import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	list: [],
	init: true,
};

export const validarBonosSlice = createSlice({
	name: 'validar bonos',
	initialState,
	reducers: {
		
		setList: (state, action) => {
			state.list = [...action.payload];
			
			if (!state.init)
				state.init = true;
		},
		
		addList: (state, action) => {
			var find = state.list.find(item => Number(item.id) === Number(action.payload.id));
			
			if (find)
				state.list = [...state.list].map(item => {
					if (Number(item.id) === Number(action.payload.id))
						return action.payload;
					else
						return item;
				});
			else
				state.list = [...state.list, action.payload];
			
			if (!state.init)
				state.init = true;
		},
		
		deleteList: (state, action) => {
			state.list = [...state.list].filter(item => Number(item.id) !== Number(action.payload));
		}
		
	}
});

export const { setList, addList, deleteList } = validarBonosSlice.actions;
export default validarBonosSlice.reducer;