import { useState } from "react";

const useFile = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event) => {
        setValue(event.target.files);
    };
    
    const handleSet = (value) => {
        setValue(value);
    };

    return {
        value,
        onChange: handleChange,
        setValue: handleSet
    };
};

export default useFile;