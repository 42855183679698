import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft } from 'react-icons/md';

import '../styles/Back.scss';

const Back = () => {
	const navigate = useNavigate();
	
	const handleClick = e => {
		navigate(-1);
	}
	
	return (
		<div className="back">
			<div onClick={ handleClick }><MdKeyboardArrowLeft className="icon" />Back</div>
		</div>
	);
}

export default Back;