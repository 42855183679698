import '../styles/Input.scss';

const Input = ({ type, name, placeholder, value, onChange, error, disabled, big, presymbol, symbol, autoComplete }) => {
	const className = ['drws-input'];
	
	if (error) className.push('error');
	if (disabled) className.push('disabled');
	if (big) className.push('big');
	
	return (
		<div className={ className.join(' ') }>
			{ presymbol && <div className="pre-symbol">{ presymbol }</div> }
			<input type={ type } name={ name } placeholder={ placeholder } value={ value } onChange={ onChange } disabled={ disabled } autoComplete={ autoComplete ? autoComplete : (type === 'password' ? "new-password" : "off") } />
			{ symbol && <div className="symbol">{ symbol }</div> }
		</div>
	);
}

export default Input;