import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import '../../styles/ModalEscanear.scss';

import { openModal } from '../../features/layout/layoutSlice';
import { setList, addList } from '../../features/validarBonos/validarBonosSlice';

import apiURL from '../../hooks/apiURL';
import fetchData from '../../hooks/fetchData';

import Button from '../../components/Button';

const ModalEscanear = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.validarBonos);
	const { id, multiple } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ scanner, setScanner ] = useState(false);
	
	
	useEffect(() => {
		localStorage.escaneados = '';
		
		return () => {
			localStorage.escaneados = undefined;
		}
	}, []);
	
	const handleCancel = e => {
		scanner.clear();
		close();
	}
	
	const handleConfirm = e => {
		scanner.clear();
		close();
	}
	
	const onScanSuccess = (decodedText, decodedResult) => {
	  if (multiple) {
		  let escaneados = localStorage.escaneados.split(',');
		  
		  if (escaneados.indexOf(decodedText) < 0) {
			  dispatch(addList({ id: uuid(), estado: 0, serial: decodedText}));
			  
			  escaneados.push(decodedText);
			  localStorage.escaneados = escaneados.join(',');
		  }
	  }
	  else {
		  const nList = [...list].map(item => {
			  const nItem = {...item};
			  if (item.id === id)
			  	nItem.camera = decodedText;
			  
			  return nItem;
		  })
		  
			dispatch(setList(nList));
	  }
	  
	  if (id)
	  	handleCancel();
	}
	

	const handleEscanear = () => {
		let config = {
		  fps: 5,
		  isShowingInfoIcon: 0,
		  qrbox: {
			  width: 180, 
			  height: 180
			},
		  rememberLastUsedCamera: true,
		  experimentalFeatures: {
         useBarCodeDetectorIfSupported: true 
      },
		  // Only support camera scan type.
		  supportedScanTypes: [window.Html5QrcodeScanType.SCAN_TYPE_CAMERA]
		};
		
		let html5QrcodeScanner = new window.Html5QrcodeScanner("reader", config, /* verbose= */ false)
		setScanner( html5QrcodeScanner );
		  
		html5QrcodeScanner.render(onScanSuccess);
	}
	
	useEffect(() => {
		handleEscanear()
	}, []);
	
	
	return (
		<div className="drowers-modal-escanear-cupon">
			<div className="title">Escanear código</div>
			
			<div id="reader" className={ `inside ${ error ? 'error' : '' }` }>
				
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ handleCancel } disabled={ send }>Cancelar</Button>
				<Button green={ true } onClick={ handleConfirm } disabled={ send }>OK</Button>
			</div>
		</div>
	);
}

export default ModalEscanear;