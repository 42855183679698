import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp, MdAccountBalanceWallet, MdOutlineShoppingCart } from 'react-icons/md';
import { TbFilePlus } from 'react-icons/tb';
import { FaTrash } from 'react-icons/fa';
import { IoLogOut, IoDocumentText } from 'react-icons/io5';
import { RiEdit2Fill } from 'react-icons/ri';
import { CgDebug } from 'react-icons/cg';
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { IoEyeSharp, IoAlertCircle } from 'react-icons/io5';

import '../styles/ComerciosVerPage.scss';

import fetchData from '../hooks/fetchData';
import formatMoney from '../hooks/formatMoney';
import formatDate from '../hooks/formatDate';
import formatProgress from '../hooks/formatProgress';

import { openModal, setTitle, setConfig } from '../features/layout/layoutSlice';
import { setList } from '../features/auditoria/auditoriaSlice';
import { setList as setLogList } from '../features/log/logSlice';

import Loader from '../components/Loader';
import InfoCard from '../components/InfoCard';
import Back from '../components/Back';

const ComerciosVerPageTableItemLog = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const handleGestionar = () => {
		dispatch(openModal( {id: 'gestionar-documentacion', extra: { id: item.id } }))
	}
	
	const handleEditar = () => {
		dispatch(openModal( {id: 'crear-comercio', extra: { id: item.id } }))
	}
	
	return (
		<tr>
			<td className="check-field"># { item.id }</td>
			
			<td>
				<div className="bi-line full">
					<div className="left">
						<b>{ item.accion }</b>
						<small>El { formatDate('d.m.Y', item.fecha) } a las { formatDate('H:i', item.fecha) }</small>
					</div>
					
					<div className="extra">
						<div className="info-ip">
							{ item.ip }
						</div>
					</div>
				</div>
			</td>
		</tr>
	)
}

const ComerciosVerPageTableItem = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [ open, setOpen ] = useState(false);
	
	const handleClick = e => {
		setOpen(!open);
	}
	
	const handleAdjuntarFactura = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'adjuntar-factura', extra: { id: item.id } }));
	}
	
	const handleVerFactura = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'auditar', extra: { id: item.id } }));
	}
	
	const handleAddBono = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'adjuntar-bono', extra: { id: item.id } }));
	}
	
	const handleCupon = id => {
		navigate(`/buscar/${ id }`)
	}
	
	const totalBonos = item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	
	return (
		<>
			<tr onClick={ handleClick }>
				<td className="check-field">
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b># { item.id }</b></div> }
					{ !item.auditoria && <># { item.id }</> }
				</td>
				
				<td>
					<div className="bi-line">
						<b>{ item.consumidor_nombre }</b>
						<small>{ item.consumidor_telefono }</small>
					</div>
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b>{ formatDate('d.m.Y', item.fecha) }</b></div> }
					{ !item.auditoria && <>{ formatDate('d.m.Y', item.fecha) }</> }
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b>{ item.compra }€</b></div> }
					{ !item.auditoria && <>{ formatMoney(item.compra * 100) }€</> }
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b>{ totalBonos }€</b></div> }
					{ !item.auditoria && <>{ formatMoney(totalBonos * 100) }€</> }
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b>{ item.compra - totalBonos }€</b></div> }
					{ !item.auditoria && <>{ formatMoney((item.compra - totalBonos) * 100) }€</> }
				</td>
				
				<td>
					{ !item.auditar && <div className="estado pendiente">Pendiente</div> }
					{ item.auditar && item.auditar.estado === 0 && <div className="estado pagada">Validado</div> }
					{ item.auditar && item.auditar.estado !== 0 && <div className="estado error">Suspendido</div> }
				</td>
				<td>
					{
						item.remesa && (
							<div className="info-facturas flex h-center">
								{ item.remesa.estado !== 2 && <div className="info-remesa">Enviada al Ayuntamiento en la <b>Remesa Nº{ item.remesa.numero }</b></div> }
								{ item.remesa.estado === 2 && <div className="info-remesa pagada">Pagada en la <b>Remesa Nº{ item.remesa.numero }</b></div> }
								
								{
									totalBonos > 0 && (
										<div className="arrow">
											<MdKeyboardArrowDown className={ `icon ${ open ? 'open' : ''}` } />
										</div>
									)
								}
							</div>
						)
					}
					
					{
						!item.remesa && (
							<>
								<div className="info-facturas flex h-center">
									{
										!item.foto_factura && (
											<div className="adjuntar" onClick={ handleAdjuntarFactura }>
												<BsFillFileEarmarkTextFill className="icon" />Adjuntar factura
											</div>
										)
									}
									
									{
										item.foto_factura && (
											<div className="ver" onClick={ handleVerFactura }>
												<IoEyeSharp className="icon" />Auditar
											</div>
										)
									}
									
									<div className="adjuntar" onClick={ handleAddBono }>
										<IoEyeSharp className="icon" />Adjuntar bono
									</div>
									
									{
										totalBonos > 0 && (
											<div className="arrow">
												<MdKeyboardArrowDown className={ `icon ${ open ? 'open' : ''}` } />
											</div>
										)
									}
								</div>
								
								{ item.auditar && item.auditar.estado === 1 && (<div className="auditar-text"><IoAlertCircle className="icon" />La imagen está borrosa</div>) }
								{ item.auditar && item.auditar.estado === 2 && (<div className="auditar-text"><IoAlertCircle className="icon" />Los importes no coinciden</div>) }
								{ item.auditar && item.auditar.estado === 3 && (<div className="auditar-text"><IoAlertCircle className="icon" />Datos de cliente incorrectos</div>) }
							</>
						)
					}
				</td>
			</tr>
			
			{
				open && (
					<>
						{
							item.cupones.map((cupon, index) => 
								<tr key={ cupon.id } className={ `grey ${ index === 0 ? 'first' : ''} ${ index === item.cupones.length-1 ? 'last' : ''}`}>
									<td colSpan={ 4 } align="center" className="serial"><span onClick={ () => handleCupon(cupon.serial) }>{ cupon.serial }</span></td>
									<td>{ cupon.valor }€</td>
									<td>{ cupon.usuario.nombre } { cupon.usuario.apellidos }</td>
									<td colSpan={ 3 }>{ cupon.usuario.telefono }</td>
								</tr>
							)
						}
					</>
				)
			}
		</>
	)
}

const ComerciosVerPage = () => {
	const dispatch = useDispatch();
	const { token, config } = useSelector(state => state.layout);
	const { list, init } = useSelector(state => state.auditoria);
	const { list:bonos } = useSelector(state => state.remesas);
	const { list:log } = useSelector(state => state.log);
	const { id } = useParams();
	const [ page, setPage ] = useState(0);
	const [ max, setMax ] = useState(10);
	const [ pageLog, setPageLog ] = useState(0);
	const [ maxLog, setMaxLog ] = useState(10);
	const [ sort, setSort ] = useState(false);
	const [ logShow, setLogShow ] = useState(false);
	const [ sortDirection, setSortirection ] = useState(-1);
	
	useEffect(() => {
		dispatch(setTitle('Comercios'));
		dispatch(setList([]));
		
		fetchData(`get-auditar/${ id }`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setList(response));
			}
		});
		
		fetchData(`get-app-info`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setConfig(response));
			}
		});
		
		fetchData(`get-commerce-log`, {
			method: 'POST',
			token,
			body: {
				id
			},
			cbk: ({ status, message, response }) => {
				dispatch(setLogList(response));
			}
		});
	}, []);
	
	const handleSort = (ord) => {
		if (!sort_list.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	const handleLog = e => {
		setLogShow(!logShow);
	}
	
	const handleCreate = () => {
		dispatch(openModal( {id: 'crear-venta', extra: { id } }))
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
		
	let sort_list = [...list].sort((a, b) => (new Date(b.fecha)).getTime() - (new Date(a.fecha)).getTime())
	let sort_list_log = [...log].sort((a, b) => (new Date(b.fecha)).getTime() - (new Date(a.fecha)).getTime())
	
	
	if (sort) {
		sort_list = [...sort_list].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	}
	
	let importeCampania = config.presupuesto;
	let importePagado = config.pagado || 0;
	let importePendiente = config.pendiente || 0;
	let importeRestante = importeCampania - (importePagado * 100) - (importePendiente * 100);
	
	return (
		<div className="comercios-ver-page">
			<div className="row">
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida disponible' value={ `${ formatMoney(importeCampania) }€` } text={ <div>presupuesto de la campaña</div> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Dinero pagado' value={ `${ formatMoney(importePagado * 100) }€` } text={ <><span className="green">{ formatProgress((importePagado * 100) / importeCampania) }%</span><div>del total disponible</div></> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida restante' value={ `${ formatMoney(importeRestante) }€` } text={ <><span className="red">{ formatProgress((importeRestante * 100) / importeCampania) }%</span><div>de la partida disponible</div></> } />
				</div>
			</div>
			
			<Back />
			
			<div className={ `card ${ !logShow ? 'close' : '' }` }>
				
				<div className="table-info click" onClick={ handleLog }>
					<div className="title">
						Log
						<div className="subtitle">Actividad de un comercio en la plataforma.</div>
					</div>
					
					<div className="right">
						<MdKeyboardArrowDown className="icon" />
					</div>
				</div>
				
				<div className="table-container">
					<table className="drws-table-log" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
						<thead>
							<tr>
								<td className="check-field">ID</td>
								<td className="td-comercio">Log</td>
							</tr>
						</thead>
						
						<tbody>
							{
								[...sort_list_log].slice(pageLog*maxLog, pageLog*maxLog + maxLog ).map(item => <ComerciosVerPageTableItemLog key={ item.id } item={ item } /> )
							}
							
							{
								!sort_list_log.length && (
									<tr>
										<td colSpan={ 2 } align="center" className="empty">
											<div className="empty">
												<div className="btn grey no-click">
													<CgDebug /> Aun no hay dados
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				
				<div className="table-controls">
					<div className="page">
						{
							sort_list.length > 0 && (
								<>
									{ pageLog * maxLog + 1 } al { ((pageLog * maxLog) + maxLog) > sort_list_log.length ? sort_list_log.length : ((pageLog * maxLog) + maxLog) } de { sort_list_log.length } 
									
									<select className="page-select" value={ maxLog } onChange={ e => setMaxLog(Number(e.target.value))}>
										<option value="5">5</option>
										<option value="7">7</option>
										<option value="10">10</option>
										<option value="20">20</option>
									</select>
								</>
							)
						}
					</div>
					
					
					<div className="pagination">
						<div className="options">
							<div className={ pageLog < 1 ? 'disabled' : '' } onClick={ () => setPageLog(page-1) }>
								<MdKeyboardArrowLeft />
							</div>
							
							{
								Array(Math.ceil(sort_list_log.length / maxLog)).fill(null).map((i, index) => 
									<div key={ index } className={ `single-page${ index === pageLog ? ' active' : '' }` } onClick={ () => setPageLog(index) }>
										{ index+1 }
									</div>
								)
							}
							
							<div className={ pageLog*maxLog + maxLog >= sort_list_log.length ? 'disabled' : '' } onClick={ () => setPageLog(page+1) }>
								<MdKeyboardArrowRight />
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
			<div className="card">
				
				<div className="table-info">
					<div className="title">
						Gestionar ventas
						<div className="subtitle">Gestion de las ventas del comercio.</div>
					</div>
					
					<div className="options">
						<div className="btn" onClick={ handleCreate }>
							<MdOutlineShoppingCart /> Nueva venta
						</div>
					</div>
				</div>
				
				<div className="table-container">
					<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
						<thead>
							<tr>
								<td className="check-field">ID</td>
								<td onClick={ () => handleSort('id') } className="tr-fecha">Cliente <br />operación { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-fecha">Fecha <br />operación { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-importe">Importe <br />de la venta { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-bonos">Bonos <br />canjeados { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-pagar">A pagar por <br />el cliente { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-estado">Estado de <br />la factura { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-otras" width={ 100 }>Otras <br />operaciones { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
							</tr>
						</thead>
						
						<tbody>
							{
								[...sort_list].slice(page*max, page*max + max ).map(item => <ComerciosVerPageTableItem key={ item.id } item={ item } /> )
							}
							
							{
								!sort_list.length && (
									<tr>
										<td colSpan={ 8 } align="center" className="empty">
											<div className="empty">
												<div className="btn" onClick={ handleCreate }>
													<MdOutlineShoppingCart /> Crear la primera venta
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				
				<div className="table-controls">
					<div className="page">
						{
							sort_list.length > 0 && (
								<>
									{ page * max + 1 } al { ((page * max) + max) > sort_list.length ? sort_list.length : ((page * max) + max) } de { sort_list.length } 
									
									<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
										<option value="5">5</option>
										<option value="7">7</option>
										<option value="10">10</option>
										<option value="20">20</option>
									</select>
								</>
							)
						}
					</div>
					
					
					<div className="pagination">
						<div className="options">
							<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
								<MdKeyboardArrowLeft />
							</div>
							
							{
								Array(Math.ceil(sort_list.length / max)).fill(null).map((i, index) => 
									<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
										{ index+1 }
									</div>
								)
							}
							
							<div className={ page*max + max >= sort_list.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
								<MdKeyboardArrowRight />
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
			<Back />
		</div>
	);
}

export default ComerciosVerPage;