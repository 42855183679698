import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { BsFiles, BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { RiInformationFill, RiAddLine } from 'react-icons/ri';
import { IoClose } from 'react-icons/io5';
import { IoIosWarning } from 'react-icons/io';
import { CgSpinner } from 'react-icons/cg'
import { HiQrCode } from 'react-icons/hi2';


import '../styles/ValidarBonosPage.scss';

import { setTitle, openModal } from '../features/layout/layoutSlice';
import { setList } from '../features/validarBonos/validarBonosSlice';

import useInput from '../hooks/useInput';
import fetchData from '../hooks/fetchData';

import Loader from '../components/Loader';
import Input from '../components/Input';
import InfoCard from '../components/InfoCard';

const ValidarBonosPageTableItem = ({ item, onDelete, onUpdate }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useSelector(state => state.layout);
	const [ serial, setSerial ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ checked, setChecked ] = useState(false);
	
	useEffect(() => {
		if (item.serial)
			handleChange({target: {value: item.serial}});
	}, []);
	
	useEffect(() => {
		if (item.camera && item.serial !== item.camera) {
			handleChange({target: {value: item.camera}});
		}
	}, [item.camera]);
	
	const handleAdjuntarFactura = e => {
		e.preventDefault();
		e.stopPropagation();
	}
	
	const handleEscanear = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'escanear-codigo', extra: { id: item.id } }));
	}
	
	const handleDelete = e => {
		onDelete(item.id);
	}
	
	const handleChange = e => {
		const v = e.target.value;
		
		setSerial(v);
		
		if (v.length === 10) {
			setLoading(true);
			setChecked(true);
			
			fetchData(`check-serial`, {
				method: 'POST',
				token,
				body: {
					s: v
				},
				cbk: ({ status, message, response }) => {
					setLoading(false);
					
					if (response) {
						response.id = item.id;
						onUpdate(response);
					}
					else {
						let nItem = {...item};
								nItem.estado = 2;
								nItem.serial = v;
						
						onUpdate(nItem);
					}
				}
			});
		}
		else {
			if (checked) {
				setChecked(false);
				
				let nItem = {...item};
						nItem.estado = 0;
						nItem.serial = v;
						
				onUpdate(nItem);
			}
		}
	}
	
	return (
		<>
			<tr className={ loading ? 'disabled' : '' }>
				<td><Input value={ serial } onChange={ handleChange } symbol={ loading ? <CgSpinner className="rotating-element" /> : <IoClose onClick={ handleDelete } /> } disabled={ loading } /></td>
				
				<td align="center">
					{ item.estado === 0 && <div className="estado"></div> }
					
					{ item.estado === 1 && <div className="estado pagada">Valido</div> }
					{ item.estado === 2 && <div className="estado error">No válido</div> }
					{ item.estado === 3 && <div className="estado error">Usado</div> }
					{ item.estado === 4 && <div className="estado pendiente">Repetido</div> }
					{ item.estado === 5 && <div className="estado pendiente">Falta importe</div> }
				</td>
				
				<td className="amount valor-td">{ `${ item.valor ? item.valor : 0 }€` }</td>
				
				<td className="extra-field">
					<div className="flex h-center">
						<div className="ver" onClick={ handleEscanear }>
							<HiQrCode className="icon" />Escanear
						</div>
					</div>
				</td>
			</tr>
		</>
	)
}

const ValidarBonosPage = ({ disponibles }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list, init } = useSelector(state => state.validarBonos);
	const { list:listValidados } = useSelector(state => state.ventasRealizadas);
	const [ sort, setSort ] = useState(false);
	const [ sortDirection, setSortirection ] = useState(-1);
	const [ totalBonos, setTotalBonos ] = useState(0);
	const [ descuento, setDescuento ] = useState(1);
	const [ minDescuento, setMinDescuento ] = useState(10);
	const searchInput = useRef(null);
	const regex = /[^0-9.]/g;
	
	const nombre = useInput('');
	const apellidos = useInput('');
	const telefono = useInput('');
	
	const importe_total = useInput('');
	
	useEffect(() => {
		dispatch(setTitle('Ventas Realizadas'));
	}, []);
	
	useEffect(() => {
		dispatch(setList([]));
		nombre.onChange({ target: { value: '' } });
		apellidos.onChange({ target: { value: '' } });
		telefono.onChange({ target: { value: '' } });
		importe_total.onChange({ target: { value: '' } });
	}, [listValidados])
	
	const checkBonos = (list) => {
		let used = [];
		let update = false;
		let suma = 0;
		let lnList = [...list];
		
		const sort_list = [...list].sort((a, b) => String(b.valor).localeCompare(String(a.valor), 'en', {numeric: true}));
		
		sort_list.map(item => {
			const index = used.find(serial => serial === item.serial);
			
			const nItem = {...item};
			
			if (index) {
				nItem.estado = 4;
			}
			else {
				if (nItem.estado === 4)
					nItem.estado = 1;
				
				if (nItem.estado === 5 && nItem.oldEstado !== undefined)
					nItem.estado = nItem.oldEstado;
			}
			
			if (nItem.estado === 1 && suma + item.valor > dTotal) {
				if (nItem.oldEstado === undefined)
					nItem.oldEstado = nItem.estado;
				nItem.estado = 5;
			}
			
			if (nItem.estado === 1)
				suma += item.valor;
			
			used.push(nItem.serial);
			
			lnList = [...lnList].map(itm => itm.id === item.id ? nItem : itm);
		});
		
		dispatch(setList(lnList));
		setTotalBonos(suma);
	};
	
	
	const handleSort = (ord) => {
		if (!sort_list.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	const handleAddLine = e => {
		const newList = [...list];
		let index = 0;
		
		if (newList.length)
			index = newList[newList.length-1].id;
		
		newList.push({id: index+1, estado: 0});
		
		dispatch(setList(newList));
	}
	
	const handleDelete = id => {
		const newList = [...list].filter(item => item.id !== id);
		
		dispatch(setList(newList));
		
		checkBonos(newList)
	}
	
	const handleUpdate = (newItem) => {
		const newList = [...list].map(item => item.id === newItem.id ? newItem : item);
		
		dispatch(setList(newList));
		
		checkBonos(newList);
	}
	
	useEffect(() => {
		checkBonos([...list]);
	}, [importe_total.value])
	
	const handleEscanear = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'escanear-codigo', extra: { multiple: true } }));
	}
	
	const handleSend = e => {
		if (totalBonos > 0)
			dispatch(openModal({ id: 'finalizar-venta', extra: { id: 1, importe: importe_total.value, descuento: dTotal, nombre: nombre.value +' '+ apellidos.value, telefono: telefono.value } }));
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
		
	let sort_list = [...list].sort((a, b) => (new Date(b.create_at)).getTime() - (new Date(a.create_at)).getTime())
	
	if (sort) {
		sort_list = [...sort_list].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	}
	
	let iTotal = (importe_total.value).replace(',', '.').replace(regex, ""),
			dTotal = Number(Number(iTotal * descuento).toFixed(2).split('.')[1]) === 0 ? Number(iTotal * descuento).toFixed(0) : Number(iTotal * descuento).toFixed(2);
	
	dTotal = Math.floor(dTotal / minDescuento) * minDescuento;
	
	if (dTotal > 100) dTotal = 100;
	
	return (
		<div className="validar-bonos-page">
			{
				!disponibles && (
					<div className="alert warning">
						<IoIosWarning className="icon" /><div><b>Presupuesto de la campaña agotado.</b> La plataforma quedará habilitada hasta el día 25 de Noviembre para adjuntar facturas realizadas a fecha anterior a
		la finalización de la campaña. Si el ayuntamiento decide ampliar el presupuesto, se lo notificaremos por correo electrónico.</div>
					</div>
				)
			}
			
			<div className="title-page">Añadir venta</div>
			
			<div className="row">
				<div className="grow formulario">
					
					<div className="validar-steps">
						<div className="step">1</div>
						Introduce los datos del cliente
					</div>
					
					<div className="datos">
						<div className="field">
							<div className="field-title">NOMBRE</div>
							<Input value={ nombre.value } onChange={ nombre.onChange } />
						</div>
						
						<div className="field">
							<div className="field-title">APELLIDOS</div>
							<Input value={ apellidos.value } onChange={ apellidos.onChange } />
						</div>
						
						<div className="field">
							<div className="field-title">TELÉFONO</div>
							<Input value={ telefono.value } onChange={ telefono.onChange } />
						</div>
					</div>
					
					<div className="validar-steps">
						<div className="step">2</div>
						Introduce el importe de la venta
					</div>
					
					<div className="datos">
						<div className="field">
							<div className="field-title">IMPORTE TOTAL</div>
							<Input value={ importe_total.value } onChange={ importe_total.onChange } symbol='€' />
						</div>
						
						<div className="field">
							<div className="field-title">IMPORTE MÁXIMO A DESCONTAR</div>
							<div className="descuento-maximo"><span>{ `${ dTotal } €` }</span></div>
						</div>
					</div>
					
					
					<div className="validar-steps">
						<div className="step">3</div>
						Introduce los bonos del cliente
						
						<div className="flex h-center">
							<div className="ver todo" onClick={ handleEscanear }>
								<HiQrCode className="icon" />Escanear todos
							</div>
						</div>
					</div>
					
					
					<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
						<thead>
							<tr>
								<td onClick={ () => handleSort('codigo') } className="codigo-td">Código { sort === 'codigo' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('estado') } align="center" className="estado-td">Estado { sort === 'estado' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('valor') } className="valor-td">Valor { sort === 'valor' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td className="extra-field">Escanear</td>
							</tr>
						</thead>
						
						<tbody>
							{
								[...sort_list].map(item => <ValidarBonosPageTableItem key={ item.id } item={ item } onDelete={ handleDelete } onUpdate={ handleUpdate } /> )
							}
							
							{
								!sort_list.length && (
									<tr>
										<td colSpan={ 4 } align="center" className="empty">
											<div className="empty">
												<div className="btn grey" onClick={ handleAddLine }>
													<BsFiles /> Añade tu primer bono
												</div>
											</div>
										</td>
									</tr>
								)
							}
							
							<tr>
								<td colSpan={ 4 }>
									<div className="add-line">
										<div className="add-button" onClick={ handleAddLine }>
											<RiAddLine className="icon" />
										</div>
									</div>
								</td>
							</tr>
							
							<tr className="tr-no-top-line">
								<td colSpan={ 2 }>
									<div className="precio-total table-text">
										Total venta
									</div>
								</td>
								
								<td colSpan={ 2 }>
									<div className="precio-total amount">
										{ `${ Number(Number(iTotal).toFixed(2).split('.')[1]) === 0 ? Number(iTotal).toFixed(0) : Number(iTotal).toFixed(2) }€` }
									</div>
								</td>
							</tr>
							
							<tr className="tr-no-top-line">
								<td colSpan={ 2 }>
									<div className="precio-bonos table-text">
										Total bonos
									</div>
								</td>
								
								<td colSpan={ 2 }>
									<div className="precio-bonos amount">
										{ `${ totalBonos }€` }
										
										{
											totalBonos < dTotal && (
												<div className="total-descuento-label">
													<div>¡Puedes cargar hasta { dTotal }€!</div>
												</div>
											)
										}
									</div>
								</td>
							</tr>
							
							<tr className="tr-no-top-line">
								<td colSpan={ 2 }>
									<div className="a-pagar table-text">
										A pagar por el cliente
									</div>
								</td>
								
								<td colSpan={ 2 }>
									<div className="a-pagar amount">
										{ `${ Number((iTotal - totalBonos).toFixed(2).split('.')[1]) === 0 ? Number(iTotal - totalBonos).toFixed(0) : (iTotal - totalBonos).toFixed(2) }€` }
									</div>
								</td>
							</tr>
							
							{
								totalBonos < dTotal && (
									<tr className="tr-no-top-line mobile-tope">
										<td colSpan={ 4 }>
											<div className="mensaje">
												<div className="total-descuento-label">
													<div>¡Puedes cargar hasta { dTotal }€!</div>
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
					
					<div className="finish-button-hook">
						<div className={ `finish-button ${ totalBonos === 0 || iTotal < 1 ? 'disabled' : '' }` } onClick={ handleSend }>PROCESAR VENTA</div>
					</div>
				</div>
				
				<div className="alert info info-card">
					<div>
						<RiInformationFill className="icon" />
					</div>
					
					<b>Información.</b> <div>¿Tienes dudas o alguna urgencia? Llama al <a href="tel:619 477 411">619 477 411</a> para solicitar ayuda urgente</div>
				</div>
			</div>
		</div>
	);
}

export default ValidarBonosPage;