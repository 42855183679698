import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { TbFileDots } from 'react-icons/tb';
import { TiWarningOutline } from 'react-icons/ti';
import { TbReportSearch } from 'react-icons/tb';

import '../styles/MisBonosPage.scss';

import { setTitle, openModal } from '../features/layout/layoutSlice';
import { setList, addList, deleteList } from '../features/misBonos/misBonosSlice';

import fetchData from '../hooks/fetchData';

import Loader from '../components/Loader';
import BonoCard from '../components/BonoCard';


const MisBonosPage = () => {
	const dispatch = useDispatch();
	const location = useLocation()
	const navigate = useNavigate();
	const [ disponibles, setDisponibles ] = useState(999);
	const [ disponiblesUser, setDisponiblesUser ] = useState(0);
  const params = new URLSearchParams(location.search)
	const { init, list } = useSelector(state => state.misBonos);
	const { token } = useSelector(state => state.layout);
	
	useEffect(() => {
		dispatch(setTitle('Mis Bonos'));
		
		fetchData(`get-mis-bonos`, {
			method: 'POST',
			token,
			body: {
				pi: params.get("payment_intent")
			},
			cbk: ({ status, message, response }) => {
				if (params.get("payment_intent"))
					navigate('/');
				
				setDisponibles(response.disponible);
				setDisponiblesUser(response.totales);
				dispatch(setList(response.list));
			}
		});
	}, []);
	
	const handleBuy = () => {
		dispatch(openModal({ id: 'comprar-bonos', extra: { maximo: disponiblesUser } }))
	}
	
	const handleBuyBenefico = () => {
		dispatch(openModal({ id: 'comprar-bonos', extra: { benefico: true, maximo: disponiblesUser } }))
	}
	
	const handleDownload = () => {
		
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
	
	const bono_benefico = [...list].find(item => item.benefico === 1);
	const bono_resto = [...list].filter(item => item.benefico !== 1);
	
	return (
		<div className="mis-bonos-page">
			<div className="comprar">
				{
					disponibles && disponiblesUser < 10 && 
						<div className="button" onClick={ handleBuy }>
							<MdAccountBalanceWallet className="icon" />Comprar bonos
						</div>
				}
				
				{
					disponibles && disponiblesUser === 10 && 
						<div className="button" onClick={ handleBuyBenefico }>
							<MdAccountBalanceWallet className="icon" />Comprar bono benéfico
						</div>
				}
				
				{
					(!disponibles || disponiblesUser > 10) && 
						<div className="button grey">
							<TiWarningOutline className="icon" />Limite de bonos alcanzado
						</div>
				}
			</div>
			
			{
				bono_benefico && (
					<div className="row center">
						<div className="col-4 col-pc-large-3 col-pc-medium-2 col-tablet-1">
							<BonoCard data={ bono_benefico } barCode={ true } />
						</div>
					</div>
				)
			}
			
			<div className="row center">
				{
					!bono_resto.length && disponiblesUser === 0 && <div className="sin-bonos"><TbReportSearch className="icon" />Aun no tienes bonos</div>
				}
				
				{
					!bono_resto.length && disponiblesUser > 0 && <div className="sin-bonos"><TbReportSearch className="icon" />Espera a que se asignen { disponiblesUser } bonos, refresca en unos instantes</div>
				}
				
				{
					bono_resto.map(item => (
						<div key={ item.id } className="col-4 col-pc-large-3 col-pc-medium-2 col-tablet-1">
							<BonoCard data={ item } barCode={ true } />
						</div>
					))
				}
			</div>
			
			{
				!!bono_resto.length && (
					<div className="descargar">
						<div className="button" onClick={ handleDownload }>
							<TbFileDots className="icon" />Descargar bonos
						</div>
					</div>
				)
			}
		</div>
	);
}

export default MisBonosPage;