import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../styles/ModalModule.scss';

import { closeModal } from '../features/layout/layoutSlice';

import ModalConfirmDelete from './modals/ModalConfirmDelete';
import ModalAdjuntarFactura from './modals/ModalAdjuntarFactura';
import ModalVerFactura from './modals/ModalVerFactura';
import ModalFinalizarVenta from './modals/ModalFinalizarVenta';
import ModalComprarBonos from './modals/ModalComprarBonos';
import ModalCrearEditarComercio from './modals/ModalCrearEditarComercio';
import ModalCrearEditarUsuario from './modals/ModalCrearEditarUsuario';
import ModalEscanear from './modals/ModalEscanear';
import ModalAuditar from './modals/ModalAuditar';
import ModalGestionarDocumentacion from './modals/ModalGestionarDocumentacion';
import ModalGestionarBonos from './modals/ModalGestionarBonos';
import ModalAdjuntarBono from './modals/ModalAdjuntarBono';
import ModalCrearVenta from './modals/ModalCrearVenta';
import ModalRegistrarUsuario from './modals/ModalRegistrarUsuario';
import ModalFaltaValidar from './modals/ModalFaltaValidar';

const ModalModule = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const { show, extra } = useSelector(state => state.layout.modal);
	let modal = <></>;
	
	if (!show)
		return modal;
	
	const extraData = extra.extra || {};
	
	const handleClose = e => {
		if (extraData.back)
			navigate(extraData.back);
			
		dispatch(closeModal());
	}
	
	
	const handlePrevent = e => {
		//e.preventDefault();
		e.stopPropagation();
	}
	
	
	switch (extra.id) {
		case 'confirm-delete':
			modal = <ModalConfirmDelete close={ handleClose } extra={ extraData } />;
			break;
		
		case 'adjuntar-factura':
			modal = <ModalAdjuntarFactura close={ handleClose } extra={ extraData } />;
			break;
		
		case 'ver-factura':
			modal = <ModalVerFactura close={ handleClose } extra={ extraData } />;
			break;
		
		case 'finalizar-venta':
			modal = <ModalFinalizarVenta close={ handleClose } extra={ extraData } />;
			break;
		
		case 'comprar-bonos':
			modal = <ModalComprarBonos close={ handleClose } extra={ extraData } />;
			break;
		
		case 'crear-comercio':
		case 'editar-comercio':
			modal = <ModalCrearEditarComercio close={ handleClose } extra={ extraData } />
			break;
		
		case 'crear-usuario':
		case 'editar-usuario':
			modal = <ModalCrearEditarUsuario close={ handleClose } extra={ extraData } />
			break;
		
		case 'escanear-codigo':
			modal = <ModalEscanear close={ handleClose } extra={ extraData } />
			break;
		
		case 'auditar':
			modal = <ModalAuditar close={ handleClose } extra={ extraData } />
			break;
		
		case 'gestionar-documentacion':
			modal = <ModalGestionarDocumentacion close={ handleClose } extra={ extraData } />
			break;
		
		case 'gestionar-bonos':
			modal = <ModalGestionarBonos close={ handleClose } extra={ extraData } />
			break;
		
		case 'adjuntar-bono':
			modal = <ModalAdjuntarBono close={ handleClose } extra={ extraData } />;
			break;
		
		case 'crear-venta':
			modal = <ModalCrearVenta close={ handleClose } extra={ extraData } />
			break;
		
		case 'registrar-usuario':
			modal = <ModalRegistrarUsuario close={ handleClose } extra={ extraData } />
			break;
		
		case 'falta-validar':
			modal = <ModalFaltaValidar close={ handleClose } extra={ extraData } />
			break;
	}
	
	return (
		<div className="drowers-modal">
			<div className="box" onClick={ handlePrevent }>
				{ modal }
			</div>
		</div>
	);
}

export default ModalModule;