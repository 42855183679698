import { useSelector, useDispatch } from 'react-redux';

import '../../styles/ModalAdjuntarFactura.scss';

import fetchData from '../../hooks/fetchData';

import Button from '../../components/Button';

const ModalConfirmDelete = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { prop, endpoint } = extra;
	
	const handleConfirmCallback = id => {
		switch (prop) {
			default:
				break;
		}
	}
	
	const handleConfirm = e => {
		fetchData(endpoint, {
			token,
			cbk: ({ status, message, response }) => {
				handleConfirmCallback(response);
				
				close();
			}
		});
	}
	
	return (
		<div className="drowers-modal-confirm-delete">
			<div className="title">Confirm delete</div>
			
			<div className="content">
				Do you really want to delete this element? this process cannot be undone
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close }>Cancel</Button>
				<Button onClick={ handleConfirm }>Delete</Button>
			</div>
		</div>
	);
}

export default ModalConfirmDelete;