import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	init: false,
	login: false,
	token: false,
	tipo: 'usuario',
	role: 'Usuario',
	menu: false,
	title: '',
	user: {},
	config: {},
	modal: {
		show: false
	},
	context: {
		show: false
	}
};

export const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		
		setInit: (state, action) => {
			state.init = action.payload;
		},
		
		setLogin: (state, action) => {
			state.login = action.payload;
		},
		
		setToken: (state, action) => {
			state.token = action.payload;
		},
		
		setTipo: (state, action) => {
			state.tipo = action.payload;
		},
		
		setRole: (state, action) => {
			state.role = action.payload;
		},
		
		setTitle: (state, action) => {
			state.title = action.payload;
		},
		
		setUser: (state, action) => {
			state.user = Object.assign({...state.user}, action.payload);
		},
		
		unlogin: (state, action) => {
			state.login = false;
			state.token = false;
			state.user = {};
		},
		
		setConfig: (state, action) => {
			state.config = Object.assign({...state.config}, action.payload);
		},
		
		openContext: (state, action) => {
			state.context = {show: true, extra: action.payload};
		},
		
		closeContext: (state, action) => {
			state.context = {show: false};
		},
		
		openModal: (state, action) => {
			state.modal = {show: true, extra: action.payload};
		},
		
		closeModal: (state, action) => {
			state.modal = false;
		},
		
		toggleMenu : (state, action) => {
			state.menu = !state.menu;
		},
	}
});

export const { setInit, setLogin, setToken, setTipo, setRole, setTitle, setUser, unlogin, setConfig, openContext, closeContext, openModal, closeModal, toggleMenu } = layoutSlice.actions;
export default layoutSlice.reducer;