import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Elements, PaymentElement, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { IoIosWarning } from 'react-icons/io';

import '../../styles/ModalComprarBonos.scss';

import baseWebURL from '../../hooks/baseWebURL';
import fetchData from '../../hooks/fetchData';

import Button from '../../components/Button';
import Loader from '../../components/Loader';


const Payment = ({ volver, amount, price, pricePay, benefico }) => {
	const stripe = useStripe();
  const elements = useElements();
	const { list } = useSelector(state => state.misBonos);
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ loading, setLoading ] = useState(true);
  
  useEffect(() => {
	  if (elements)
	  	setLoading(false);
  }, [elements]);
  
	const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
		
		setSend(true);
	  setError(false);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: baseWebURL
      },
    });

    if (result.error) {
	    setError(result.error.message)
	    
			setSend(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
	
	return (
		<form onSubmit={ handleSubmit }>
			<div className={ `inside ${ error ? 'error' : '' }` }>
				{
					error && (
						<div className="alert danger">
							<IoIosWarning className="icon" /><div><b>Error.</b> { error }.</div>
						</div>
					)
				}
				
				{ loading && <div className="loader"><Loader /></div> }
		    <PaymentElement />
			</div>
			
			<div className="buttons center">
				<Button type="default" grey={ true } onClick={ volver } disabled={ send }>{ benefico ? 'Cancelar' : 'Volver' }</Button>
				<Button type="submit" green={ true } disabled={ !stripe || send }><MdAccountBalanceWallet className="icon" />Finalizar comprar { pricePay * amount }€</Button>
			</div>
		</form>
	)
}

const ModalComprarBonos = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { benefico, maximo } = extra;
	const [ amount, setAmount ] = useState(1);
	const [ finish, setFinish ] = useState(false);
	const price = 10;
	const pricePay = 6;
	const { list } = useSelector(state => state.misBonos);
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const { token } = useSelector(state => state.layout);
	
	const [ clientSecret, setClientSecret ] = useState(false);
	
	useEffect(() => {
		if (benefico)
			handleFinish();
	}, [])
	const handleConfirm = e => {
		close();
	}
	
	const handleRestar = e => {
		if (amount > 1)
			setAmount(amount-1)
	}
	
	const handleSumar = e => {
		if (amount < 10 - list.length)
			if (amount < 10 - maximo)
				setAmount(amount+1)
	}
	
	const handleFinish = () => {
		setLoading(true);
		
		fetchData(`client-secret/${ amount }`, {
			token,
			cbk: ({ status, message, response }) => {
				if (status === 'error') {
					window.location.reload();
					return;
				}
				
				setClientSecret(response.client_secret);
				setFinish(true);
			}	
		})
	}
	
	const handleBack = e => {
		e.preventDefault();
		
		if (benefico) {
			close();
		}
		else {
			setLoading(false);
			setFinish(false)
		}
	}
	
	if (!finish)
		return (
			<div className="drowers-modal-comprar-bonos">
				<div className="title">Comprar { benefico ? 'bono benéfico' : 'bonos' }</div>
			
				{
					loading && <div className="loader"><Loader /></div>
				}
				
				{
					!loading && (
						<div className="amount">
				    	<div className="operacion" onClick={ handleRestar }><BiMinus /></div>
				    	<div className="valor">{ amount }</div>
				    	<div className="operacion" onClick={ handleSumar }><BiPlus /></div>
				    </div>
				  )
				}
				
				<div className="buttons center">
					<Button grey={ true } onClick={ close } disabled={ send }>Cancelar</Button>
					<Button onClick={ handleFinish } green={ true } disabled={ send }><MdAccountBalanceWallet className="icon" />Comprar { pricePay * amount }€</Button>
				</div>
			</div>
		)
  
	if (!clientSecret)
		return <div className="loader"><Loader /></div>;
	
	
	const stripePromise = loadStripe('pk_test_51KUT1HEMDNSDp3SMNLaOaiA48qrDZcZtUSaCstKvsQgOHAjwAxJc3yB752KwA3BgqyRzRKxceTrVS6WGcQIGpCFh008syTredJ');
	
	const options = {
    // passing the client secret obtained from the server
    clientSecret
  };

	return (
		<div className="drowers-modal-comprar-bonos">
			<div className="title">Comprar { benefico ? 'bono benéfico' : 'bonos' }</div>
			
			<Elements stripe={ stripePromise } options={ options }>
	      <Payment volver={ handleBack } amount={ amount } price={ price } pricePay={ pricePay } benefico={ benefico } />
	    </Elements>
		</div>
	);
}

export default ModalComprarBonos;