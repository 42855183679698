import apiURL from './apiURL';

const FetchData = async ( endpoint, { method='GET', token, body, cbk, cbkError } ) => {
	const params = {
		method: method
	}
	
	if (token) {
		params.headers = new Headers({
      'Authorization': 'Bearer '+ token
    });
	}
	
	if (body) {
		params.body = JSON.stringify({
			...body
		});
	}
	
	
  try {
    const response = await fetch(`${ apiURL }v1/${ endpoint }`, params);
    
    const json = await response.json();
    
    cbk && cbk(json);
  } catch (error) {
    console.log("**_ERROR_**", error);
    cbkError && cbkError(error);
  };
};

export default FetchData;