import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MdEmail, MdLock } from 'react-icons/md';

import '../styles/LoginPage.scss';

import { setLogin, setToken, setTipo, setRole, setUser, setConfig } from '../features/layout/layoutSlice';

import useInput from '../hooks/useInput';
import fetchData from '../hooks/fetchData';

import Input from '../components/Input';
import Button from '../components/Button';

import Logo from '../media/logo.png';
//import Background from '../media/login-background.png';

const LoginPage = ({ tipo, load }) => {
	const dispatch = useDispatch();
	const email = useInput('');
	const password = useInput('');
	const [ submit, setSubmit ] = useState(false);
	const [ error, setError ] = useState({});
	
	// ELIMINAR EN PRODUCCION
	useEffect(() => {
		switch (tipo) {
			case 'comercio':
				email.onChange({target: {value: 'comercio@drowers.es'} });
				password.onChange({target: {value: '123'} });
				break;
			
			case 'administrador':
				email.onChange({target: {value: 'administrador@drowers.es'} });
				password.onChange({target: {value: '123'} });
				break;
			
			case '':
			default:
				email.onChange({target: {value: 'usuario@drowers.es'} });
				password.onChange({target: {value: '123'} });
				break;
		}
	}, [tipo]);
	
	useEffect(() => {
		setError({});
	}, [email.value, password.value]);
	
	const handleSubmit = e => {
		e.preventDefault();
		
		setError({});
		
		if (!email.value || !password.value) {
			const err = {};
			
			if (!email.value) err['email'] = true;
			if (!password.value) err['password'] = true;
			
			setError(err);
			
			return null;
		}
		
		setSubmit(true);
		
		fetchData(`auth`, {
			method: 'POST',
			body: {
				email: email.value,
				password: password.value,
				tipo
			},
			cbk: ({ status, message, response }) => {
				if (status === 'error') {
					setSubmit(false);
					setError({
						email: true,
						password: true
					});
					return null;
				}
				
				dispatch(setToken(response.token));
				dispatch(setTipo(response.tipo));
				dispatch(setRole(response.role));
				dispatch(setConfig(response.config));
				dispatch(setLogin(true));
			},
			cbkError: response => {
				setSubmit(false);
			}
		});
	}
	
	const crear_cuenta = (tipo !== 'comercio' && load.inicio === 1 && load.fin === 0) || (tipo === 'comercio' && load.fecha_registro_comercios === 1 && load.fecha_fin_registro_comercios === 0 && load.fin === 0);
	const allow_login = tipo === 'administrador' || (tipo !== 'comercio' && load.inicio === 1 && load.fin === 0) || (tipo === 'comercio' && load.fecha_registro_comercios === 1);
	
	return (
		<div className="login-page">
			{ /*<img src={ Background } alt='background' className="background" />*/ }
			
			<div className="inside">
				<div className="logo">
					<img src={ Logo } alt='logo' />
				</div>
				
				<div className="switch">
					{
						tipo !== 'administrador' && (
							<>
								<NavLink to='/login' end={ true }>Usuario</NavLink>
								<NavLink to='/login/comercio' end={ true }>Comercio</NavLink>
							</>
						)
					}
					
					{
						tipo === 'administrador' && (
							<>
								<NavLink to='/login/administrador' end={ true } className="full">Administrador</NavLink>
							</>
						)
					}
				</div>
				
				<form onSubmit={ handleSubmit } className="box">
					<Input label='Usuario' type='text' name='email' placeholder='Correo electrónico' value={ email.value } onChange={ email.onChange } error={ error.email } errorMsg="Invalid email" disabled={ submit } big={ true } presymbol={ <MdEmail /> } />
					
					<Input label='Contraseña' type='password' name='password' placeholder='Contraseña' value={ password.value } onChange={ password.onChange } error={ error.password } errorMsg="Invalid password" disabled={ submit } big={ true } presymbol={ <MdLock /> } />
					
					<Button type='submit' disabled={ submit || !allow_login } big={ true }>Iniciar sesión</Button>
					
					{
						tipo !== 'administrador' && (
							<>
								{
									crear_cuenta && (
										<div className="recordar">
											<NavLink to={ `/registrar/${ tipo !== 'usuario' ? tipo : '' }` }>Crear una Cuenta</NavLink>
										</div>
									)
								}
								
								<div className="recordar">
									<NavLink to={ `/recuperar/${ tipo !== 'usuario' ? tipo : '' }` }>¿Has olvidado tu contraseña?</NavLink>
								</div>
							</>
						)
					}
				</form>
			</div>
		</div>
	);
}

export default LoginPage;