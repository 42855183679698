import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoExit } from 'react-icons/io5';

import '../../styles/ModalCrearEditarUsuario.scss';

import { openModal } from '../../features/layout/layoutSlice';
import { setList, addList } from '../../features/usuarios/usuariosSlice';

import apiURL from '../../hooks/apiURL';
import fetchData from '../../hooks/fetchData';
import useInput from '../../hooks/useInput';

import Button from '../../components/Button';
import InputField from '../../components/InputField';

const ModalCrearEditarUsuario = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.usuarios);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ usuario, setUsuario ] = useState(false);
	
	const nombre = useInput('');
	const apellidos = useInput('');
	const email = useInput('');
	const telefono = useInput('');
	const dni = useInput('');
	
	useEffect(() => {
		if (id) {
			const item = list.find(item => item.id === id);
			
			if (!item)
				close();
			else {
				setUsuario(item);
				
				nombre.setValue(item.nombre);
				apellidos.setValue(item.apellidos);
				email.setValue(item.email);
				telefono.setValue(item.telefono);
				dni.setValue(item.documento);
			}
		}
	}, [list]);
	
	const handleConfirm = e => {
		if (send) return null;
		
		setSend(true);
		
		if (!id) {
			fetchData(`create-usuario`, {
				method: 'POST',
				token,
				body: {
					nombre: nombre.value,
					apellidos: apellidos.value,
					email: email.value,
					telefono: telefono.value,
					dni: dni.value
				},
				cbk: ({ status, message, response }) => {
					dispatch(addList(response));
					close();
				}
			});
		}
		else {
			fetchData(`edit-usuario`, {
				method: 'POST',
				token,
				body: {
					id,
					
					nombre: nombre.value,
					apellidos: apellidos.value,
					email: email.value,
					telefono: telefono.value,
					dni: dni.value
				},
				cbk: ({ status, message, response }) => {
					let nList = [...list].map(item => {
						if (item.id === response.id)
							return response;
						
						return item;
					})
					
					dispatch(setList(nList));
					close();
				}
			});
		}
	}
	
	const handleResetear = () => {
		let nList = [...list].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.reseteada = true;
			
			return nItem;
		})
		
		dispatch(setList(nList));
		changeEstadoRemesa(id)
	}
	
	const changeEstadoRemesa = (id) => {
		fetchData(`resetear-password-usuario`, {
			method: 'POST',
			token,
			body: {
				id
			}
		});
	}
	
	return (
		<div className="drowers-modal-crear-editar-usuario">
			<div className="title">{ id ? 'Editar' : 'Crear' } Usuario</div>
			
			{ id && <div className="nombre">{ usuario.comercio_nombre }</div> }
			
			<div className="inside">
				<div className="row">
					<div className="col-2">
						<InputField label='Nombre' type='text' value={ nombre.value } onChange={ nombre.onChange } />
					</div>
					
					<div className="col-2">
						<InputField label='Apellidos' type='text' value={ apellidos.value } onChange={ apellidos.onChange } />
					</div>
				</div>
				
				<InputField label='Correo electrónico' type='text' value={ email.value } onChange={ email.onChange } />
				
				<div className="row">
					<div className="col-2">
						<InputField label='Teléfono' type='text' value={ telefono.value } onChange={ telefono.onChange } />
					</div>
					
					<div className="col-2">
						<InputField label='DNI' type='text' value={ dni.value } onChange={ dni.onChange } />
					</div>
				</div>
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Cancelar</Button>
				<Button green={ true } onClick={ handleConfirm } disabled={ send }>{ id ? 'Editar' : 'Crear' }</Button>
			</div>
			
			{ 
				id && (
					<div className="resetear">
						{ !usuario.reseteada && <div onClick={ handleResetear }>¿Contraseña olvidada? Resetear</div> }
						{ usuario.reseteada && <div className="set">Password reseteada a <b>1234</b></div> }
					</div>
				)
			}
		</div>
	);
}

export default ModalCrearEditarUsuario;