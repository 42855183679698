import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp, MdAccountBalanceWallet } from 'react-icons/md';
import { TbFilePlus, TbReportSearch } from 'react-icons/tb';
import { FaTrash } from 'react-icons/fa';
import { IoLogOut, IoEyeSharp, IoAlertCircle, IoDocumentText } from 'react-icons/io5';
import { RiEdit2Fill } from 'react-icons/ri';
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';

import '../styles/BuscarPage.scss';

import fetchData from '../hooks/fetchData';
import formatDate from '../hooks/formatDate';
import formatMoney from '../hooks/formatMoney';
import formatProgress from '../hooks/formatProgress';

import { openModal, setTitle, setConfig } from '../features/layout/layoutSlice';
import { setList as setListComercios } from '../features/comercios/comerciosSlice';
import { setList as setListUsuarios } from '../features/usuarios/usuariosSlice';
import { setList as setListAuditar } from '../features/auditoria/auditoriaSlice';
import { setList as setListRemesas, deleteList as deleteListRemesas } from '../features/remesas/remesasSlice';

import Loader from '../components/Loader';
import InfoCard from '../components/InfoCard';

const BuscarPageTableItemRemesas = ({ item, pagar, despagar }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useSelector(state => state.layout);
	
	const totalBonos = item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	
	const handlePagar = () => {
		pagar(item.id);
	}
	
	const handleDespagar = () => {
		despagar(item.id);
	}
	
	const handleBorrar = e => {
		fetchData(`delete-remesa`, {
			method: 'POST',
			token,
			body: {
				id: item.id
			},
			cbk: ({ status, message, response }) => {
				dispatch(deleteListRemesas(response));
			}
		});
	}
	
	return (
		<tr>
			<td className="check-field"># { item.id }</td>
			
			<td><b>{ item.codigo }</b></td>
			<td>{ formatDate('d.m.Y', item.fecha) }</td>
			<td>{ item.cupones.length }</td>
			<td>{ totalBonos }€</td>
			<td>
				{ item.estado === 0 && <div className="estado error">Sin pagar</div> }
				{ item.estado === 1 && <div className="estado pendiente">Pendiente</div> }
				{ item.estado === 2 && <div className="estado pagada">Pagado</div> }
			</td>
			<td>
				<div className="flex h-center">
					{
						item.estado === 0 && (
							<div className="btn pay" onClick={ handlePagar }>
								<MdAccountBalanceWallet className="icon" />Pagar
							</div>
						)
					}
					
					{
						item.estado === 2 && (
							<div className="btn unpay" onClick={ handleDespagar }>
								<MdAccountBalanceWallet className="icon" />Quitar Pagado
							</div>
						)
					}
					
					{
						item.estado === 0 && (
							<div className="btn borrar" onClick={ handleBorrar }>
								<FaTrash className="icon" />Borrar
							</div>
						)
					}
					
					<div className="btn txt">
						<IoDocumentText className="icon" />Archivo TXT
					</div>
				</div>
			</td>
		</tr>
	)
}

const BuscarPageTableItemComercios = ({ item, validar, desvalidar, suspender }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const handleDblClick = e => {
		navigate( `/comercios/${ item.id }` );
	}
	
	const handleValidar = () => {
		validar(item.id);
	}
	
	const handleDesvalidar = () => {
		desvalidar(item.id);
	}
	
	const handleSuspender = () => {
		suspender(item.id);
	}
	
	const handleGestionar = () => {
		dispatch(openModal( {id: 'gestionar-documentacion', extra: { id: item.id } }))
	}
	
	const handleEditar = () => {
		dispatch(openModal( {id: 'crear-comercio', extra: { id: item.id } }))
	}
	
	return (
		<tr onDoubleClick={ handleDblClick }>
			<td className="check-field"># { item.id }</td>
			
			<td>
				<div className="bi-line">
					<b>{ item.comercio_nombre }</b>
					<small>{ `${ item.nombre } ${ item.apellidos }` }</small>
					{ !!item.es_benefico && <small className="green">Benefico</small> }
				</div>
			</td>
			<td>
				<div className="bi-line">
					<b>{ item.email }</b>
					<small>{ item.telefono }</small>
				</div>
			</td>
			<td>
				{ item.estado === 0 && <div className="estado pendiente">Pendiente</div> }
				{ item.estado === 1 && <div className="estado pagada">Validado</div> }
				{ item.estado === -1 && <div className="estado error">Suspendido</div> }
			</td>
			<td>
				<div className="flex h-center">
					<div className="btn gestionar" onClick={ handleGestionar }>
						<IoDocumentText className="icon" />Gestionar
					</div>
				</div>
			</td>
			<td>
				<div className="flex h-center">
					{
						item.estado === 0 && (
							<div className="btn pay" onClick={ handleValidar }>
								<IoLogOut className="icon" />Validar
							</div>
						)
					}
					{
						item.estado === 1 && (
							<div className="btn unpay" onClick={ handleDesvalidar }>
								<IoLogOut className="icon" />Desvalidar
							</div>
						)
					}
					{
						item.estado === -1 && (
							<div className="btn unpay-2" onClick={ handleDesvalidar }>
								<IoLogOut className="icon" />Reactivar
							</div>
						)
					}
					
					{
						item.estado === 0 && (
							<div className="btn borrar" onClick={ handleSuspender }>
								<IoLogOut className="icon" />Suspender
							</div>
						)
					}
					
					<div className="btn txt" onClick={ handleEditar }>
						<RiEdit2Fill className="icon" />Editar
					</div>
				</div>
			</td>
		</tr>
	)
}

const BuscarPageTableItemVentas = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [ open, setOpen ] = useState(false);
	const { search } = useParams();
	
	useEffect(() => {
		if (item.abierto)
			setOpen(true);
	}, []);
	
	const handleClick = e => {
		setOpen(!open);
	}
	
	const handleAdjuntarFactura = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'adjuntar-factura', extra: { id: item.id } }));
	}
	
	const handleVerFactura = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'auditar', extra: { id: item.id } }));
	}
	
	const handleAddBono = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'adjuntar-bono', extra: { id: item.id } }));
	}
	
	const handleComercio = e => {
		navigate(`/buscar/${ item.comercio.comercio_nombre }`)
	}
	
	const handleUsuario = e => {
		navigate(`/buscar/${ item.consumidor_nombre }`)
	}
	
	const handleCupon = id => {
		navigate(`/buscar/${ id }`)
	}
	
	const totalBonos = item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	const redError = (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3);
	
	return (
		<>
			<tr onClick={ handleClick }>
				<td className="check-field">
					{ redError && <div className="red"><b># { item.id }</b></div> }
					{ !item.auditoria && <># { item.id }</> }
				</td>
				
				<td>
					<div className={ `bi-line ${ redError ? 'red' : '' }` }>
						<b onClick={ handleComercio }>{ item.comercio.comercio_nombre }</b>
						<small className={ redError ? 'red' : '' }>{ `${ item.comercio.nombre } ${ item.comercio.apellidos }` }</small>
					</div>
				</td>
				
				<td>
					<div className={ `bi-line ${ redError ? 'red' : '' }` }>
						<b onClick={ handleUsuario }>{ item.consumidor_nombre }</b>
						<small className={ redError ? 'red' : '' }>{ item.consumidor_telefono }</small>
					</div>
				</td>
				
				<td>
					{ redError && <div className="red"><b>{ formatDate('d.m.Y', item.fecha) }</b></div> }
					{ !item.auditoria && <>{ formatDate('d.m.Y', item.fecha) }</> }
				</td>
				
				<td>
					{ redError && <div className="red"><b>{ item.compra }€</b></div> }
					{ !item.auditoria && <>{ formatMoney(item.compra * 100) }€</> }
				</td>
				
				<td>
					{ redError && <div className="red"><b>{ totalBonos }€</b></div> }
					{ !item.auditoria && <>{ formatMoney(totalBonos * 100) }€</> }
				</td>
				
				<td>
					{ redError && <div className="red"><b>{ item.compra - totalBonos }€</b></div> }
					{ !item.auditoria && <>{ formatMoney((item.compra - totalBonos) * 100) }€</> }
				</td>
				
				<td>
					{ !item.auditar && <div className="estado pendiente">Pendiente</div> }
					{ item.auditar && item.auditar.estado === 0 && <div className="estado pagada">Validado</div> }
					{ item.auditar && item.auditar.estado !== 0 && <div className="estado error">Suspendido</div> }
				</td>
				<td>
					{
						item.remesa && (
							<div className="info-facturas flex h-center">
								{ item.remesa.estado !== 2 && <div className="info-remesa">Enviada al Ayuntamiento en la <b>Remesa Nº{ item.remesa.numero }</b></div> }
								{ item.remesa.estado === 2 && <div className="info-remesa pagada">Pagada en la <b>Remesa Nº{ item.remesa.numero }</b></div> }
								
								{
									totalBonos > 0 && (
										<div className="arrow">
											<MdKeyboardArrowDown className={ `icon ${ open ? 'open' : ''}` } />
										</div>
									)
								}
							</div>
						)
					}
					
					{
						!item.remesa && (
							<>
								<div className="info-facturas flex h-center">
									{
										!item.foto_factura && (
											<div className="adjuntar" onClick={ handleAdjuntarFactura }>
												<BsFillFileEarmarkTextFill className="icon" />Adjuntar factura
											</div>
										)
									}
									
									{
										item.foto_factura && (
											<div className="ver" onClick={ handleVerFactura }>
												<IoEyeSharp className="icon" />Auditar
											</div>
										)
									}
									
									<div className="adjuntar" onClick={ handleAddBono }>
										<IoEyeSharp className="icon" />Adjuntar bono
									</div>
									
									{
										totalBonos > 0 && (
											<div className="arrow">
												<MdKeyboardArrowDown className={ `icon ${ open ? 'open' : ''}` } />
											</div>
										)
									}
								</div>
								
								{ item.auditar && item.auditar.estado === 1 && (<div className="auditar-text"><IoAlertCircle className="icon" />La imagen está borrosa</div>) }
								{ item.auditar && item.auditar.estado === 2 && (<div className="auditar-text"><IoAlertCircle className="icon" />Los importes no coinciden</div>) }
								{ item.auditar && item.auditar.estado === 3 && (<div className="auditar-text"><IoAlertCircle className="icon" />Datos de cliente incorrectos</div>) }
							</>
						)
					}
				</td>
			</tr>
			
			{
				open && (
					<>
						{
							item.cupones.map((cupon, index) => 
								<tr key={ cupon.id } className={ `grey ${ index === 0 ? 'first' : ''} ${ index === item.cupones.length-1 ? 'last' : ''}`}>
									<td colSpan={ 4 } align="center" className="serial"><span onClick={ () => handleCupon(cupon.serial) }>{ cupon.serial === search ? <span className="highlight">{ cupon.serial }</span> : cupon.serial }</span></td>
									<td>{ cupon.serial === search ? <span className="highlight">{ cupon.valor }€</span> : <>{ cupon.valor}€</> }</td>
									<td>{ cupon.usuario.nombre } { cupon.usuario.apellidos }</td>
									<td colSpan={ 3 }>{ cupon.usuario.telefono }</td>
								</tr>
							)
						}
					</>
				)
			}
		</>
	)
}

const BuscarPageTableItemUsuarios = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { search } = useParams();
	
	const totalBonos = (item.cupones || []).reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	const totalBonosDisponibles = (item.cupones || []).reduce((acumulador, cupon) => {
		if (!cupon.id_compra)
			return acumulador + cupon.valor
		
		return acumulador;
	}, 0);
	
	const handleGestionar = () => {
		dispatch(openModal( {id: 'gestionar-bonos', extra: { id: item.id, highlight: search } }))
	}
	
	const handleEditar = () => {
		dispatch(openModal( {id: 'crear-usuario', extra: { id: item.id } }))
	}
	
	const handleRegistrar = () => {
		dispatch(openModal( {id: 'registrar-usuario', extra: { id: item.id } }))
	}
	
	return (
		<tr>
			<td className="check-field"># { item.sin_validar !== 1 ? item.id : '-' }</td>
			
			<td>
				{ item.sin_validar === 1 && (
						<div className="bi-line">
							<b>{ item.documento }</b>
							<small>Nacimiento { item.fecha }</small>
						</div>
					)
				}
				
				{ item.sin_validar !== 1 && (
						<div className="bi-line">
							<b>{ `${ item.nombre } ${ item.apellidos }` }</b>
							<small>Registro { formatDate('d.m.Y', item.fecha_creado) }</small>
						</div>
					)
				}
			</td>
			<td>
				{ item.sin_validar !== 1 && (
						<div className="bi-line">
							<b>{ item.email }</b>
							<small>{ item.telefono }</small>
						</div>
					)
				}
			</td>
			<td>
				{ item.sin_validar === 1 && <div className="estado">Sin registrarse</div> }
				{ item.sin_validar !== 1 && totalBonos > 0 && totalBonosDisponibles > 0 && totalBonos !== totalBonosDisponibles && <div className="estado utilizando">Utilizando</div> }
				{ item.sin_validar !== 1 && totalBonos > 0 && totalBonos === totalBonosDisponibles && <div className="estado pagada">Descargados</div> }
				{ item.sin_validar !== 1 && totalBonos === 0 && <div className="estado pendiente">Sin descargar</div> }
				{ item.sin_validar !== 1 && totalBonos > 0 && totalBonosDisponibles === 0 && <div className="estado error">Agotados</div> }
			</td>
			<td>
				{ item.sin_validar !== 1 && (
						<div className="bi-line">
							<b>{ totalBonos }€ canjeable</b>
							<small>{ totalBonosDisponibles }€ disponibles</small>
						</div>
					)
				}
			</td>
			<td>
				<div className="flex h-center">
					{ item.sin_validar === 1 && (
							<div className="btn pay" onClick={ handleRegistrar }>
								<IoDocumentText className="icon" />Registrar
							</div>
						)
					}
					
					{ item.sin_validar !== 1 && (
							<>
								<div className="btn gestionar" onClick={ handleGestionar }>
									<IoDocumentText className="icon" />Gestionar bonos
								</div>
								
								<div className="btn txt" onClick={ handleEditar }>
									<RiEdit2Fill className="icon" />Editar
								</div>
							</>
						)
					}
				</div>
			</td>
		</tr>
	)
}

const BuscarPage = () => {
	const dispatch = useDispatch();
	const { token, config } = useSelector(state => state.layout);
	const { list:listUsuarios } = useSelector(state => state.usuarios);
	const { list:listComercios } = useSelector(state => state.comercios);
	const { list:listRemesas } = useSelector(state => state.remesas);
	const { list:listAuditoria } = useSelector(state => state.auditoria);
	const navigate = useNavigate();
	const [ page, setPage ] = useState(0);
	const [ max, setMax ] = useState(10);
	const [ sort, setSort ] = useState(false);
	const [ init, setInit ] = useState(true);
	const [ sortDirection, setSortirection ] = useState(-1);
	const { search } = useParams();
	
	useEffect(() => {
		if (search.length >= 3) {
			setInit(false);
			dispatch(setListUsuarios([]));
			dispatch(setListAuditar([]));
			dispatch(setListComercios([]));
			dispatch(setListRemesas([]));
			
			dispatch(setTitle('Buscar'));
			
			fetchData(`buscar`, {
				method: 'POST',
				token,
				body: {
					s: search
				},
				cbk: ({ status, message, response }) => {
					//dispatch(setList(response));
					
					dispatch(setListUsuarios(response.usuarios));
					dispatch(setListAuditar(response.ventas));
					dispatch(setListComercios(response.comercios || []));
					dispatch(setListRemesas(response.remesas || []));
					
					
					setInit(true);
				}
			});
		}
		else
			navigate('/');
	}, [search]);
	
	const handleSort = (ord) => {
		if (!remesas.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	
	
	const handleValidar = (id) => {
		let nList = [...listComercios].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = 1;
			
			return nItem;
		})
		
		dispatch(setListComercios(nList));
		changeEstadoComercio(id, 1)
	}
	
	const handleDesvalidar = (id) => {
		let nList = [...listComercios].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = 0;
			
			return nItem;
		})
		
		dispatch(setListComercios(nList));
		changeEstadoComercio(id, 0)
	}
	
	const handleSuspender = (id) => {
		let nList = [...listComercios].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = -1;
			
			return nItem;
		})
		
		dispatch(setListComercios(nList));
		changeEstadoComercio(id, -1)
	}
	
	const changeEstadoComercio = (id, s) => {
		fetchData(`update-comercio-estado`, {
			method: 'POST',
			token,
			body: {
				id,
				s
			}
		});
	}
	
	const handlePagar = (id) => {
		let nList = [...listRemesas].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = 2;
			
			return nItem;
		})
		
		dispatch(setListRemesas(nList));
		changeEstadoRemesa(id, 2)
	}
	
	const handleDespagar = (id) => {
		let nList = [...listRemesas].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = 0;
			
			return nItem;
		})
		
		dispatch(setListRemesas(nList));
		changeEstadoRemesa(id, 0)
	}
	
	const changeEstadoRemesa = (id, s) => {
		fetchData(`update-remesa-pago`, {
			method: 'POST',
			token,
			body: {
				id,
				s
			}
		});
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
	
	
	
	let remesas = [...listRemesas]//.sort((a, b) => (new Date(b.create_at)).getTime() - (new Date(a.create_at)).getTime());
	let comercios = [...listComercios]//.sort((a, b) => (new Date(b.create_at)).getTime() - (new Date(a.create_at)).getTime());
	let ventas = [...listAuditoria]////.sort((a, b) => (new Date(b.create_at)).getTime() - (new Date(a.create_at)).getTime());
	let usuarios = [...listUsuarios]//.sort((a, b) => (new Date(b.fecha_creado)).getTime() - (new Date(a.fecha_creado)).getTime());
	
	if (sort) {
		//remesas = [...remesas].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	}

	return (
		<div className="buscar-page">
			<div className="title">Buscando: <span>{ search }</span></div>
			
			{
				!!remesas.length && (
					<div className="card">
						
						<div className="table-info">
							<div className="title">
								Remesas
								<div className="subtitle">Resultado de busqueda en remesas.</div>
							</div>
						</div>
						
						<div className="table-container">
							<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
								<thead>
									<tr>
										<td className="check-field" onClick={ () => handleSort('id') }># { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('codigo') } className="td-codigo">Código { sort === 'codigo' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('fecha') } className="td-fecha">Fecha { sort === 'fecha' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('bonos') } className="td-bonos">Bonos { sort === 'bonos' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('total') } className="td-total">Total { sort === 'total' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('estado') } className="td-estado">Estado { sort === 'estado' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td width={ 220 }>Acciones</td>
									</tr>
								</thead>
								
								<tbody>
									{
										[...remesas.slice(page*max, page*max + max )].map(item => <BuscarPageTableItemRemesas key={ item.id } item={ item } pagar={ handlePagar } despagar={ handleDespagar } /> )
									}
								</tbody>
							</table>
						</div>
						
						<div className="table-controls">
							<div className="page">
								{ page * max + 1 } al { ((page * max) + max) > remesas.length ? remesas.length : ((page * max) + max) } de { remesas.length } 
								
								<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
									<option value="5">5</option>
									<option value="7">7</option>
									<option value="10">10</option>
									<option value="20">20</option>
								</select>
							</div>
							
							
							<div className="pagination">
								<div className="options">
									<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
										<MdKeyboardArrowLeft />
									</div>
									
									{
										Array(Math.ceil(remesas.length / max)).fill(null).map((i, index) => 
											<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
												{ index+1 }
											</div>
										)
									}
									
									<div className={ page*max + max >= remesas.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
										<MdKeyboardArrowRight />
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
			
			
			{
				!!comercios.length && (
					<div className="card">
						
						<div className="table-info">
							<div className="title">
								Comercios
								<div className="subtitle">Resultado de busqueda en comercios.</div>
							</div>
						</div>
						
						<div className="table-container">
							<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
								<thead>
									<tr>
										<td className="check-field" onClick={ () => handleSort('id') }>ID { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('comercio_nombre') } className="td-comercio">Comercio { sort === 'comercio_nombre' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('email') } className="td-contacto">Contacto { sort === 'email' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('estado') } className="td-estado">Estado { sort === 'estado' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td width={ 160 } className="td-documentacion">Documentación</td>
										<td width={ 220 }>Acciones</td>
									</tr>
								</thead>
								
								<tbody>
									{
										[...comercios.slice(page*max, page*max + max )].map(item => <BuscarPageTableItemComercios key={ item.id } item={ item } validar={ handleValidar } desvalidar={ handleDesvalidar } suspender={ handleSuspender } /> )
									}
								</tbody>
							</table>
						</div>
						
						
						<div className="table-controls">
							<div className="page">
								{ page * max + 1 } al { ((page * max) + max) > comercios.length ? comercios.length : ((page * max) + max) } de { comercios.length } 
								
								<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
									<option value="5">5</option>
									<option value="7">7</option>
									<option value="10">10</option>
									<option value="20">20</option>
								</select>
							</div>
							
							
							<div className="pagination">
								<div className="options">
									<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
										<MdKeyboardArrowLeft />
									</div>
									
									{
										Array(Math.ceil(comercios.length / max)).fill(null).map((i, index) => 
											<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
												{ index+1 }
											</div>
										)
									}
									
									<div className={ page*max + max >= comercios.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
										<MdKeyboardArrowRight />
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
			
			
			{
				!!ventas.length && (
					<div className="card">
						
						<div className="table-info">
							<div className="title">
								Ventas
								<div className="subtitle">Resultado de busqueda en ventas.</div>
							</div>
						</div>
						
						<div className="table-container">
							<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
								<thead>
									<tr>
										<td className="check-field">ID</td>
										<td onClick={ () => handleSort('id') } className="tr-fecha">Comercio { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('id') } className="tr-fecha">Cliente <br />operación { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('id') } className="tr-fecha">Fecha <br />operación { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('id') } className="tr-importe">Importe <br />de la venta { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('id') } className="tr-bonos">Bonos <br />canjeados { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('id') } className="tr-pagar">A pagar por <br />el cliente { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('id') } className="tr-estado">Estado de <br />la factura { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										<td onClick={ () => handleSort('id') } className="tr-otras" width={ 100 }>Otras <br />operaciones { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
									</tr>
								</thead>
								
								<tbody>
									{
										[...ventas.slice(page*max, page*max + max )].map(item => <BuscarPageTableItemVentas key={ item.id } item={ item } /> )
									}
								</tbody>
							</table>
						</div>
						
						<div className="table-controls">
							<div className="page">
								{ page * max + 1 } al { ((page * max) + max) > ventas.length ? ventas.length : ((page * max) + max) } de { ventas.length } 
								
								<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
									<option value="5">5</option>
									<option value="7">7</option>
									<option value="10">10</option>
									<option value="20">20</option>
								</select>
							</div>
							
							
							<div className="pagination">
								<div className="options">
									<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
										<MdKeyboardArrowLeft />
									</div>
									
									{
										Array(Math.ceil(ventas.length / max)).fill(null).map((i, index) => 
											<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
												{ index+1 }
											</div>
										)
									}
									
									<div className={ page*max + max >= ventas.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
										<MdKeyboardArrowRight />
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
			
			
			{
				!!usuarios.length && (
					<div className="card">
						
						<div className="table-info">
							<div className="title">
								Usuarios
								<div className="subtitle">Resultado de busqueda en usuarios.</div>
							</div>
						</div>
						
						<div className="table-container">
							<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
								<thead>
									<tr>
										<td className="check-field">ID</td>
								<td onClick={ () => handleSort('id') } className="td-cliente">Cliente { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-contacto">Contacto { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-estado">Estado { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-bonos">Bonos { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td width={ 90 }>Acciones</td>
									</tr>
								</thead>
								
								<tbody>
									{
										[...usuarios.slice(page*max, page*max + max )].map(item => <BuscarPageTableItemUsuarios key={ item.id } item={ item } /> )
									}
								</tbody>
							</table>
						</div>
						
						<div className="table-controls">
							<div className="page">
								{ page * max + 1 } al { ((page * max) + max) > usuarios.length ? usuarios.length : ((page * max) + max) } de { usuarios.length } 
								
								<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
									<option value="5">5</option>
									<option value="7">7</option>
									<option value="10">10</option>
									<option value="20">20</option>
								</select>
							</div>
							
							
							<div className="pagination">
								<div className="options">
									<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
										<MdKeyboardArrowLeft />
									</div>
									
									{
										Array(Math.ceil(usuarios.length / max)).fill(null).map((i, index) => 
											<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
												{ index+1 }
											</div>
										)
									}
									
									<div className={ page*max + max >= usuarios.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
										<MdKeyboardArrowRight />
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
			
			
			{
				!remesas.length && !comercios.length && !ventas.length && !usuarios.length && (
					<div className="full-empty">
						<div className="btn grey no-click">
							<TbReportSearch className="icon" /> Sin resultados
						</div>
					</div>
				)
			}
		</div>
	);
}

export default BuscarPage;