import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RiSearchLine } from 'react-icons/ri';

import '../styles/EstablecimientosPage.scss';

import { setList, addList, deleteList } from '../features/establecimientos/establecimientosSlice';
import { setTitle } from '../features/layout/layoutSlice';

import fetchData from '../hooks/fetchData';
import useInput from '../hooks/useInput';

import Loader from '../components/Loader';
import Input from '../components/Input';
import Select from '../components/Select';
import ComercioCard from '../components/ComercioCard';

const EstablecimientosPage = () => {
	const dispatch = useDispatch();
	const { init, list } = useSelector(state => state.establecimientos);
	const nombre = useInput('');
	const sector = useInput('');
	const { token } = useSelector(state => state.layout);
	const [ filter, setFilter ] = useState(false);
	const [ sectores, setSectores ] = useState([]);
	
	useEffect(() => {
		dispatch(setTitle('Establecimientos'));
		
		fetchData(`get-establecimientos`, {
			token,
			cbk: ({ status, message, response }) => {
				setSectores(response.sectores);
				dispatch(setList(response.list));
			}
		});
	}, []);
	
	if (!init)
		return <div className="loader"><Loader /></div>;
	
	const handleFilter = () => {	
		setFilter(nombre.value);
	}
	
	const handleReset = () => {
		setFilter(false);
		
		nombre.setValue('')
		sector.setValue('')
	}
	
	let filter_list = [...list];
	
	if (filter || sector.value) {
		if (filter)
			filter_list = filter_list.filter(item => item.comercio_nombre.toLowerCase().indexOf(filter.toLowerCase()) > -1);
		
		if (sector.value)
			filter_list = filter_list.filter(item => Number(item.id_sector) === Number(sector.value));
	}
	
	return (
		<div className="establecimientos-page">
			<div className="title-page">Buscador de comercios</div>
			
			<div className="buscador">
				<div className="field">
					<div className="field-title">NOMBRE COMERCIO</div>
					<Input value={ nombre.value } onChange={ nombre.onChange } />
				</div>
				
				<div className="field">
					<div className="field-title">SECTOR</div>
					<Select value={ sector.value } onChange={ sector.onChange }>
						<option value=""></option>
						{
							sectores.map(item => <option key={ item.id } value={ item.id }>{ item.nombre }</option>)
						}
					</Select>
				</div>
				
				<div className="buscar" onClick={ handleFilter }>
					<RiSearchLine className="icon" />Buscar comercios
				</div>
				
				{
					(filter || sector.value) && (
						<div className="limpiar" onClick={ handleReset }>
							Limpiar filtro
						</div>
					)
				}
			</div>
			
			<div className="title-page">
				Listado de comercios
				
				<div className="badget">{ filter_list.length }</div>
			</div>
			
			
			<div className="row">
				{
					filter_list.map(item => (
						<div key={ item.id } className="col-4 col-pc-large-3 col-pc-medium-2 col-tablet-1">
							<ComercioCard data={ item } />
						</div>
					))
				}
			</div>
			
		</div>
	);
}

export default EstablecimientosPage;