import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RiErrorWarningFill } from 'react-icons/ri';
import { BsCheck } from 'react-icons/bs';
import { AiOutlineFileExclamation, AiOutlineFilePdf } from 'react-icons/ai';

import '../../styles/ModalAuditar.scss';

import { openModal } from '../../features/layout/layoutSlice';
import { setList } from '../../features/auditoria/auditoriaSlice';

import apiURL from '../../hooks/apiURL';
import baseWebURL from '../../hooks/baseWebURL';
import fetchData from '../../hooks/fetchData';
import formatMoney from '../../hooks/formatMoney';

import Button from '../../components/Button';

const ModalAuditar = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.auditoria);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ noValid, setNoValid ] = useState(false);
	const [ noValidOption, setNoValidOption ] = useState(0);
	const [ venta, setVenta ] = useState({});
	
	useEffect(() => {
		const v = list.find(item => item.id === id);
		
		setVenta(v);
		
		if (v.auditar && v.auditar.estado) {
			setNoValid(true);
			
			if (v.auditar && v.auditar.estado)
				setNoValidOption(v.auditar.estado)
		}
	}, []);
	
	const handleUnValid = e => {
		setNoValid(true);
		
		if (venta.auditar && venta.auditar.estado)
			setNoValidOption(venta.auditar.estado)
	}
	
	const handleValid = e => {
		sendEstatus(0)
	}
	
	const handleCancelNoValid = () => {
		setNoValid(false);
		setNoValidOption(0);
	}
	
	const sendEstatus = s => {
		fetchData(`create-auditar`, {
			method: 'POST',
			token,
			body: {
				id,
				s,
				u: venta.auditar && venta.auditar.id ? venta.auditar.id : undefined
			},
			cbk: ({ status, message, response }) => {
				const nList = [...list].map(item => {
					if (item.id !== id)
						return item;
					
					let nList = {...item};
					
					nList.auditar = response;
					nList.auditoria = s;
					
					return nList;
				});
				
				dispatch(setList(nList));
			}
		});
		
		close();
	}
	
	const handleNoValid = () => {
		sendEstatus(noValidOption)
	}
	
	const handleViewPdf = e => {
		window.open(`${ baseWebURL }Api/upload/facturas/${ venta.foto_factura }`);
	}
	
	const totalCupones = (venta.cupones || []).reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	
	return (
		<div className="drowers-modal-auditar">
			<div className="title">Auditar</div>
			
			<div className={ `inside ${ error ? 'error' : '' }` }>
				{
					venta.foto_factura && venta.foto_factura.indexOf('.pdf') < 0 && <img src={ `${ apiURL }upload/facturas/${ venta.foto_factura }` } className="factura" />
				}
				
				{
					venta.foto_factura && venta.foto_factura.indexOf('.pdf') > -1 && (
						<div className="pdf-factura">
							<div onClick={ handleViewPdf }>
								<AiOutlineFilePdf className="icon" />
								Ver
							</div>
						</div>
					)
				}
				
				{
					!venta.foto_factura && (
						<div className="falta-factura">
							<div>
								<AiOutlineFileExclamation className="icon" />
								Falta documento
							</div>
						</div>
					)
				}
				
				<div className="venta-hook">
					<div className="venta">
						<div className="line">
							<div className="title">Total <br />Venta</div>
							<div className="value">{ formatMoney(venta.compra * 100) }€</div>
						</div>
						
						<div className="separator" />
						
						<div className="line">
							<div className="title">Bonos <br />Canjeados</div>
							<div className="value green">-{ formatMoney(totalCupones * 100) }€</div>
						</div>
						
						<div className="separator" />
						
						<div className="line">
							<div className="title">A pagar por <br />el cliente</div>
							<div className="value">{ formatMoney((venta.compra - totalCupones) * 100) }€</div>
						</div>
					</div>
					
					<div className="buttons">
						<Button red={ true } onClick={ handleUnValid } disabled={ send }><RiErrorWarningFill className="icon" />No válida</Button>
						<Button green={ true } onClick={ handleValid } disabled={ send }><BsCheck className="icon" />Válida</Button>
					</div>
				</div>
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Cancelar</Button>
			</div>
			
			{
				noValid && <div className="no-valid-bg" />
			}
			
			{
				noValid && (
					<div className="no-valid">
						<div className="inside">
							<div className="title">¿Por qué no es válida?</div>
							
							<div className={ `option ${ noValidOption === 1 ? 'active' : '' }` } onClick={ () => setNoValidOption(1) }>La imagen está borrosa</div>
							
							<div className={ `option ${ noValidOption === 2 ? 'active' : '' }` } onClick={ () => setNoValidOption(2) }>Los importes no coinciden</div>
							
							<div className={ `option ${ noValidOption === 3 ? 'active' : '' }` } onClick={ () => setNoValidOption(3) }>Datos de cliente incorrectos</div>
							
							<div className="buttons">
								<Button grey={ true } onClick={ handleCancelNoValid } disabled={ send }>Cancelar</Button>
								<Button green={ true } onClick={ handleNoValid } disabled={ send }>Solicitar corregida</Button>
							</div>
						</div>
					</div>
				)
			}
		</div>
	);
}

export default ModalAuditar;