import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdOutlineSell, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp, MdAccountBalanceWallet } from 'react-icons/md';
import { TbFilePlus } from 'react-icons/tb';
import { FaTrash } from 'react-icons/fa';
import { IoLogOut, IoDocumentText } from 'react-icons/io5';
import { RiEdit2Fill } from 'react-icons/ri';

import '../styles/AuditoriaPage.scss';

import fetchData from '../hooks/fetchData';
import formatDate from '../hooks/formatDate';
import formatMoney from '../hooks/formatMoney';
import formatProgress from '../hooks/formatProgress';

import { openModal, setTitle, setConfig } from '../features/layout/layoutSlice';
import { setList } from '../features/auditoria/auditoriaSlice';

import Loader from '../components/Loader';
import InfoCard from '../components/InfoCard';

const AuditoriaPageTableItem = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const handleEditar = () => {
		dispatch(openModal( {id: 'auditar', extra: { id: item.id } }))
	}
	
	const totalBonos = item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	
	return (
		<tr>
			<td className="check-field"># { item.id }</td>
			
			<td>
				<div className="bi-line">
					<b>{ item.comercio.comercio_nombre }</b>
					<small>{ `${ item.comercio.nombre } ${ item.comercio.apellidos }` }</small>
				</div>
			</td>
			<td>
				<div className="bi-line">
					<b>{ item.comercio.email }</b>
					<small>{ item.comercio.telefono }</small>
				</div>
			</td>
			<td>{ formatDate('d.m.Y', item.fecha) }</td>
			<td>{ `${ formatMoney(item.compra * 100) }€` }</td>
			<td>{ `${ formatMoney(totalBonos * 100) }€` }</td>
			<td>{ `${ formatMoney((item.compra - totalBonos) * 100) }€` }</td>
			<td>
				{ !item.auditar && <div className="estado pendiente">Pendiente</div> }
				{ item.auditar && item.auditar.estado === 0 && <div className="estado pagada">Validado</div> }
				{ item.auditar && item.auditar.estado !== 0 && <div className="estado error">Suspendido</div> }
			</td>
			<td>
				<div className="flex h-center">
					<div className="btn txt" onClick={ handleEditar }>
						<RiEdit2Fill className="icon" />Auditar
					</div>
				</div>
			</td>
		</tr>
	)
}

const AuditoriaPage = () => {
	const dispatch = useDispatch();
	const { token, config } = useSelector(state => state.layout);
	const { list, init } = useSelector(state => state.auditoria);
	const { list:bonos } = useSelector(state => state.remesas);
	const [ search, setSearch ] = useState(false);
	const [ searchValue, setSearchValue ] = useState('');
	const [ page, setPage ] = useState(0);
	const [ max, setMax ] = useState(10);
	const [ sort, setSort ] = useState(false);
	const [ sortDirection, setSortirection ] = useState(-1);
	const [ hideValid, setHideValid ] = useState(false);
	const searchInput = useRef(null);
	
	useEffect(() => {
		dispatch(setTitle('Auditoria'));
		
		fetchData(`get-auditar`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setList(response));
			}
		});
		
		fetchData(`get-app-info`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setConfig(response));
			}
		});
	}, []);
	
	useEffect(() => {
		setPage(0);
	}, [searchValue]);
	
	const handleSort = (ord) => {
		if (!sort_list.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	const handleToggleHideValid = e => {
		setHideValid(!hideValid);
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
		
	let sort_list = [...list].sort((a, b) => (new Date(b.create_at)).getTime() - (new Date(a.create_at)).getTime())
	
	if (searchValue) {
		sort_list = [...sort_list].filter(item => 	item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.author.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.format.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
	}
	
	if (sort) {
		sort_list = [...sort_list].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	}
	
	if (hideValid) {
		sort_list = [...sort_list].filter(item => item && (!item.auditar || (item.auditar.estado !== 0)));
	}
	
	
	let importeCampania = config.presupuesto;
	let importePagado = config.pagado || 0;
	let importePendiente = config.pendiente || 0;
	let importeRestante = importeCampania - (importePagado * 100) - (importePendiente * 100);
	
	return (
		<div className="auditoria-page">
			<div className="row">
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida disponible' value={ `${ formatMoney(importeCampania) }€` } text={ <div>presupuesto de la campaña</div> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Dinero pagado' value={ `${ formatMoney(importePagado * 100) }€` } text={ <><span className="green">{ formatProgress((importePagado * 100) / importeCampania) }%</span><div>del total disponible</div></> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida restante' value={ `${ formatMoney(importeRestante) }€` } text={ <><span className="red">{ formatProgress((importeRestante * 100) / importeCampania) }%</span><div>de la partida disponible</div></> } />
				</div>
			</div>
			
			<div className="card">
				
				<div className="table-info">
					<div className="title">
						Gestionar ventas
						<div className="subtitle">Auditar las ventas de los comercios.</div>
					</div>
					
					<div className="options">
						<div className={ `btn grey ${ hideValid ? 'active' : '' }` } onClick={ handleToggleHideValid }>Ocultar validados</div>
					</div>
				</div>
				
				<div className="table-container">
					<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
						<thead>
							<tr>
								<td className="check-field" onClick={ () => handleSort('id') }>ID { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('comercio_nombre') } className="td-comercio">Comercio { sort === 'comercio_nombre' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('email') } className="td-contacto">Contacto { sort === 'email' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-fecha">Fecha <br />operación { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-importe">Importe <br />de la venta { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-bonos">Bonos <br />canjeados { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-pagar">A pagar por <br />el cliente { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('estado') } className="td-estado">Estado { sort === 'estado' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td width={ 60 }>Acciones</td>
							</tr>
						</thead>
						
						<tbody>
							{
								[...sort_list.slice(page*max, page*max + max )].map(item => <AuditoriaPageTableItem key={ item.id } item={ item } /> )
							}
							
							{
								!sort_list.length && (
									<tr>
										<td colSpan={ 9 } align="center" className="empty">
											<div className="empty">
												<div className="btn grey no-click">
													<MdOutlineSell /> Aun no hay ventas que auditar
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				
				<div className="table-controls">
					<div className="page">
						{
							sort_list.length > 0 && (
								<>
									{ page * max + 1 } al { ((page * max) + max) > sort_list.length ? sort_list.length : ((page * max) + max) } de { sort_list.length } 
									
									<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
										<option value="5">5</option>
										<option value="7">7</option>
										<option value="10">10</option>
										<option value="20">20</option>
									</select>
								</>
							)
						}
					</div>
					
					
					<div className="pagination">
						<div className="options">
							<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
								<MdKeyboardArrowLeft />
							</div>
							
							{
								Array(Math.ceil(sort_list.length / max)).fill(null).map((i, index) => 
									<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
										{ index+1 }
									</div>
								)
							}
							
							<div className={ page*max + max >= sort_list.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
								<MdKeyboardArrowRight />
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
}

export default AuditoriaPage;