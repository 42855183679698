import { TbTicket } from 'react-icons/tb';

import '../styles/InfoCard.scss';

const InfoCard = ({ title, value, text }) => {
	const className = ['info-card card'];
	
	return (
		<div className={ className.join(' ') }>
			<div className="title">{ title }</div>
			<div className="value">{ value }</div>
			<div className="text">{ text }</div>
		</div>
	);
}

export default InfoCard;