const formatMoney = (value) => {
	if (Number(value) === 0) return value;
	
	const opciones = {
	  style: 'decimal',
	  minimumFractionDigits: 0,
	  maximumFractionDigits: 2
	};
	
	const valorFormateado = (value/100).toLocaleString('es-ES', opciones);
	
	return valorFormateado;
}

export default formatMoney;