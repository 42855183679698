import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoExit } from 'react-icons/io5';

import '../../styles/ModalVerFactura.scss';

import { openModal } from '../../features/layout/layoutSlice';
import { setList } from '../../features/ventasRealizadas/ventasRealizadasSlice';
import { setList as SetListAuditoria } from '../../features/auditoria/auditoriaSlice';

import apiURL from '../../hooks/apiURL';
import fetchData from '../../hooks/fetchData';

import Button from '../../components/Button';

const ModalVerFactura = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.ventasRealizadas);
	const { list:auditoria } = useSelector(state => state.auditoria);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	
	const handleConfirm = e => {
		close();
	}
	
	const handleSustituir = e => {
		fetchData(`sustituir-factura`, {
			method: 'POST',
			token,
			body: {
				id
			},
			cbk: ({ status, message, response }) => {
				const nList = [...list].map(item => {
					const nItem = {...item};
					
					if (nItem.id === id) {
						nItem.foto_factura = undefined;
					}
					
					return nItem;
				})
				
				const nList2 = [...auditoria].map(item => {
					const nItem = {...item};
					
					if (nItem.id === id) {
						nItem.foto_factura = undefined;
					}
					
					return nItem;
				})
				
				dispatch(openModal({ id: 'adjuntar-factura', extra: { id } }));
				dispatch(setList(nList));
				dispatch(SetListAuditoria(nList2));
			}
		});
	}
	
	const venta = list.find(item => item.id === id);
	
	return (
		<div className="drowers-modal-ver-factura">
			<div className="title">Ver factura</div>
			
			<div className={ `inside ${ error ? 'error' : '' }` }>
				<img src={ `${ apiURL }upload/facturas/${ venta.foto_factura }` } className="factura" />
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ handleSustituir } disabled={ send }><IoExit className="icon" />Sustituir factura</Button>
				<Button green={ true } onClick={ handleConfirm } disabled={ send }>OK</Button>
			</div>
		</div>
	);
}

export default ModalVerFactura;