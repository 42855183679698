import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
	  const item = pathname.split('/').filter(item => item)[0];
	  
	  if (localStorage.lastUrl !== item)
		  window.scrollTo(0, 0);
		
		localStorage.lastUrl = item;
  }, [pathname]);

  return null;
}

export default ScrollToTop 