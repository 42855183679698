import { NavLink } from 'react-router-dom';

import '../styles/404.scss';

const NotFoundPage = () => {
	return (
		<div className="not-found-page">
			<div className="not-found-content">
				<div className="box">
					<div className="title">Woops !</div>
					<div className="subtitle">Página no encontrada</div>
					<div className="error">404</div>
					<NavLink to={ -2 } className="back">Volver</NavLink>
				</div>
			</div>
		</div>
	);
}

export default NotFoundPage;