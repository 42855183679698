import React from "react";
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { AiOutlineShop } from 'react-icons/ai';
import { HiChartPie } from 'react-icons/hi';
import { BsFillCalendarWeekFill } from 'react-icons/bs';

import '../styles/EstadisticasPage.scss';

import fetchData from '../hooks/fetchData';
import formatMoney from '../hooks/formatMoney';
import formatProgress from '../hooks/formatProgress';

import { openContext, setTitle, setConfig } from '../features/layout/layoutSlice';
import { setList } from '../features/estadisticas/estadisticasSlice';

import Loader from '../components/Loader';
import InfoCard from '../components/InfoCard';

const EstadisticasPageTableItem = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const handleDblClick = e => {
		navigate(`/videos/${ item.id }`);
	}
	
	const handleContextMenu = e => {
		e.preventDefault();
		e.stopPropagation();
		
		const diff = document.getElementsByClassName('app-container-sections')[0].getBoundingClientRect(),
					scrollY = document.getElementsByClassName('app-container-sections')[0].scrollTop;
		
		dispatch(openContext( {pos: {x: e.pageX - diff.x, y: e.pageY - diff.y + scrollY}, items: [ { title: 'View', endpoint: `/videos/${ item.id }` }, { title: 'Edit', endpoint: `/videos/${ item.id }/edit` }, { prop: 'videos', title: 'Block video', endpoint: `/videos/${ item.id }/block` }, { prop: 'videos', title: 'Delete', endpoint: `/delete-videos/${ item.id }`, confirm: true } ] } ));
	}
	
	return (
		<tr onDoubleClick={ handleDblClick } onContextMenu={ handleContextMenu }>
			<td># { item.id }</td>
			
			<td>
				<div className="bi-line">
					<b>Arte Libro</b>
				</div>
			</td>
			<td>{ `4.800€` }</td>
			<td>{ `24.000€` }</td>
			<td>
				{ item.id === 1 && <b className="green">x 4</b> }
				{ item.id === 2 && <b className="blue">x 3.1</b> }
				{ item.id === 3 && <b className="orange">x 3</b> }
			</td>
		</tr>
	)
}

const EstadisticasPageConsumoSectorItem = ({ item }) => {
	let color;
	
	switch (item.id) {
		case 1: color = 'dark'; break;
		case 2: color = 'blue'; break;
		case 3: color = 'purple'; break;
		case 4: color = 'pink'; break;
		case 5: color = 'red'; break;
		case 6: color = 'orange'; break;
		case 7: color = 'yellow'; break;
		
		default: color = 'grey'; break;
	}
	
	return (
		<div className="consumo-item">
			<div className="title-hook">
				<div className="title">{ item.title }</div>
				<div className="progress">{ `${ item.value }%` }</div>
			</div>
			
			<div className="progress-bar">
				<div className="bar">
					<div className={ color } style={{ width: `${ item.value }%` }} />
				</div>
			</div>
		</div>
	)
}


const EstadisticasPage = () => {
	const dispatch = useDispatch();
	const { token, config } = useSelector(state => state.layout);
	const { list, init } = useSelector(state => state.estadisticas);
	const { list:bonos } = useSelector(state => state.remesas);
	const [ search, setSearch ] = useState(false);
	const [ searchValue, setSearchValue ] = useState('');
	const [ page, setPage ] = useState(0);
	const [ max, setMax ] = useState(10);
	const [ sort, setSort ] = useState(false);
	const [ sortDirection, setSortirection ] = useState(-1);
	const searchInput = useRef(null);
	
	useEffect(() => {
		dispatch(setTitle('Estadísticas'));
		
		dispatch(setList([ {id: 1}, {id: 2}, {id: 3} ]));
		
		fetchData(`get-app-info`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setConfig(response));
			}
		});
	}, []);
	
	useEffect(() => {
		setPage(0);
	}, [searchValue]);
	
	const handleSort = (ord) => {
		if (!sort_list.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
		
	let sort_list = [...list].sort((a, b) => (new Date(b.create_at)).getTime() - (new Date(a.create_at)).getTime())
	
	if (searchValue) {
		sort_list = [...sort_list].filter(item => 	item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.author.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.format.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
	}
	
	if (sort) {
		sort_list = [...sort_list].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	}
	
	
	let importeCampania = config.presupuesto;
	let importePagado = config.pagado || 0;
	let importePendiente = config.pendiente || 0;
	let importeRestante = importeCampania - (importePagado * 100) - (importePendiente * 100);
	
	
	ChartJS.register(
	  CategoryScale,
	  LinearScale,
	  BarElement,
	  Title,
	  Tooltip,
	  Legend
	);
	
	
	
	const labels = Array(30).fill(null).map((i, index) => index+1 );
	
	const data = {
	  labels,
	  datasets: [
	    {
	      label: 'Bonos',
	      data: labels.map(() => Math.floor(Math.random() * 1000) ),
	      backgroundColor: '#b2def7',
	      borderRadius: 3,
	      hoverBackgroundColor: '#047bbe',
	    }
	  ],
	};
	
	const options = {
	  responsive: true,
	  maintainAspectRatio: false,
	  height: 200,
	  plugins: {
	    legend: {
	      display: false,
	    },
	    title: {
	      display: false,
	    },
	  },
	  scales: {
		  x: {
		    grid: {
		      display: false
		    }
		  },
	  }
	};
	
	
	return (
		<div className="estadisticas-page">
			<div className="row">
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida disponible' value={ `${ formatMoney(importeCampania) }€` } text={ <div>presupuesto de la campaña</div> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Dinero pagado' value={ `${ formatMoney(importePagado * 100) }€` } text={ <><span className="green">{ formatProgress((importePagado * 100) / importeCampania) }%</span><div>del total disponible</div></> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida restante' value={ `${ formatMoney(importeRestante) }€` } text={ <><span className="red">{ formatProgress((importeRestante * 100) / importeCampania) }%</span><div>de la partida disponible</div></> } />
				</div>
			</div>
			
			<div className="card">
				
				<div className="table-info">
					<div className="title">
						Estadísticas globales
						<div className="subtitle">Datos acumulados de la campaña</div>
					</div>
					
					<div className="options">
						<div className="btn grey">
							<HiChartPie /> <span className="mobile-hide">Dinero en bonos</span>
						</div>
						
						{ /*
						<div className="btn grey small-icon">
							<BsFillCalendarWeekFill /> <span className="mobile-hide">1 Sep - 30 Sep</span>
						</div>
						*/ }
					</div>
				</div>
				
				<div className="grafico">
					<Bar options={ options } data={ data } height={ 200 } />
				</div>
			</div>
			
			<div className="row">
				<div className="grow">
					<div className="card">
						<div className="table-info">
							<div className="title">
								Ranking comercios
								<div className="subtitle">Estadísticas por establecimientos</div>
							</div>
						</div>
						
						<div className="card-inside">
							<div className="table-container">
								<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
									<thead>
										<tr>
											<td className="td-posicion">Posición</td>
											<td onClick={ () => handleSort('id') } className="td-comercio">Comercio{ sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
											<td onClick={ () => handleSort('id') } className="td-bonos">Bonos <br />canjeados{ sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
											<td onClick={ () => handleSort('id') } className="td-compras">Compras <br />formalizadas{ sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
											<td onClick={ () => handleSort('id') } width={ 95 }>Dinero <br />promovido{ sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
										</tr>
									</thead>
									
									<tbody>
										{
											[...sort_list.slice(page*max, page*max + max )].map(item => <EstadisticasPageTableItem key={ item.id } item={ item } /> )
										}
										
										{
											!sort_list.length && (
												<tr>
													<td colSpan={ 5 } align="center" className="empty">
														<div className="empty">
															Empty
														</div>
													</td>
												</tr>
											)
										}
									</tbody>
								</table>
							</div>
							
							<div className="table-controls">
								<div className="page">
									{
										sort_list.length > 0 && (
											<>
												{ page * max + 1 } al { ((page * max) + max) > sort_list.length ? sort_list.length : ((page * max) + max) } de { sort_list.length } 
												
												<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
													<option value="5">5</option>
													<option value="7">7</option>
													<option value="10">10</option>
													<option value="20">20</option>
												</select>
											</>
										)
									}
								</div>
								
								
								<div className="pagination">
									<div className="options">
										<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
											<MdKeyboardArrowLeft />
										</div>
										
										{
											Array(Math.ceil(sort_list.length / max)).fill(null).map((i, index) => 
												<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
													{ index+1 }
												</div>
											)
										}
										
										<div className={ page*max + max >= sort_list.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
											<MdKeyboardArrowRight />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col-3 col-mobile-1">
					<div className="card">
						<div className="table-info">
							<div className="title">
								Consumo por sector
								<div className="subtitle">Uso de los bonos por sector.</div>
							</div>
						</div>
						
						<div className="consumo">
							<EstadisticasPageConsumoSectorItem item={ {id: 1, title: 'TECNOLOGÍA Y MULTIMEDIA', value: 45} } />
							<EstadisticasPageConsumoSectorItem item={ {id: 2, title: 'LIBRERÍA Y PAPELERÍA', value: 25} } />
							<EstadisticasPageConsumoSectorItem item={ {id: 3, title: 'ALIMENTACIÓN', value: 10} } />
							<EstadisticasPageConsumoSectorItem item={ {id: 4, title: 'ESTÉTICA Y COSMÉTICA', value: 6} } />
							<EstadisticasPageConsumoSectorItem item={ {id: 5, title: 'MODA Y COMPLEMENTOS', value: 5} } />
							<EstadisticasPageConsumoSectorItem item={ {id: 6, title: 'DEPORTE', value: 5} } />
							<EstadisticasPageConsumoSectorItem item={ {id: 7, title: 'SALUD', value: 4} } />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EstadisticasPage;