import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IoIosWarning } from 'react-icons/io';

import { closeContext, openModal, unlogin } from '../features/layout/layoutSlice';
import { setList } from '../features/comercios/comerciosSlice';

import LeftMenu from '../components/LeftMenu';
import Navbar from '../components/Navbar';


import MisBonosPage from './MisBonosPage';
import EstablecimientosPage from './EstablecimientosPage';


import ValidarBonosPage from './ValidarBonosPage';
import VentasRealizadasPage from './VentasRealizadasPage';


import BonosPage  from './BonosPage';
import ComerciosPage  from './ComerciosPage';
	import ComerciosVerPage  from './ComerciosVerPage';
import UsuariosPage  from './UsuariosPage';
import EstadisticasPage  from './EstadisticasPage';
import AuditoriaPage  from './AuditoriaPage';
import BuscarPage  from './BuscarPage';


import PublicRoutes from '../hooks/PublicRoutes';
import ProtectedRoutes from '../hooks/ProtectedRoutes';


const AppPage = ({ load }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { context, tipo, user } = useSelector(state => state.layout);
	
	const handleContextItem = (endpoint) => {
		if (endpoint.confirm) {
			dispatch(openModal({ id: 'confirm-delete', extra: { prop: endpoint.prop, endpoint: endpoint.endpoint } }));
		}
		else {
			if (endpoint.endpoint)
				navigate(endpoint.endpoint);
		}
	}
	
	const handleCloseContext = e => {
		dispatch(closeContext());
	}
	
	useEffect(() => {
		window.addEventListener('click', handleCloseContext);
		
		return () => {
			window.removeEventListener('click', handleCloseContext);
		}
	}, [])
	
	useEffect(() => {
		if (user && user.ficheros) {
			let all_files = true;
			
			user.ficheros.map(item => {
				if (!item.fichero)
					all_files = false;
			})
			
			if (!all_files) {
				dispatch(setList([{...user}]))
				dispatch(openModal({ id: 'gestionar-documentacion', extra: { id: user.id, noClose: true } }));
			}
			else {
				if (user.estado === 0)
					dispatch(openModal({ id: 'falta-validar' }));
				
				if (user.estado === -1) {
					delete localStorage.token;
					dispatch(unlogin());
				}
			}
		}
	}, [user])
	
	const fin = tipo === 'administrador' || (tipo !== 'comercio' && load.inicio === 1 && load.fin === 0) || (tipo === 'comercio' && load.fecha_registro_comercios === 1);
	const fin_bonos = tipo === 'administrador' || (tipo !== 'comercio' && load.inicio === 1 && load.fin === 0) || (tipo === 'comercio' && load.fecha_registro_comercios === 1);
	
	return (
		<>
			<LeftMenu />
			
			<div className="app-container">
				<Navbar />
				
				<div className="app-container-sections">
					{
						context.show && (
							<div className="drws-context" style={{ left: `${ context.extra.pos.x }px`, top: `${ context.extra.pos.y }px` }}>
								{
									context.extra.items.map((item, index) => {
										return (
											<div className="item" key={ index } onClick={ e => handleContextItem(item) }>
												{ item.title }
											</div>
										)
									})
								}
							</div>
						)
					}
					
					{
						!!load.fin && (
							<div className="alert danger">
								<IoIosWarning className="icon" /><div>La campaña ha terminado.</div>
							</div>
						)
					}
					
					{
						tipo === 'usuario' && (
							<Routes>
								<Route path='/' element={ <MisBonosPage /> } />
								
								<Route path='/establecimientos' element={ <EstablecimientosPage /> } />
								
								<Route path='/*' element={ <Navigate to='/404' /> } />
							</Routes>
						)
					}
					
					{
						tipo === 'comercio' && (
							<Routes>
								<Route path='/' element={ <ValidarBonosPage disponibles={ !load.fin && load.disponibles } /> } />
								
								<Route path='/ventas-realizadas'>
									<Route path='' element={ <VentasRealizadasPage disponibles={ !load.fin && load.disponibles } /> } />
									
									<Route path='ofertantes' element={ <>Listado de clientes - ofertantes</> } />
									<Route path='demandantes' element={ <>Listado de clientes - demandantes</> } />
								</Route>
								
								<Route path='/*' element={ <Navigate to='/404' /> } />
							</Routes>
						)
					}
					
					{
						tipo === 'administrador' && (
							<Routes>
								<Route path='/' element={ <BonosPage /> } />
								
								<Route path='/comercios'>
									<Route path='' element={ <ComerciosPage /> } />
									
									<Route path=':id'>
										<Route path='' element={ <ComerciosVerPage /> } />
									</Route>
								</Route>
								
								<Route path='/usuarios'>
									<Route path='' element={ <UsuariosPage /> } />
									
									<Route path=':id'>
										<Route path='' element={ <>Ver usuario</> } />
									</Route>
								</Route>
								
								<Route path='/estadisticas' element={ <EstadisticasPage /> } />
								
								<Route path='/auditoria' element={ <AuditoriaPage /> } />
								
								<Route path='/configuracion' element={ <>Configuración</> } />
								
								<Route path='/buscar/:search' element={ <BuscarPage /> } />
								
								<Route path='/*' element={ <Navigate to='/404' /> } />
							</Routes>
						)
					}
				</div>
			</div>
		</>
	);
}

export default AppPage;