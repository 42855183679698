import { QRCodeCanvas } from "qrcode.react";
import Barcode from 'react-barcode';
import { TbTicket } from 'react-icons/tb';
import { BsCheck } from 'react-icons/bs';

import '../styles/BonoCard.scss';

const BonoCard = ({ data, barCode }) => {
	const className = ['bono-card'];
	const { disabled, benefico } = data;
	
	if (disabled) className.push('card-disabled');
	if (benefico) className.push('card-benefico');
	
	return (
		<div className={ className.join(' ') }>
			<div className="card">
				<div className="qr">
					<QRCodeCanvas
			      id="qrCode"
			      value={ data.serial } 
			      size={ 300 }
			      bgColor='transparent'
			      level={"M"}
			    />
				</div>
				
				<div className="icon">
					<TbTicket />
				</div>
				
				<div className="text">VALE POR</div>
				
				<div className="value">{ data.valor }€</div>
				
				<div className="line"></div>
				
				<div className="text-2">CÓDIGO DEL BONO{ benefico ? ' BENÉFICO' : '' }</div>
				
				<div className="serial">{ data.serial }</div>
				
				{
					barCode && (
						<div className="bar-code">
							<Barcode value={ data.serial } format="CODE128" height={ 60 } margin={ 0 } displayValue={ false } />
						</div>
					)
				}
			</div>
			
			{
				disabled && (
					<div className="disabled-alert">
						<div className="info">
							<BsCheck className="info-icon" />
							Usado
						</div>
					</div>
				)
			}
		</div>
	);
}

export default BonoCard;