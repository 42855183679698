import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineUser, AiOutlineMenu } from 'react-icons/ai';
import { FaRegBell } from 'react-icons/fa';
import { RiSearchLine } from 'react-icons/ri';

import '../styles/Navbar.scss';

import { toggleMenu } from '../features/layout/layoutSlice';

const Navbar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { nombre } = useSelector(state => state.layout.user);
	const { role, title, menu, user } = useSelector(state => state.layout);
	const [ search, setSearch ] = useState(false);
	const [ searchValue, setSearchValue ] = useState('');
	const { search:searchParam } = useParams();
	
	const handleHome = () => {
		navigate('/');
	}
	
	const handleMenu = () => {
		dispatch(toggleMenu(!menu));
	}
	
	const toggleSearch = () => {
		if (searchValue) {
			handleSearchKey({ keyCode: 13 })
		}
		else 
			setSearch(!search);
	}
	
	const handleSearch = ({ target }) => {
		const { value } = target;
		
		setSearchValue(value)
	}
	
	const handleSearchKey = ({ keyCode }) => {
		if (keyCode === 13 && searchValue && searchValue.length >= 3)
			navigate(`/buscar/${ searchValue }`)
	}
	
	return (
		<div className="navbar">
			<div className="menu" onClick={ handleMenu }>
				<AiOutlineMenu className="icon" />
			</div>
			
			<div className="title">
				<div className="section">
					<div className="home" onClick={ handleHome }>INICIO</div> { title && <><span className="grey">/</span> <div className="grey">{ title }</div></> }
				</div>
				
				<div className="name">
					{ title }
				</div>
			</div>
			
			
			<div className="options">
				{
					role === 'Super Admin' && (
						<div className={ `search ${ search ? 'open' : '' }` }>
							<input value={ searchValue } onChange={ handleSearch } onKeyUp={ handleSearchKey } />
							
							<div className="icon" onClick={ toggleSearch }>
								<RiSearchLine />
							</div>
						</div>
					)
				}
				
				{ /*
				<div className="alerts">
					<FaRegBell />
				</div>
				*/ }
				
				<div className="profile">
					<div className="avatar">
						<AiOutlineUser />
					</div>
					
					<div className="data">
						<div className="name">{ nombre }</div>
						<div className="role">{ role }</div>
					</div>
				</div>
			</div>
			
			
		</div>
	);
}

export default Navbar;