import '../styles/Button.scss';

const Button = ({ children, type, disabled, grey, green, red, base, onClick, big }) => {
	const className = ['drws-button'];
	
	if (grey) className.push('grey');
	if (green) className.push('green');
	if (red) className.push('red');
	if (base) className.push('base');
	if (disabled) className.push('disabled');
	if (big) className.push('big');
	
	return (
		<button type={ type } className={ className.join(' ') } onClick={ onClick }>{ children }</button>
	);
}

export default Button;