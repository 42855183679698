import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import Loader from '../components/Loader';

const ProtectedRoutes = ({ children, redirectPath = '/login' }) => {
	const { init, login } = useSelector(state => state.layout);
	
	//
	
	if (!init)
		return <div className="loader full"><Loader /></div>;
	
	if (!login)
		return <Navigate to={ redirectPath } />;
	
  return children ? children : <Outlet />;
}

export default ProtectedRoutes;