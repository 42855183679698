import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoExit } from 'react-icons/io5';

import '../../styles/ModalCrearVenta.scss';

import { openModal } from '../../features/layout/layoutSlice';
import { setList, addList } from '../../features/auditoria/auditoriaSlice';

import apiURL from '../../hooks/apiURL';
import fetchData from '../../hooks/fetchData';
import useInput from '../../hooks/useInput';

import Button from '../../components/Button';
import InputField from '../../components/InputField';

const ModalCrearVenta = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.auditoria);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const regex = /[^0-9.]/g;
	
	const nombre = useInput('');
	const apellidos = useInput('');
	const telefono = useInput('');
	const importe = useInput('');
	
	const handleConfirm = e => {
		if (send) return null;
		
		setSend(true);
		
		fetchData(`create-venta`, {
			method: 'POST',
			token,
			body: {
				id,
				
				nombre: nombre.value,
				apellidos: apellidos.value,
				telefono: telefono.value,
				importe: (importe.value).replace(',', '.').replace(regex, "")
			},
			cbk: ({ status, message, response }) => {
				dispatch(addList(response));
				close();
			}
		});
	}
	
	return (
		<div className="drowers-modal-crear-venta">
			<div className="title">Crear venta</div>
			
			<div className="inside">
				<div className="row">
					<div className="col-2">
						<InputField label='Nombre' type='text' value={ nombre.value } onChange={ nombre.onChange } />
					</div>
					
					<div className="col-2">
						<InputField label='Apellidos' type='text' value={ apellidos.value } onChange={ apellidos.onChange } />
					</div>
				</div>
				
				<div className="row">
					<div className="col-2">
						<InputField label='Teléfono' type='text' value={ telefono.value } onChange={ telefono.onChange } />
					</div>
					
					<div className="col-2">
						<InputField label='Importe' type='text' value={ importe.value } onChange={ importe.onChange } />
					</div>
				</div>
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Cancelar</Button>
				<Button green={ true } onClick={ handleConfirm } disabled={ send }>Crear</Button>
			</div>
		</div>
	);
}

export default ModalCrearVenta;