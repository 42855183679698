import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import '../../styles/ModalFinalizarVenta.scss';

import { openModal } from '../../features/layout/layoutSlice';
import { addList } from '../../features/ventasRealizadas/ventasRealizadasSlice';

import fetchData from '../../hooks/fetchData';

import Button from '../../components/Button';

const ModalFinalizarVenta = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.validarBonos);
	const { id, importe, descuento, nombre, telefono } = extra;
	const [ send, setSend ] = useState(false);
	const regex = /[^0-9.]/g;
	
	const handleConfirm = e => {
		setSend(true);
		
		fetchData(`finalizar-venta`, {
			method: 'POST',
			token,
			body: {
				cupones: [...list].filter(item => item.estado === 1),
				importe,
				nombre,
				telefono
			},
			cbk: ({ status, message, response }) => {
				dispatch(addList(response))
				dispatch(openModal({ id: 'adjuntar-factura', extra: { id: response.id } }));
			},
			cbkError: response => {
				setSend(false);
			}
		});
	}
	
	let totalBonos = 0,
			iTotal = (importe).replace(',', '.').replace(regex, "");
	
	list.map(item => {
		if (item.estado === 1)
			totalBonos += item.valor;
	});
	
	return (
		<div className="drowers-modal-finalizar-venta">
			<div className="title">Finalizar venta</div>
			
			<div className="text">Comprueba que los datos introducidos <br />son correctos, estos bonos una vez consumidos <br />no se podrán devolver.</div>
			
			<div className="inside">
				<div className="line">
					<div className="titular">Total <br />Venta</div>
					<div className="valor">{ iTotal }€</div>
				</div>
				
				<div className="separador" />
				
				<div className="line">
					<div className="titular">Bonos <br />Canjeados</div>
					<div className="valor green">{ totalBonos }€</div>
				</div>
				
				{
					totalBonos < descuento && (
						<div className="total-descuento">
							<div>¡Puedes cargar hasta { descuento }€!</div>
						</div>
					)
				}
				
				<div className="line-final">
					<div className="titular">A pagar por <br />el cliente</div>
					<div className="valor">{ Number((iTotal - totalBonos).toFixed(2).split('.')[1]) === 0 ? Number(iTotal - totalBonos).toFixed(0) : (iTotal - totalBonos).toFixed(2) }€</div>
				</div>
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Volver</Button>
				<Button green={ true } onClick={ handleConfirm } disabled={ send }>Finalizar venta</Button>
			</div>
		</div>
	);
}

export default ModalFinalizarVenta;