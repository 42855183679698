import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate, Switch } from 'react-router-dom';

import ScrollToTop from "./app/scrollToTop";

import fetchData from './hooks/fetchData';

import { setInit, setLogin, setToken, setTipo, setRole, setUser, setConfig } from './features/layout/layoutSlice';

//

import './styles/Fonts.scss';
import './styles/App.scss';

//

import AppPage from './pages/AppPage';

import LoginPage from './pages/LoginPage';
import RegistrarPage from './pages/RegistrarPage';
import RecuperarPage from './pages/RecuperarPage';

import ModalModule from './modules/ModalModule';

import NotFoundPage from './pages/NotFoundPage';

import PublicRoutes from './hooks/PublicRoutes';
import ProtectedRoutes from './hooks/ProtectedRoutes';

//

import Loader from './components/Loader';

const App = () => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const [ load, setLoad ] = useState(false);
	
	useEffect(() => {
		fetchData(`get-status`, {
			cbk: ({ status, message, response }) => {
				setLoad(response);
			}
		});
	}, []);
	
	useEffect(() => {
		if (token) {
			fetchData(`authToken`, {
				method: 'POST',
				body: {
					token
				},
				cbk: ({ status, message, response }) => {
					if (status === 'success') {
						localStorage.token = token;
						
						dispatch(setUser(response.user));
						dispatch(setTipo(response.tipo));
						dispatch(setRole(response.role));
						dispatch(setConfig(response.config));
						dispatch(setLogin(true));
					}
					else {
						localStorage.clear();
					}
					
					dispatch(setInit(true));
				}
			});
		}
		
	}, [token]);
	
	const appHeight = () => {
	  const doc = document.documentElement;
	  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
	}

	useEffect(() => {
		const token = localStorage.token;
		
		if (token)
			dispatch(setToken(token));
		else
			dispatch(setInit(true));
		
		window.addEventListener('resize', appHeight);
		
		return () => {
			window.removeEventListener('resize', appHeight);
		}
	}, []);
	
	if (!load)
		return <div className="loader full"><Loader /></div>;
		
	return (
		<BrowserRouter>
			<ScrollToTop />
			
				<div className="app-root">
					<ModalModule />
					
					<Routes>
						<Route element={ <ProtectedRoutes /> }>
							<Route path='*' element={ <AppPage load={ load } /> } />
						</Route>
						
						<Route element={ <PublicRoutes /> }>
							
							<Route path='login'>
								<Route path='' element={ <LoginPage tipo='usuario' load={ load } /> } />
								<Route path='comercio' element={ <LoginPage tipo='comercio' load={ load } /> } />
								<Route path='administrador' element={ <LoginPage tipo='administrador' load={ load } /> } />
							</Route>
							
							<Route path='registrar'>
								<Route path='' element={ <RegistrarPage tipo='usuario' load={ load } /> } />
								<Route path='comercio' element={ <RegistrarPage tipo='comercio' load={ load } /> } />
							</Route>
							
							<Route path='recuperar'>
								<Route path='' element={ <RecuperarPage tipo='usuario' /> } />
								<Route path='comercio' element={ <RecuperarPage tipo='comercio' /> } />
								<Route path='administrador' element={ <RecuperarPage tipo='administrador' /> } />
							</Route>
							
						</Route>
						
						<Route path='404' element={ <NotFoundPage /> } />
					</Routes>
				</div>
		</BrowserRouter>
	);
}

export default App;