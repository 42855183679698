import '../styles/InputField.scss';

import Input from './Input';

const InputField = ({ label, type, name, placeholder, value, onChange, error, errorMsg, disabled, big, presymbol, symbol, autoComplete }) => {
	const className = ['input-field'];
	
	if (error) className.push('error');
	if (disabled) className.push('disabled');
	if (big) className.push('big');
	
	return (
		<div className={ className.join(' ') }>
			<div className="label">{ label }</div>
			
			<div className="input">
				<Input type={ type } name={ name } placeholder={ placeholder } value={ value } onChange={ onChange } error={ error } disabled={ disabled } big={ big } presymbol={ presymbol } symbol={ symbol } autoComplete={ autoComplete } />
			</div>
			
			{
				error && errorMsg && <div className="error-message">{ errorMsg }</div>
			}
		</div>
	);
}

export default InputField;