import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdOutlineBlock, MdOutlineRefresh } from 'react-icons/md';
import { HiTicket } from 'react-icons/hi';
import { TbReportSearch } from 'react-icons/tb';

import '../../styles/ModalGestionarBonos.scss';

import fetchData from '../../hooks/fetchData';

import { setList } from '../../features/usuarios/usuariosSlice';

import Button from '../../components/Button';

const ModalGestionarBonosCupon = ({ item, usuario, close, highlight }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.usuarios);
	
	const handleRemove = e => {
		fetchData(`eliminar-codigo`, {
			method: 'POST',
			token,
			body: {
				id: item.id
			},
			cbk: ({ status, message, response }) => {
				const nList = [...list].map(item2 => {
					if (item2.id !== usuario.id)
						return item2;
					
					const nItem2 = {...item2};
								nItem2.cupones = [...nItem2.cupones].filter(item3 => item3.id !== item.id);
					
					return nItem2;
				});
				
				dispatch(setList(nList));
			}
		});
	}
	
	const handleRefrescar = e => {
		const nList = [...list].map(item2 => {
			if (item2.id !== usuario.id)
				return item2;
			
			const nItem2 = {...item2};
						nItem2.cupones = [...nItem2.cupones].map(item3 => {
							if (item3.id !== item.id)
								return item3;
							
							const nItem3 = {...item3};
										nItem3.loading = true;
							
							return nItem3;
						});
			
			return nItem2;
		});
		
		dispatch(setList(nList));
		
		fetchData(`refrescar-codigo`, {
			method: 'POST',
			token,
			body: {
				id: item.id
			},
			cbk: ({ status, message, response }) => {
				const nList = [...list].map(item2 => {
					if (item2.id !== usuario.id)
						return item2;
					
					const nItem2 = {...item2};
								nItem2.cupones = [...nItem2.cupones].map(item3 => {
									if (item3.id !== item.id)
										return item3;
									
									const nItem3 = {...item3};
												nItem3.serial = response;
												nItem3.loading = false;
									
									return nItem3;
								});
					
					return nItem2;
				});
				
				dispatch(setList(nList));
			}
		});
	}
	
	const handleBuscar = e => {
		navigate(`/buscar/${ item.serial }`);
		close();
	}
	
	const serial = highlight === item.serial ? <span className="highlight">{ item.serial }</span> : item.serial;
	
	return (
		<div className={ `bono-line ${ item.benefico ? 'bono-benefico' : '' }` }>
			<div className="serial">
				{ !item.loading ? serial : <i style={{ opacity: .5 }}>Cargando...</i> }
				{ item.benefico === 1 && <div className="benefico">Bono benéfico</div> }
			</div>
			
			{ !item.loading && item.id_compra && <div className="estado gastado" onClick={ handleBuscar }>Utilizado en { item.comercio.comercio_nombre }</div> }
			{ !item.loading && !item.id_compra && <div className="option" onClick={ handleRemove }><MdOutlineBlock className="icon" /></div> }
			{ !item.loading && !item.id_compra && <div className="option" onClick={ handleRefrescar }><MdOutlineRefresh className="icon" /></div> }
		</div>
	)
}

const ModalGestionarBonos = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.usuarios);
	const { id, highlight } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	
	const handleAddBono = e => {
		fetchData(`add-codigo`, {
			method: 'POST',
			token,
			body: {
				id: usuario.id
			},
			cbk: ({ status, message, response }) => {
				const nList = [...list].map(item2 => {
					if (item2.id !== usuario.id)
						return item2;
					
					const nItem2 = {...item2};
								nItem2.cupones = [...nItem2.cupones, response];
					
					return nItem2;
				});
				
				dispatch(setList(nList));
			}
		});
	}
	
	const usuario = list.find(item => item.id === id);
	
	return (
		<div className="drowers-modal-gestionar-bonos">
			<div className="title">Gestionar bonos</div>
			
			<div className='inside'>
				{
					!usuario.cupones.length && <div className="sin-bonos"><TbReportSearch className="icon" />Aun no tiene bonos</div>
				}
				
				{
					usuario.cupones.map(item => <ModalGestionarBonosCupon key={ item.id } usuario={ usuario } item={ item } close={ close } highlight={ highlight } />)
				}
			</div>
			
			<div className="add-bono">
				<div onClick={ handleAddBono }><HiTicket className="icon" />Añadir bono</div>
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Cerrar</Button>
				<Button green={ true } onClick={ close } disabled={ send }>OK</Button>
			</div>
		</div>
	);
}

export default ModalGestionarBonos;