import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MdEmail } from 'react-icons/md';

import '../styles/RecuperarPage.scss';

import { setLogin, setToken, setTipo, setRole, setUser } from '../features/layout/layoutSlice';

import useInput from '../hooks/useInput';
import fetchData from '../hooks/fetchData';

import Input from '../components/Input';
import Button from '../components/Button';

import Logo from '../media/logo.png';
//import Background from '../media/login-background.png';

const RecuperarPage = ({ tipo }) => {
	const dispatch = useDispatch();
	const email = useInput('');
	const [ submit, setSubmit ] = useState(false);
	const [ error, setError ] = useState({});
	
	useEffect(() => {
		setError({});
	}, [email.value]);
	
	const handleSubmit = e => {
		e.preventDefault();
		
		setError({});
		
		if (!email.value) {
			const err = {};
			
			if (!email.value) err['email'] = true;
			
			setError(err);
			
			return null;
		}
		
		setSubmit(true);
		
		fetchData(`recovery`, {
			auth: true,
			method: 'POST',
			body: {
				email: email.value,
				tipo
			},
			cbk: ({ status, message, response }) => {
				setSubmit(false);
			},
			cbkError: response => {
				setSubmit(false);
			}
		});
	}
	
	return (
		<div className="recuperar-page">
			{ /*<img src={ Background } alt='background' className="background" />*/ }
			
			<div className="inside">
				<div className="logo">
					<img src={ Logo } alt='logo' />
				</div>
				
				<form onSubmit={ handleSubmit } className="box">
					<Input label='Usuario' type='text' name='email' placeholder='Correo electrónico' value={ email.value } onChange={ email.onChange } error={ error.email } errorMsg="Invalid email" disabled={ submit } big={ true } presymbol={ <MdEmail /> } />
					
					<Button type='submit' disabled={ submit } big={ true }>Recuperar { tipo }</Button>
					
					<div className="recordar">
						<NavLink to={ `/login/${ tipo !== 'usuario' ? tipo : '' }` }>Volver</NavLink>
					</div>
				</form>
			</div>
		</div>
	);
}

export default RecuperarPage;