import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp, MdAccountBalanceWallet } from 'react-icons/md';
import { TbFilePlus } from 'react-icons/tb';
import { FaTrash } from 'react-icons/fa';
import { IoDocumentText, IoDownload } from 'react-icons/io5';
import { CgSpinner } from 'react-icons/cg';

import '../styles/BonosPage.scss';

import fetchData from '../hooks/fetchData';
import formatDate from '../hooks/formatDate';
import formatMoney from '../hooks/formatMoney';
import formatProgress from '../hooks/formatProgress';

import { openContext, setTitle } from '../features/layout/layoutSlice';
import { setList, addList, deleteList } from '../features/remesas/remesasSlice';

import Loader from '../components/Loader';
import InfoCard from '../components/InfoCard';

const BonosPageTableItem = ({ item, pagar, despagar }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useSelector(state => state.layout);
	
	const totalBonos = item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	
	const handlePagar = () => {
		pagar(item.id);
	}
	
	const handleDespagar = () => {
		despagar(item.id);
	}
	
	const handleBorrar = e => {
		fetchData(`delete-remesa`, {
			method: 'POST',
			token,
			body: {
				id: item.id
			},
			cbk: ({ status, message, response }) => {
				dispatch(deleteList(response));
			}
		});
	}
	
	return (
		<tr>
			<td className="check-field"># { item.id }</td>
			
			<td><b>{ item.codigo }</b></td>
			<td>{ formatDate('d.m.Y', item.fecha) }</td>
			<td>{ item.cupones.length }</td>
			<td>{ totalBonos }€</td>
			<td>
				{ item.estado === 0 && <div className="estado error">Sin pagar</div> }
				{ item.estado === 1 && <div className="estado pendiente">Pendiente</div> }
				{ item.estado === 2 && <div className="estado pagada">Pagado</div> }
			</td>
			<td>
				<div className="flex h-center">
					{
						item.estado === 0 && (
							<div className="btn pay" onClick={ handlePagar }>
								<MdAccountBalanceWallet className="icon" />Pagar
							</div>
						)
					}
					
					{
						item.estado === 2 && (
							<div className="btn unpay" onClick={ handleDespagar }>
								<MdAccountBalanceWallet className="icon" />Quitar Pagado
							</div>
						)
					}
					
					{
						item.estado === 0 && (
							<div className="btn borrar" onClick={ handleBorrar }>
								<FaTrash className="icon" />Borrar
							</div>
						)
					}
					
					<div className="btn txt">
						<IoDocumentText className="icon" />Archivo TXT
					</div>
				</div>
			</td>
		</tr>
	)
}

const BonosPage = () => {
	const dispatch = useDispatch();
	const { token, config } = useSelector(state => state.layout);
	const { list, init } = useSelector(state => state.remesas);
	const [ search, setSearch ] = useState(false);
	const [ page, setPage ] = useState(0);
	const [ max, setMax ] = useState(10);
	const [ sort, setSort ] = useState('id');
	const [ sortDirection, setSortirection ] = useState(-1);
	const [ creating, setCreating ] = useState(false);
	const searchInput = useRef(null);
	
	useEffect(() => {
		dispatch(setTitle('Bonos'));
		
		fetchData(`get-remesas`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setList(response));
			}
		});
	}, []);
	
	const handleSort = (ord) => {
		if (!sort_list.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	const handleCreate = () => {
		if (creating) return null;
		
		setCreating(true);
		
		fetchData(`create-remesa`, {
			token,
			cbk: ({ status, message, response }) => {
				setCreating(false);
				if (response)
					dispatch(addList(response));
			}
		});
	}
	
	const handlePagar = (id) => {
		let nList = [...list].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = 2;
			
			return nItem;
		})
		
		dispatch(setList(nList));
		changeEstadoRemesa(id, 2)
	}
	
	const handleDespagar = (id) => {
		let nList = [...list].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = 0;
			
			return nItem;
		})
		
		dispatch(setList(nList));
		changeEstadoRemesa(id, 0)
	}
	
	const changeEstadoRemesa = (id, s) => {
		fetchData(`update-remesa-pago`, {
			method: 'POST',
			token,
			body: {
				id,
				s
			}
		});
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
		
	const sort_list = [...list].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	
	let importeCampania = config.presupuesto;
	let importePagado = 0;
	let importePendiente = 0;
	
	list.map(item => {
		if (item.estado === 2)
			importePagado += item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
		
		if (item.estado === 0)
			importePendiente += item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	})
	
	let importeRestante = importeCampania - (importePagado * 100) - (importePendiente * 100);
	
	return (
		<div className="bonos-page">
			<div className="row">
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida disponible' value={ `${ formatMoney(importeCampania) }€` } text={ <div>presupuesto de la campaña</div> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Dinero pagado' value={ `${ formatMoney(importePagado * 100) }€` } text={ <><span className="green">{ formatProgress((importePagado * 100) / importeCampania) }%</span><div>del total disponible</div></> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida restante' value={ `${ formatMoney(importeRestante) }€` } text={ <><span className="red">{ formatProgress((importeRestante * 100) / importeCampania) }%</span><div>de la partida disponible</div></> } />
				</div>
			</div>
			
			<div className="card">
				
				<div className="table-info">
					<div className="title">
						Remesas
						<div className="subtitle">Estado de los pagos a los comercios.</div>
					</div>
					
					<div className="options">
						<div className={ `btn ${ creating ? 'disabled' : ''}` } onClick={ handleCreate }>
							{ creating ? <CgSpinner className="rotating-element" /> : <TbFilePlus /> } Crear una nueva remesa
						</div>
					</div>
				</div>
				
				<div className="table-container">
					<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
						<thead>
							<tr>
								<td className="check-field" onClick={ () => handleSort('id') }># { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('codigo') } className="td-codigo">Código { sort === 'codigo' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('fecha') } className="td-fecha">Fecha { sort === 'fecha' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('bonos') } className="td-bonos">Bonos { sort === 'bonos' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('total') } className="td-total">Total { sort === 'total' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('estado') } className="td-estado">Estado { sort === 'estado' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td width={ 220 }>Acciones</td>
							</tr>
						</thead>
						
						<tbody>
							{
								[...sort_list.slice(page*max, page*max + max )].map(item => <BonosPageTableItem key={ item.id } item={ item } pagar={ handlePagar } despagar={ handleDespagar } /> )
							}
							
							{
								!sort_list.length && (
									<tr>
										<td colSpan={ 7 } align="center" className="empty">
											<div className="empty">
												<div className={ `btn ${ creating ? 'disabled' : ''}` } onClick={ handleCreate }>
													{ creating ? <CgSpinner className="rotating-element" /> : <TbFilePlus /> } Crear la primea remesa
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				
				<div className="table-controls">
					<div className="page">
						{
							sort_list.length > 0 && (
								<>
									{ page * max + 1 } al { ((page * max) + max) > sort_list.length ? sort_list.length : ((page * max) + max) } de { sort_list.length } 
									
									<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
										<option value="5">5</option>
										<option value="7">7</option>
										<option value="10">10</option>
										<option value="20">20</option>
									</select>
								</>
							)
						}
					</div>
					
					
					<div className="pagination">
						<div className="options">
							<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
								<MdKeyboardArrowLeft />
							</div>
							
							{
								Array(Math.ceil(sort_list.length / max)).fill(null).map((i, index) => 
									<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
										{ index+1 }
									</div>
								)
							}
							
							<div className={ page*max + max >= sort_list.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
								<MdKeyboardArrowRight />
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
}

export default BonosPage;