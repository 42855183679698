import '../styles/Select.scss';

const Select = ({ type, name, placeholder, value, onChange, error, disabled, big, children }) => {
	const className = ['drws-select'];
	
	if (error) className.push('error');
	if (disabled) className.push('disabled');
	if (big) className.push('big');
	
	return (
		<select type={ type } name={ name } placeholder={ placeholder } className={ className.join(' ') } value={ value } onChange={ onChange } disabled={ disabled }>
			{ children }
		</select>
	);
}

export default Select;