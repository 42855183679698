import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { MdEmail, MdLock, MdOutlineCancel } from 'react-icons/md';
import { FiUpload } from 'react-icons/fi';
import { IoIosWarning } from 'react-icons/io';

import '../styles/RegistrarPage.scss';

import { setLogin, setToken, setTipo, setRole, setUser, setConfig } from '../features/layout/layoutSlice';

import apiURL from '../hooks/apiURL';
import useInput from '../hooks/useInput';
import useFile from '../hooks/useFile';
import fetchData from '../hooks/fetchData';

import InputField from '../components/InputField';
import Select from '../components/Select';
import Button from '../components/Button';

import Logo from '../media/logo.png';
//import Background from '../media/login-background.png';

const RegistrarPage = ({ tipo, load }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [ ficheros, setFicheros ] = useState([]);
	const [ sectores, setSectores ] = useState([]);
	const [ progress, setProgress ] = useState(0);
	
	const nombre = useInput('');
	const apellidos = useInput('');
	const telefono = useInput('');
	const documento = useInput('');
	const dia = useInput('');
	const mes = useInput('');
	const anio = useInput('');
	
	const email = useInput('');
	const reemail = useInput('');
	const password = useInput('');
	const repassword = useInput('');
	
	const nombre_comercio = useInput('');
	const cif_nif = useInput('');
	const razon_social = useInput('');
	const direccion = useInput('');
	const iban = useInput('');
	const sector = useInput('');
	
	const fichero_logo = useFile([]);
	const ref_fichero_logo = useRef(null);
	
	const fichero_tienda = useFile([]);
	const ref_fichero_tienda = useRef(null);
	
	const fichero_1 = useFile([]);
	const ref_fichero_1 = useRef(null);
	const fichero_2 = useFile([]);
	const ref_fichero_2 = useRef(null);
	const fichero_3 = useFile([]);
	const ref_fichero_3 = useRef(null);
	const fichero_4 = useFile([]);
	const ref_fichero_4 = useRef(null);
	const fichero_5 = useFile([]);
	const ref_fichero_5 = useRef(null);
	const fichero_6 = useFile([]);
	const ref_fichero_6 = useRef(null);
	
	const politica = useInput(false);
	const terminos = useInput(false);
	const terceros = useInput(false);
	
	const numericoRegex = /^\d+$/;
	const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	const dniRegex = /^\d{8}[a-zA-Z]$/;
	const dniExtRegex = /^[XYZ]\d{7,8}[A-Z]$/;
	
	const [ submit, setSubmit ] = useState(false);
	const [ error, setError ] = useState({});
	const [ errorMsg, setErrorMsg ] = useState(false);
	
	useEffect(() => {
		const crear_cuenta = (tipo !== 'comercio' && load.inicio === 1 && load.fin === 0) || (tipo === 'comercio' && load.fecha_registro_comercios === 1 && load.fecha_fin_registro_comercios === 0 && load.fin === 0);
		
		if (!crear_cuenta)
			navigate( `/login/${ tipo }` );
			
		if (tipo === 'comercio') {
			fetchData(`get-register-data`, {
				method: 'GET',
				cbk: ({ status, message, response }) => {
					setFicheros(response.files);
					setSectores(response.sectores);
				}
			});
		}
	}, []);
	
	useEffect(() => {
		setError({});
		setErrorMsg(false);
	}, [nombre.value, apellidos.value, telefono.value, documento.value, dia.value, mes.value, anio.value, email.value, reemail.value, password.value, repassword.value, politica.value, terminos.value, terceros.value, nombre_comercio.value, cif_nif.value, razon_social.value, direccion.value, iban.value, sector.value]);
	
	const handleSubmit = e => {
		e.preventDefault();
		
		switch (tipo) {
			case 'usuario':
				handleSubmitUsuario();
				break;
			
			case 'comercio':
				handleSubmitComercio();
				break;
		}
	}
	
	const checkAge = (dob) => {
	  // Convertir la fecha de nacimiento en objeto Date
	  const birthDate = new Date(dob);
	  
	  // Obtener la fecha actual
	  const today = new Date();
	  
	  // Calcular la diferencia de tiempo entre las dos fechas
	  const diffTime = Math.abs(today.getTime() - birthDate.getTime());
	  
	  // Calcular la diferencia de años
	  const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
	  
	  // Verificar si la persona tiene más de 18 años
	  return diffYears >= 18;
	}
	
	const handleSubmitUsuario = e => {
		setError({});
		
		if (!nombre.value ||
				!apellidos.value ||
				!telefono.value ||
				!documento.value ||
				!dia.value ||
				!mes.value ||
				!anio.value ||
				!email.value ||
				!reemail.value ||
				!password.value ||
				!repassword.value ||
				!politica.value ||
				!terminos.value) {
			const err = {};
			
			if (!nombre.value) err['nombre'] = true;
			if (!apellidos.value) err['apellidos'] = true;
			if (!telefono.value) err['telefono'] = true;
			if (!documento.value) err['documento'] = true;
			if (!dia.value) err['dia'] = true;
			if (!mes.value) err['mes'] = true;
			if (!anio.value) err['anio'] = true;
			if (!email.value) err['email'] = true;
			if (!reemail.value) err['reemail'] = true;
			if (!password.value) err['password'] = true;
			if (!repassword.value) err['repassword'] = true;
			if (!politica.value) err['politica'] = true;
			if (!terminos.value) err['terminos'] = true;
			
			setError(err);
			setErrorMsg('Faltan campos por completar');
			
			return null;
		}
		
		if (email.value !== reemail.value) {
			setError({ email: true, reemail: true });
			setErrorMsg('El email no coincide');
			
			return null;
		}
		
		if (password.value !== repassword.value) {
			setError({ password: true, repassword: true });
			setErrorMsg('La contraseña no coincide');
			
			return null;
		}
		
		if (!numericoRegex.test(dia.value) || !numericoRegex.test(mes.value) || !numericoRegex.test(anio.value)) {
			const err = {};
			
			if (!numericoRegex.test(dia.value)) err['dia'] = true;
			if (!numericoRegex.test(mes.value)) err['mes'] = true;
			if (!numericoRegex.test(anio.value)) err['anio'] = true;
			
			setError(err);
			setErrorMsg('La fecha de nacimiento es incorrecta');
			
			return null;
		}
		
		if (!checkAge(`${ anio.value }-${ mes.value }-${ dia.value }`)) {
			setError({ dia: true, mes: true, anio: true });
			setErrorMsg('La campaña es para mayores de 18 años');
			
			return null;
		}
		
		if (!emailRegex.test(email.value) || !emailRegex.test(reemail.value)) {
			const err = {};
			
			if (!emailRegex.test(email.value)) err['email'] = true;
			if (!emailRegex.test(reemail.value)) err['reemail'] = true;
			
			setError(err);
			setErrorMsg('El formato de email es incorrecto');
			
			return null;
		}
		
		if (!dniRegex.test(documento.value) && !dniExtRegex.test(documento.value)) {
			setError({ documento: true });
			setErrorMsg('Documento incorrecto');
			
			return null;
		}
		
		setSubmit(true);
		
		
		fetchData(`register`, {
			auth: true,
			method: 'POST',
			body: {
				tipo,
				
				nombre: nombre.value,
				apellidos: apellidos.value,
				telefono: telefono.value,
				documento: documento.value,
				dia: dia.value,
				mes: mes.value,
				anio: anio.value,
				email: email.value,
				password: password.value
			},
			cbk: ({ status, message, response }) => {
				if (status === 'error') {
					setErrorMsg(message);
					setSubmit(false);
				}
				else {
					dispatch(setToken(response.token));
					dispatch(setTipo(response.tipo));
					dispatch(setRole(response.role));
					dispatch(setConfig(response.config));
					dispatch(setLogin(true));
				}
			},
			cbkError: response => {
				setSubmit(false);
				setErrorMsg('Vuelve a intentarlo');
			}
		});
	}
	
	const handleSubmitComercio = e => {
		setError({});
		
		if (!nombre.value ||
				!apellidos.value ||
				!telefono.value ||
				!email.value ||
				!reemail.value ||
				!password.value ||
				!repassword.value ||
				
				!nombre_comercio.value ||
				!cif_nif.value ||
				!razon_social.value ||
				!direccion.value ||
				!iban.value ||
				!sector.value ||
				
				!politica.value ||
				!terminos.value ||
				!terceros.value) {
			const err = {};
			
			if (!nombre.value) err['nombre'] = true;
			if (!apellidos.value) err['apellidos'] = true;
			if (!telefono.value) err['telefono'] = true;
			if (!email.value) err['email'] = true;
			if (!reemail.value) err['reemail'] = true;
			if (!password.value) err['password'] = true;
			if (!repassword.value) err['repassword'] = true;
			
			if (!nombre_comercio.value) err['nombre_comercio'] = true;
			if (!cif_nif.value) err['cif_nif'] = true;
			if (!razon_social.value) err['razon_social'] = true;
			if (!direccion.value) err['direccion'] = true;
			if (!iban.value) err['iban'] = true;
			if (!sector.value) err['sector'] = true;
			
			if (!politica.value) err['politica'] = true;
			if (!terminos.value) err['terminos'] = true;
			if (!terceros.value) err['terceros'] = true;
			
			setError(err);
			setErrorMsg('Faltan campos por completar');
			
			return null;
		}
		
		if (email.value !== reemail.value) {
			setError({ email: true, reemail: true });
			setErrorMsg('El email no coincide');
			
			return null;
		}
		
		if (password.value !== repassword.value) {
			setError({ password: true, repassword: true });
			setErrorMsg('La contraseña no coincide');
			
			return null;
		}
		
		if (!emailRegex.test(email.value) || !emailRegex.test(reemail.value)) {
			const err = {};
			
			if (!emailRegex.test(email.value)) err['email'] = true;
			if (!emailRegex.test(reemail.value)) err['reemail'] = true;
			
			setError(err);
			setErrorMsg('El formato de email es incorrecto');
			
			return null;
		}
		
		setSubmit(true);
		
		
		let data = new FormData();
				data.append('tipo', tipo);
				
				data.append('nombre', nombre.value);
				data.append('apellidos', apellidos.value);
				data.append('telefono', telefono.value);
				data.append('email', email.value);
				data.append('password', password.value);
				
				data.append('nombre_comercio', nombre_comercio.value);
				data.append('cif_nif', cif_nif.value);
				data.append('razon_social', razon_social.value);
				data.append('direccion', direccion.value);
				data.append('iban', iban.value);
				data.append('sector', sector.value);
				
				data.append('logo', fichero_logo.value[0]);
				data.append('foto', fichero_tienda.value[0]);
				
				ficheros.map((item, index) => {
					let fileState;
					
					switch (index) {
						case 0:
							fileState = fichero_1;
							break;
						
						case 1:
							fileState = fichero_2;
							break;
						
						case 2:
							fileState = fichero_3;
							break;
						
						case 3:
							fileState = fichero_4;
							break;
						
						case 4:
							fileState = fichero_5;
							break;
						
						case 5:
							fileState = fichero_6;
							break;
					}
					
					data.append(`fichero_${ index }`, fileState.value[0]);
				})

				
		//
		
		let request = new XMLHttpRequest();
				request.open('POST', `${ apiURL }v1/register`);
				
				// upload progress event
				request.upload.addEventListener('progress', (e) => {
					// upload progress as percentage
					setProgress((e.loaded / e.total) * 100);
				});
				
				// request finished event
				request.addEventListener('load', (e) => {
					// request.response holds response from the server
					const { status, message, response } = JSON.parse(request.response);
					
					if (status === 'error') {
						setErrorMsg(message);
						setSubmit(false);
					}
					else {
						dispatch(setToken(response.token));
						dispatch(setTipo(response.tipo));
						dispatch(setRole(response.role));
						dispatch(setConfig(response.config));
						dispatch(setLogin(true));
					}
				});
				
				// send POST request to server
				request.send(data);
	}
	
	return (
		<div className="registrar-page">
			{ /*<img src={ Background } alt='background' className="background" />*/ }
			
			<div className="inside">
				<div className="logo">
					<img src={ Logo } alt='logo' />
				</div>
				
				<form onSubmit={ handleSubmit } className="box">
					{
						errorMsg && (
							<div className="alert danger">
								<IoIosWarning className="icon" /><div><b>Error.</b> { errorMsg }.</div>
							</div>
						)
					}
					<div className="row">
						<div className="col-2 col-tablet-1">
							<InputField label='Nombre' type='text' placeholder='Nombre' value={ nombre.value } onChange={ nombre.onChange } error={ error.nombre } disabled={ submit } />
						</div>
						
						<div className="col-2 col-tablet-1">
							<InputField label='Apellidos' type='text' placeholder='Apellidos' value={ apellidos.value } onChange={ apellidos.onChange } error={ error.apellidos } disabled={ submit } />
						</div>
					</div>
					
					<div className="row">
						<div className="col-2 col-tablet-1">
							<InputField label='Teléfono' type='text' placeholder='Teléfono' value={ telefono.value } onChange={ telefono.onChange } error={ error.telefono } disabled={ submit } />
						</div>
						
						{
							tipo !== 'comercio' && (
								<div className="col-2 col-tablet-1">
									<InputField label='Documento' type='text' placeholder='documento' value={ documento.value } onChange={ documento.onChange } error={ error.documento } disabled={ submit } />
								</div>
							)
						}
					</div>
					
					{
						tipo !== 'comercio' && (
							<div className="row">
								<div className="col-2 col-tablet-1" />
								
								<div className="col-6 col-tablet-3">
									<InputField label='Día' type='text' placeholder='Dia' value={ dia.value } onChange={ dia.onChange } error={ error.dia } disabled={ submit } />
								</div>
								
								<div className="col-6 col-tablet-3">
									<InputField label='Mes' type='text' placeholder='Mes' value={ mes.value } onChange={ mes.onChange } error={ error.mes } disabled={ submit } />
								</div>
								
								<div className="col-6 col-tablet-3">
									<InputField label='Año' type='text' placeholder='Año' value={ anio.value } onChange={ anio.onChange } error={ error.anio } disabled={ submit } />
								</div>
							</div>
						)
					}
					
					<div className="line" />
					
					<div className="row">
						<div className="col-2 col-tablet-1">
							<InputField label='Email' type='text' placeholder='Email' value={ email.value } onChange={ email.onChange } error={ error.email } disabled={ submit } presymbol={ <MdEmail /> } />
						</div>
						
						<div className="col-2 col-tablet-1">
							<InputField label='Repetir Email' type='text' placeholder='Repetir email' value={ reemail.value } onChange={ reemail.onChange } error={ error.reemail } disabled={ submit } presymbol={ <MdEmail /> } />
						</div>
					</div>
					
					<div className="row">
						<div className="col-2 col-tablet-1">
							<InputField label='Contraseña' type='password' placeholder='Contraseña' value={ password.value } onChange={ password.onChange } error={ error.password } disabled={ submit } presymbol={ <MdLock /> } />
						</div>
						
						<div className="col-2 col-tablet-1">
							<InputField label='Repetir Contraseña' type='password' placeholder='Repetir contraseña' value={ repassword.value } onChange={ repassword.onChange } error={ error.repassword } disabled={ submit } presymbol={ <MdLock /> } />
						</div>
					</div>
					
					<div className="line" />
					
					{
						tipo === 'comercio' && (
							<>
								<div className="row">
									<div className="col-2 col-tablet-1">
										<InputField label='Nombre del Comercio' type='text' placeholder='Nombre del Comercio' value={ nombre_comercio.value } onChange={ nombre_comercio.onChange } error={ error.nombre_comercio } disabled={ submit } />
									</div>
									
									<div className="col-2 col-tablet-1">
										<InputField label='CIF/NIF' type='text' placeholder='CIF/NIF' value={ cif_nif.value } onChange={ cif_nif.onChange } error={ error.cif_nif } disabled={ submit } />
									</div>
								</div>
								
								<div className="row">
									<div className="col-2 col-tablet-1">
										<InputField label='Razón Social' type='text' placeholder='Razón Social' value={ razon_social.value } onChange={ razon_social.onChange } error={ error.razon_social } disabled={ submit } />
									</div>
									
									<div className="col-2 col-tablet-1">
										<InputField label='Dirección' type='text' placeholder='Dirección' value={ direccion.value } onChange={ direccion.onChange } error={ error.direccion } disabled={ submit } />
									</div>
								</div>
								
								<div className="row">
									<div className="col-2 col-tablet-1">
										<InputField label='IBAN' type='text' placeholder='IBAN' value={ iban.value } onChange={ iban.onChange } error={ error.iban } disabled={ submit } />
									</div>
									
									<div className="col-2 col-tablet-1">
										<div className="input-field">
											<div className={ `label ${ error.sector ? 'red' : '' }` }>Sector</div>
											<Select value={ sector.value } onChange={ sector.onChange } error={ error.sector }>
												<option hidden value=''></option>
												
												{
													sectores.map(item => <option key={ item.id } value={ item.id }>{ item.nombre }</option>)
												}
											</Select>
										</div>
									</div>
								</div>
								
								<div className="adjuntar-fichero">
									<div className="fichero-titulo">Foto Logo</div>
									<div className="fichero-file">
										{
											!fichero_logo.value.length && (
												<div className="fichero-estado upload" onClick={ () => ref_fichero_logo.current.click() }>
													<FiUpload className="icon" />Subir
												</div>
											)
										}
										{
											!!fichero_logo.value.length && (
												<div className="fichero-estado cancelar" onClick={ () => fichero_logo.setValue([]) }>
													<MdOutlineCancel className="icon" />Cancelar
												</div>
											)
										}
										
										<input type="file" style={{ display: 'none' }} ref={ ref_fichero_logo } onChange={ fichero_logo.onChange } />
									</div>
								</div>
								
								<div className="adjuntar-fichero">
									<div className="fichero-titulo">Foto tienda</div>
									<div className="fichero-file">
										{
											!fichero_tienda.value.length && (
												<div className="fichero-estado upload" onClick={ () => ref_fichero_tienda.current.click() }>
													<FiUpload className="icon" />Subir
												</div>
											)
										}
										{
											!!fichero_tienda.value.length && (
												<div className="fichero-estado cancelar" onClick={ () => fichero_tienda.setValue([]) }>
													<MdOutlineCancel className="icon" />Cancelar
												</div>
											)
										}
										
										<input type="file" style={{ display: 'none' }} ref={ ref_fichero_tienda } onChange={ fichero_tienda.onChange } />
									</div>
								</div>
							
								<div className="line" />
									{
										ficheros.map((item, index) => {
											let file,
													fileState;
											
											switch (index) {
												case 0:
													file = ref_fichero_1;
													fileState = fichero_1;
													break;
												
												case 1:
													file = ref_fichero_2;
													fileState = fichero_2;
													break;
												
												case 2:
													file = ref_fichero_3;
													fileState = fichero_3;
													break;
												
												case 3:
													file = ref_fichero_4;
													fileState = fichero_4;
													break;
												
												case 4:
													file = ref_fichero_5;
													fileState = fichero_5;
													break;
												
												case 5:
													file = ref_fichero_6;
													fileState = fichero_6;
													break;
											}
											return (
												<div key={ item.id } className="adjuntar-fichero">
													<div className="fichero-titulo">{ item.nombre } (Imagen o PDF)</div>
													<div className="fichero-file">
															
														{
															!fileState.value.length && (
																<div className="fichero-estado upload" onClick={ () => file.current.click() }>
																	<FiUpload className="icon" />Subir
																</div>
															)
														}
														{
															!!fileState.value.length && (
																<div className="fichero-estado cancelar" onClick={ () => fileState.setValue([]) }>
																	<MdOutlineCancel className="icon" />Cancelar
																</div>
															)
														}
													
														<input type="file" style={{ display: 'none' }} ref={ file } onChange={ fileState.onChange } />
													</div>
												</div>
											)
										})
									}
								
								<div className="line" />
							</>
						)
					}
					
					<div className={ submit ? 'disabled' : '' }>
						<div className="adjuntar-fichero checkbox">
							<label className={ error.politica ? 'red' : '' }>
								<input type="checkbox" checked={ politica.value } onChange={ e => politica.setValue(e.target.checked) } />
								Acepto la Política de privacidad.
							</label>
						</div>
						
						<div className="adjuntar-fichero checkbox">
							<label className={ error.terminos ? 'red' : '' }>
								<input type="checkbox" checked={ terminos.value } onChange={ e => terminos.setValue(e.target.checked) } />
								Acepto los Términos y condiciones.
							</label>
						</div>
						
						{
							tipo === 'comercio' && (
								<div className="adjuntar-fichero checkbox">
									<label className={ error.terceros ? 'red' : '' }>
										<input type="checkbox" checked={ terceros.value } onChange={ e => terceros.setValue(e.target.checked) } />
										Dispongo de la Ficha de terceros.
									</label>
								</div>
							)
						}
					</div>
					
					<div className="line" />
					
					{
						submit && tipo === 'comercio' && <div className="progress"><div style={{ width: `${ progress }%` }} /></div>
					}
					
					<Button type='submit' disabled={ submit }>Registrar { tipo }</Button>
					
					<div className="volver">
						<NavLink to={ `/login/${ tipo !== 'usuario' ? tipo : '' }` }>Volver</NavLink>
					</div>
				</form>
			</div>
		</div>
	);
}

export default RegistrarPage;