import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoExit } from 'react-icons/io5';

import '../../styles/ModalCrearEditarComercio.scss';

import { openModal } from '../../features/layout/layoutSlice';
import { setList, addList } from '../../features/comercios/comerciosSlice';

import apiURL from '../../hooks/apiURL';
import fetchData from '../../hooks/fetchData';
import useInput from '../../hooks/useInput';

import Button from '../../components/Button';
import InputField from '../../components/InputField';

const ModalCrearEditarComercio = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.comercios);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ comercioItem, setComercioItem ] = useState(false);
	
	const nombre = useInput('');
	const apellidos = useInput('');
	const email = useInput('');
	const telefono = useInput('');
	const dni = useInput('');
	const comercio = useInput('');
	const razonsocial = useInput('');
	
	const es_benefico = useInput('');
	
	useEffect(() => {
		if (id) {
			const item = list.find(item => item.id === id);
			
			if (!item)
				close();
			else {
				setComercioItem(item);
				
				nombre.setValue(item.nombre);
				apellidos.setValue(item.apellidos);
				email.setValue(item.email);
				telefono.setValue(item.telefono);
				dni.setValue(item.comercio_documento);
				comercio.setValue(item.comercio_nombre);
				razonsocial.setValue(item.comercio_razon_social);
				es_benefico.setValue(item.es_benefico);
			}
		}
	}, [list]);
	
	const handleConfirm = e => {
		if (send) return null;
		
		setSend(true);
		
		if (!id) {
			fetchData(`create-comercio`, {
				method: 'POST',
				token,
				body: {
					nombre: nombre.value,
					apellidos: apellidos.value,
					email: email.value,
					telefono: telefono.value,
					dni: dni.value,
					comercio: comercio.value,
					razonsocial: razonsocial.value,
					es_benefico: es_benefico.value
				},
				cbk: ({ status, message, response }) => {
					dispatch(addList(response));
					close();
				}
			});
		}
		else {
			fetchData(`edit-comercio`, {
				method: 'POST',
				token,
				body: {
					id,
					
					nombre: nombre.value,
					apellidos: apellidos.value,
					email: email.value,
					telefono: telefono.value,
					dni: dni.value,
					comercio: comercio.value,
					razonsocial: razonsocial.value,
					es_benefico: es_benefico.value
				},
				cbk: ({ status, message, response }) => {
					let nList = [...list].map(item => {
						if (item.id === response.id)
							return response;
						
						return item;
					})
					
					dispatch(setList(nList));
					close();
				}
			});
		}
	}
	
	const handleResetear = () => {
		let nList = [...list].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.reseteada = true;
			
			return nItem;
		})
		
		setComercioItem(nList.find(item => item.id === id));
		
		dispatch(setList(nList));
		changeEstadoRemesa(id)
	}
	
	const changeEstadoRemesa = (id) => {
		fetchData(`resetear-password-comercio`, {
			method: 'POST',
			token,
			body: {
				id
			}
		});
	}
	
	
	
	return (
		<div className="drowers-modal-crear-editar-comercio">
			<div className="title">{ id ? 'Editar' : 'Crear' } Comercio</div>
			
			{ id && <div className="nombre">{ comercioItem.comercio_nombre }</div> }
			
			<div className="inside">
				<div className="row">
					<div className="col-2">
						<InputField label='Nombre' type='text' value={ nombre.value } onChange={ nombre.onChange } />
					</div>
					
					<div className="col-2">
						<InputField label='Apellidos' type='text' value={ apellidos.value } onChange={ apellidos.onChange } />
					</div>
				</div>
				
				<InputField label='Correo electrónico' type='text' value={ email.value } onChange={ email.onChange } />
				
				<div className="row">
					<div className="col-2">
						<InputField label='Teléfono' type='text' value={ telefono.value } onChange={ telefono.onChange } />
					</div>
					
					<div className="col-2">
						<InputField label='DNI' type='text' value={ dni.value } onChange={ dni.onChange } />
					</div>
				</div>
				
				<div className="row">
					<div className="col-2">
						<InputField label='Comercio' type='text' value={ comercio.value } onChange={ comercio.onChange } />
					</div>
					
					<div className="col-2">
						<InputField label='Razón social' type='text' value={ razonsocial.value } onChange={ razonsocial.onChange } />
					</div>
				</div>
				
				<div className="row">
					<div className="col-1">
						<label className='label'>
							<input type="checkbox" checked={ es_benefico.value } onChange={ e => es_benefico.setValue(e.target.checked) } />
							Es benéfico
						</label>
					</div>
				</div>
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Cancelar</Button>
				<Button green={ true } onClick={ handleConfirm } disabled={ send }>{ id ? 'Editar' : 'Crear' }</Button>
			</div>
			
			{ 
				id && (
					<div className="resetear">
						{ !comercioItem.reseteada && <div onClick={ handleResetear }>¿Contraseña olvidada? Resetear</div> }
						{ comercioItem.reseteada && <div className="set">Password reseteada a <b>1234</b></div> }
					</div>
				)
			}
		</div>
	);
}

export default ModalCrearEditarComercio;