import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp, MdAccountBalanceWallet } from 'react-icons/md';
import { TbFilePlus } from 'react-icons/tb';
import { FaTrash } from 'react-icons/fa';
import { IoDocumentText } from 'react-icons/io5';
import { AiOutlineUser } from 'react-icons/ai';
import { RiEdit2Fill } from 'react-icons/ri';

import '../styles/UsuariosPage.scss';

import fetchData from '../hooks/fetchData';
import formatDate from '../hooks/formatDate';
import formatMoney from '../hooks/formatMoney';
import formatProgress from '../hooks/formatProgress';

import { openModal, setTitle, setConfig } from '../features/layout/layoutSlice';
import { setList } from '../features/usuarios/usuariosSlice';

import Loader from '../components/Loader';
import InfoCard from '../components/InfoCard';

const UsuariosPageTableItem = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const totalBonos = item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	const totalBonosDisponibles = item.cupones.reduce((acumulador, cupon) => {
		if (!cupon.id_compra)
			return acumulador + cupon.valor
		
		return acumulador;
	}, 0);
	
	const handleGestionar = () => {
		dispatch(openModal( {id: 'gestionar-bonos', extra: { id: item.id } }))
	}
	
	const handleEditar = () => {
		dispatch(openModal( {id: 'crear-usuario', extra: { id: item.id } }))
	}
	
	return (
		<tr>
			<td className="check-field"># { item.id }</td>
			
			<td>
				<div className="bi-line">
					<b>{ `${ item.nombre } ${ item.apellidos }` }</b>
					<small>Registro { formatDate('d.m.Y', item.fecha_creado) }</small>
				</div>
			</td>
			<td>
				<div className="bi-line">
					<b>{ item.email }</b>
					<small>{ item.telefono }</small>
				</div>
			</td>
			<td>
				{ totalBonos > 0 && totalBonosDisponibles > 0 && totalBonos !== totalBonosDisponibles && <div className="estado utilizando">Utilizando</div> }
				{ totalBonos > 0 && totalBonos === totalBonosDisponibles && <div className="estado pagada">Descargados</div> }
				{ totalBonos === 0 && <div className="estado pendiente">Sin descargar</div> }
				{ totalBonos > 0 && totalBonosDisponibles === 0 && <div className="estado error">Agotados</div> }
			</td>
			<td>
				<div className="bi-line">
					<b>{ totalBonos }€ canjeable</b>
					<small>{ totalBonosDisponibles }€ disponibles</small>
				</div>
			</td>
			<td>
				<div className="flex h-center">
					<div className="btn gestionar" onClick={ handleGestionar }>
						<IoDocumentText className="icon" />Gestionar bonos
					</div>
					
					<div className="btn txt" onClick={ handleEditar }>
						<RiEdit2Fill className="icon" />Editar
					</div>
				</div>
			</td>
		</tr>
	)
}

const UsuariosPage = () => {
	const dispatch = useDispatch();
	const { token, config } = useSelector(state => state.layout);
	const { list, init } = useSelector(state => state.usuarios);
	const [ search, setSearch ] = useState(false);
	const [ searchValue, setSearchValue ] = useState('');
	const [ page, setPage ] = useState(0);
	const [ max, setMax ] = useState(10);
	const [ sort, setSort ] = useState(false);
	const [ sortDirection, setSortirection ] = useState(-1);
	const searchInput = useRef(null);
	
	useEffect(() => {
		dispatch(setTitle('Usuarios'));
			
		fetchData(`get-usuarios`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setList(response));
			}
		});
		
		fetchData(`get-app-info`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setConfig(response));
			}
		});
	}, []);
	
	useEffect(() => {
		setPage(0);
	}, [searchValue]);
	
	const handleSort = (ord) => {
		if (!sort_list.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	const handleCreate = () => {
		dispatch(openModal( {id: 'crear-usuario' }))
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
		
	let sort_list = [...list].sort((a, b) => (new Date(b.create_at)).getTime() - (new Date(a.create_at)).getTime())
	
	if (searchValue) {
		sort_list = [...sort_list].filter(item => 	item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.author.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.format.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
	}
	
	if (sort) {
		sort_list = [...sort_list].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	}
	
	const usuariosRegistrados = [...sort_list].length;
	
	let importeCampania = config.presupuesto;
	let importeBonosEmitidor = 0;
	let importeBonosGastados = 0;
	let importePromovido = config.promovido;
	
	sort_list.map(item => {
		importeBonosEmitidor += item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
		importeBonosGastados += item.cupones.reduce((acumulador, cupon) => {
			if (cupon.id_compra)
				return acumulador + cupon.valor
			
			return acumulador;
		}, 0);
	});
	
	return (
		<div className="usuarios-page">
			<div className="row">
				<div className="col-4 col-mobile-1">
					<InfoCard title='Usuarios registrados' value={ usuariosRegistrados } text={ <div>Usuarios registrados</div> } />
				</div>
				
				<div className="col-4 col-mobile-1">
					<InfoCard title='Bonos emitidos' value={ `${ formatMoney(importeBonosEmitidor * 100) }€` } text={ <><span className="orange">{ formatProgress((importeBonosEmitidor * 100) / importeCampania) }%</span><div>de la partida disponible</div></> } />
				</div>
				
				<div className="col-4 col-mobile-1">
					<InfoCard title='Bonos usados' value={ `${ formatMoney(importeBonosGastados * 100) }€` } text={ <><span className="red">{ formatProgress((importeBonosGastados * 100) / importeCampania) }%</span><div>de la partida utilizada</div></> } />
				</div>
				
				<div className="col-4 col-mobile-1">
					<InfoCard title='Dinero promovido' value={ `${ formatMoney(importePromovido * 100) }€` } text={ <div>dinero promovido</div> } />
				</div>
			</div>
			
			<div className="card">
				
				<div className="table-info">
					<div className="title">
						Gestionar usuarios
						<div className="subtitle">Comprueba los registro y el consumo de los bonos por usuario.</div>
					</div>
					
					<div className="options">
						<div className="btn" onClick={ handleCreate }>
							<AiOutlineUser /> Alta usuario
						</div>
					</div>
				</div>
				
				<div className="table-container">
					<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
						<thead>
							<tr>
								<td className="check-field">ID</td>
								<td onClick={ () => handleSort('id') } className="td-cliente">Cliente { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-contacto">Contacto { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-estado">Estado { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="td-bonos">Bonos { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td width={ 250 }>Acciones</td>
							</tr>
						</thead>
						
						<tbody>
							{
								[...sort_list.slice(page*max, page*max + max )].map(item => <UsuariosPageTableItem key={ item.id } item={ item } /> )
							}
							
							{
								!sort_list.length && (
									<tr>
										<td colSpan={ 6 } align="center" className="empty">
											<div className="empty">
												<div className="btn" onClick={ handleCreate }>
													<AiOutlineUser /> Crear el primer usuario
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				
				<div className="table-controls">
					<div className="page">
						{
							sort_list.length > 0 && (
								<>
									{ page * max + 1 } al { ((page * max) + max) > sort_list.length ? sort_list.length : ((page * max) + max) } de { sort_list.length } 
									
									<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
										<option value="5">5</option>
										<option value="7">7</option>
										<option value="10">10</option>
										<option value="20">20</option>
									</select>
								</>
							)
						}
					</div>
					
					
					<div className="pagination">
						<div className="options">
							<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
								<MdKeyboardArrowLeft />
							</div>
							
							{
								Array(Math.ceil(sort_list.length / max)).fill(null).map((i, index) => 
									<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
										{ index+1 }
									</div>
								)
							}
							
							<div className={ page*max + max >= sort_list.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
								<MdKeyboardArrowRight />
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
}

export default UsuariosPage;