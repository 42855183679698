import { configureStore } from '@reduxjs/toolkit';

import layoutReducer from '../features/layout/layoutSlice';

import alertasReducer from '../features/alertas/alertasSlice';

import misBonosReducer from '../features/misBonos/misBonosSlice';
import establecimientosReducer from '../features/establecimientos/establecimientosSlice';

import validarBonosReducer from '../features/validarBonos/validarBonosSlice';
import ventasRealizadasReducer from '../features/ventasRealizadas/ventasRealizadasSlice';

import remesasReducer from '../features/remesas/remesasSlice';
import comerciosReducer from '../features/comercios/comerciosSlice';
import usuariosReducer from '../features/usuarios/usuariosSlice';
import estadisticasReducer from '../features/estadisticas/estadisticasSlice';
import auditoriaReducer from '../features/auditoria/auditoriaSlice';
import logReducer from '../features/log/logSlice';

export const store = configureStore({
	reducer: {
		layout: layoutReducer,
		
		alertas: alertasReducer,
		
		misBonos: misBonosReducer,
		establecimientos: establecimientosReducer,
		
		validarBonos: validarBonosReducer,
		ventasRealizadas: ventasRealizadasReducer,
		
		remesas: remesasReducer,
		comercios: comerciosReducer,
		usuarios: usuariosReducer,
		estadisticas: estadisticasReducer,
		auditoria: auditoriaReducer,
		log: logReducer
	}
});