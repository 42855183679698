import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HiTicket, HiChartPie, HiOutlineLogout } from 'react-icons/hi';
import { RiShoppingCart2Fill } from 'react-icons/ri';
import { FaUsers } from 'react-icons/fa';
import { AiFillShop, AiOutlineAudit } from 'react-icons/ai';
import { IoMdCheckbox } from 'react-icons/io';
import { FiSettings } from 'react-icons/fi';


import { NavLink } from 'react-router-dom';

import '../styles/LeftMenu.scss';

import { unlogin, toggleMenu } from '../features/layout/layoutSlice';

import Logo from '../media/logo.png';

const LeftMenuItem = ({ to, end, icon, iconOn, title, badget, onClick }) => {
	const dispatch = useDispatch();
	
	const handleCloseMenu = e => {
		dispatch(toggleMenu());
	}
	
	return (
		<>
			<NavLink to={ to } end={ end } className='item' onClick={ handleCloseMenu }>
				{
					icon && (
						<div className="icon">
							{ icon }
						</div>
					)
				}
				{ title }
				<div className="right">
					{ badget && <div className="badget">{ badget }</div> }
				</div>
			</NavLink>
		</>
	);
}

const LeftMenu = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const section = params['*'].split('/')[0];
	const [ submenu, setSubmenu ] = useState('');
	const { tipo, menu } = useSelector(state => state.layout);
	
	const handleUnlogin = e => {
		delete localStorage.token;
		
		dispatch(toggleMenu());
		dispatch(unlogin());
	}
	
	const handleArrow = (e, id) => {
		e.preventDefault();
		e.stopPropagation();
		
		setSubmenu(id !== submenu ? id : '');
	}
	
	const handleMenu = () => {
		dispatch(toggleMenu(!menu));
	}
	
	useEffect(() => {
		setSubmenu(section);
	}, [section])
	
	return (
		<>
			<div className={ `left-menu ${ menu ? 'open' : '' }` }>
				<NavLink to='/' className="logo">
					<img src={ Logo } alt='logo' />
				</NavLink>
				
				<div className="menu">
					<div className="title">MENÚ PRINCIPAL</div>
					
					{
						tipo === 'usuario' && (
							<>
								<LeftMenuItem to='/' end={ true } icon={ <HiTicket /> } title='Mis bonos' />
								<LeftMenuItem to='/establecimientos' icon={ <AiFillShop /> } title='Establecimientos' />
							</>
						)
					}
					
					{
						tipo === 'comercio' && (
							<>
								<LeftMenuItem to='/' end={ true } icon={ <HiTicket /> } title='Validar bonos' />
								<LeftMenuItem to='/ventas-realizadas' icon={ <IoMdCheckbox /> } title='Ventas realizadas' />
							</>
						)
					}
					
					{
						tipo === 'administrador' && (
							<>
								<LeftMenuItem to='/' end={ true } icon={ <HiTicket /> } title='Bonos' />
								<LeftMenuItem to='/comercios' icon={ <RiShoppingCart2Fill /> } title='Comercios' />
								<LeftMenuItem to='/usuarios' icon={ <FaUsers /> } title='Usuarios' />
								<LeftMenuItem to='/estadisticas' icon={ <HiChartPie /> } title='Estadísticas' />
								<LeftMenuItem to='/auditoria' icon={ <AiOutlineAudit /> } title='Auditoria' />
								{ /* <LeftMenuItem to='/configuracion' icon={ <FiSettings /> } title='Configuración' /> */ }
							</>
						)
					}
					
					
					<div className="item" onClick={ handleUnlogin }>
						<div className="icon">
							<HiOutlineLogout />
						</div>
						Cerrar sesión
					</div>
					
				</div>
			</div>
			
			<div className={ `mobile-close  ${ menu ? 'open' : '' }` } onClick={ handleMenu } />
		</>
	);
}

export default LeftMenu;