import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoClose } from 'react-icons/io5';

import '../../styles/ModalAdjuntarFactura.scss';

import { setList } from '../../features/ventasRealizadas/ventasRealizadasSlice';
import { setList as SetListAuditoria } from '../../features/auditoria/auditoriaSlice';

import apiURL from '../../hooks/apiURL';
import useFile from '../../hooks/useFile';

import Button from '../../components/Button';

const ModalAdjuntarFactura = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.ventasRealizadas);
	const { list:auditoria } = useSelector(state => state.auditoria);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ progress, setProgress ] = useState(20);
	const file = useFile([]);
	const inputElem = useRef(null);
	
	const handleSelectFile = e => {
		inputElem.current.click();
	}
	
	const handleConfirm = e => {
		if (file.value.length) {
			setSend(true);
			
			let data = new FormData();
					data.append('id', id);
					data.append('factura', file.value[0]);
			
			//
			
			let request = new XMLHttpRequest();
					request.open('POST', `${ apiURL }v1/subir-factura`); 
					
					request.setRequestHeader('Authorization', `Bearer ${ token }` );
					
					// upload progress event
					request.upload.addEventListener('progress', (e) => {
						// upload progress as percentage
						setProgress((e.loaded / e.total) * 100);
					});
					
					// request finished event
					request.addEventListener('load', (e) => {
						// request.response holds response from the server
						const json = JSON.parse(request.response);
						
						if (json.status === 'success') {
							close();
							
							const nList = [...list].map(item => {
								const nItem = {...item};
								
								if (nItem.id === id) {
									nItem.foto_factura = json.response;
								}
								
								return nItem;
							})
							
							dispatch(setList(nList));
							
							const nList2 = [...auditoria].map(item => {
								const nItem = {...item};
								
								if (nItem.id === id) {
									nItem.foto_factura = json.response;
								}
								
								return nItem;
							})
							
							dispatch(SetListAuditoria(nList2));
						}
						else {
							close();
							//setSend(false);
							//handleDeleteFile();
							//setProgress(0);
							//setError(true);
						}
					});
					
					// send POST request to server
					request.send(data);
		}
		else {
			setError(true);
		}
	}
	
	const handleDeleteFile = e => {
		file.onChange({ target: { files: [] } });
	}
	
	useEffect(() => {
		setError(false);
	}, [file.value]);
	
	return (
		<div className="drowers-modal-adjuntar-factura">
			<div className="title">Subir factura</div>
			
			<div className={ `inside ${ error ? 'error' : '' }` }>
				<div className="text">Arrastra aquí tu factura o <br />selecciona una desde <br />tu dispositivo</div>
				
				{ file.value.length === 0 && <div className="seleccionar" onClick={ handleSelectFile }>Examinar...</div> }
				{ file.value.length !== 0 && <div className="seleccionado"><div className="filename">{ file.value[0].name }</div>{ !send && <IoClose className="icon" onClick={ handleDeleteFile } /> }</div> }
				{ send && <div className="progress"><div style={{ width: `${ progress }%` }} /></div> }
				
				<input type="file" ref={ inputElem } onChange={ file.onChange } className="hidden" />
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Lo haré más tarde</Button>
				<Button green={ true } onClick={ handleConfirm } disabled={ send }>Subir factura</Button>
			</div>
		</div>
	);
}

export default ModalAdjuntarFactura;