import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import { HiTicket } from 'react-icons/hi';
import { CgSpinner } from 'react-icons/cg'

import '../../styles/ModalAdjuntarBono.scss';

import { setList, addList } from '../../features/auditoria/auditoriaSlice';

import apiURL from '../../hooks/apiURL';
import useInput from '../../hooks/useInput';
import fetchData from '../../hooks/fetchData';

import Button from '../../components/Button';
import Input from '../../components/Input';

const ModalAdjuntarBono = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.auditoria);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	const [ estado, setEstado ] = useState(0);
	const [ loading, setLoading ] = useState(false);
	const [ checked, setChecked ] = useState(false);
	const serial = useInput('');
	
	const handleAdjuntar = e => {
		fetchData(`add-codigo-venta`, {
				method: 'POST',
				token,
				body: {
					id,
					s: serial.value
				},
				cbk: ({ status, message, response }) => {
					close();
					
					if (response) {
						const nList = [...list].map(item => {
							if (item.id !== id)
								return item;
							
							let nItem = {...item};
									nItem.cupones = [...nItem.cupones || [], response];
							
							return nItem;
						})
						
						dispatch(setList(nList));
					}
				}
			});
	}
	
	useEffect(() => {
		if (serial.value.length === 10) {
			setLoading(true);
			setChecked(true);
			
			fetchData(`check-serial`, {
				method: 'POST',
				token,
				body: {
					s: serial.value,
					id_comercio: auditoria.id_comercio
				},
				cbk: ({ status, message, response }) => {
					setLoading(false);
					
					if (response) {
						setEstado(response.estado);
					}
					else {
						setEstado(2);
					}
				}
			});
		}
		else {
			if (checked) {
				setChecked(false);
				setEstado(0);
			}
		}
	}, [serial.value]);
	
	const auditoria = [...list].find(item => item.id === id);
	
	return (
		<div className="drowers-modal-adjuntar-bono">
			<div className="title">Adjuntar bono</div>
			
			<div className='inside'>
				<Input placeholder="Serial..." value={ serial.value } onChange={ serial.onChange } />
				
				<div className="estado-hooks">
					{ (!checked || estado === 0) && <div className="estado">{ loading ? <CgSpinner className="rotating-element" /> : '' }</div> }
					
					{ checked && estado === 1 && <div className="estado pagada">Valido</div> }
					{ checked && estado === 2 && <div className="estado error">No válido</div> }
					{ checked && estado === 3 && <div className="estado error">Usado</div> }
					{ checked && estado === 4 && <div className="estado pendiente">Repetido</div> }
					{ checked && estado === 5 && <div className="estado pendiente">Falta importe</div> }
				</div>
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Cancel</Button>
				<Button green={ true } onClick={ handleAdjuntar } disabled={ send || estado !== 1 }><HiTicket className="icon" />Adjuntar</Button>
			</div>
		</div>
	);
}

export default ModalAdjuntarBono;