import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { BsFiles, BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { RiInformationFill } from 'react-icons/ri';
import { FaTrash } from 'react-icons/fa';
import { IoEyeSharp, IoAlertCircle } from 'react-icons/io5';
import { IoIosWarning } from 'react-icons/io';

import '../styles/VentasRealizadasPage.scss';

import fetchData from '../hooks/fetchData';
import formatDate from '../hooks/formatDate';
import formatMoney from '../hooks/formatMoney';

import { setTitle, openModal } from '../features/layout/layoutSlice';
import { setList, deleteList } from '../features/ventasRealizadas/ventasRealizadasSlice';

import Loader from '../components/Loader';
import InfoCard from '../components/InfoCard';

const VentasRealizadasPageTableItem = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useSelector(state => state.layout);
	const [ open, setOpen ] = useState(false);
	
	const handleClick = e => {
		setOpen(!open);
	}
	
	const handleAdjuntarFactura = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'adjuntar-factura', extra: { id: item.id } }));
	}
	
	const handleVerFactura = e => {
		e.preventDefault();
		e.stopPropagation();
		
		dispatch(openModal({ id: 'ver-factura', extra: { id: item.id } }));
	}
	
	const handleEliminar = e => {
		fetchData(`eliminar-venta`, {
			method: 'POST',
			token,
			body: {
				id: item.id
			},
			cbk: ({ status, message, response }) => {
				dispatch(deleteList(response));
			}
		});
	}
	
	const totalBonos = item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
	
	return (
		<>
			<tr onClick={ handleClick }>
				<td className="check-field">
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b># { item.id }</b></div> }
					{ !item.auditoria && <># { item.id }</> }
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b>{ formatDate('d.m.Y', item.fecha) }</b></div> }
					{ !item.auditoria && <>{ formatDate('d.m.Y', item.fecha) }</> }
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b>{ item.compra }€</b></div> }
					{ !item.auditoria && <>{ formatMoney(item.compra * 100) }€</> }
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b>{ totalBonos }€</b></div> }
					{ !item.auditoria && <>{ formatMoney(totalBonos * 100) }€</> }
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="red"><b>{ item.compra - totalBonos }€</b></div> }
					{ !item.auditoria && <>{ formatMoney((item.compra - totalBonos) * 100) }€</> }
				</td>
				
				<td>
					{ (item.auditoria === 1 || item.auditoria === 2 || item.auditoria === 3 ) && <div className="estado error">solucionar</div> }  
					
					{ !item.auditoria && totalBonos === 0 && <div className="estado">Venta sin bonos</div> }
					{ !item.auditoria && totalBonos > 0 && !item.foto_factura && <div className="estado error">Falta factura</div> }
					{ !item.auditoria && totalBonos > 0 && item.foto_factura && !item.remesa && <div className="estado pendiente">Pendiente</div> }
					{ !item.auditoria && totalBonos > 0 && item.foto_factura && item.remesa && item.remesa.estado !== 2 && <div className="estado pendiente">Enviada</div> }
					{ !item.auditoria && totalBonos > 0 && item.foto_factura && item.remesa && item.remesa.estado === 2 && <div className="estado pagada">Pagada</div> }
				</td>
				<td>
					{
						item.remesa && (
							<div className="info-facturas flex h-center">
								{ item.remesa.estado !== 2 && <div className="info-remesa">Enviada al Ayuntamiento en la <b>Remesa Nº{ item.remesa.numero }</b></div> }
								{ item.remesa.estado === 2 && <div className="info-remesa pagada">Pagada en la <b>Remesa Nº{ item.remesa.numero }</b></div> }
								
								{
									totalBonos > 0 && (
										<div className="arrow">
											<MdKeyboardArrowDown className={ `icon ${ open ? 'open' : ''}` } />
										</div>
									)
								}
							</div>
						)
					}
					
					{
						!item.remesa && (
							<>
								<div className="info-facturas flex h-center">
									{
										!item.foto_factura && (
											<div className="adjuntar" onClick={ handleAdjuntarFactura }>
												<BsFillFileEarmarkTextFill className="icon" />Adjuntar factura
											</div>
										)
									}
									
									{
										item.foto_factura && (
											<div className="ver" onClick={ handleVerFactura }>
												<IoEyeSharp className="icon" />Ver factura
											</div>
										)
									}
									
									<div className="eliminar" onClick={ handleEliminar }>
										<FaTrash className="icon" />Eliminar
									</div>
									
									{
										totalBonos > 0 && (
											<div className="arrow">
												<MdKeyboardArrowDown className={ `icon ${ open ? 'open' : ''}` } />
											</div>
										)
									}
								</div>
								
								{ item.auditoria === 1 && (<div className="auditar-text"><IoAlertCircle className="icon" />La imagen está borrosa</div>) }
								{ item.auditoria === 2 && (<div className="auditar-text"><IoAlertCircle className="icon" />Los importes no coinciden</div>) }
								{ item.auditoria === 3 && (<div className="auditar-text"><IoAlertCircle className="icon" />Datos de cliente incorrectos</div>) }
							</>
						)
					}
				</td>
			</tr>
			
			{
				open && (
					<>
						{
							item.cupones.map((cupon, index) => 
								<tr key={ cupon.id } className={ `grey ${ index === 0 ? 'first' : ''} ${ index === item.cupones.length-1 ? 'last' : ''}`}>
									<td colSpan={ 3 } align="center" className="serial">{ cupon.serial }</td>
									<td colSpan={ 4 }>{ `${ cupon.valor }€` }</td>
								</tr>
							)
						}
					</>
				)
			}
		</>
	)
}

const VentasRealizadasPage = ({ disponibles }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list, init } = useSelector(state => state.ventasRealizadas);
	const [ search, setSearch ] = useState(false);
	const [ searchValue, setSearchValue ] = useState('');
	const [ page, setPage ] = useState(0);
	const [ max, setMax ] = useState(10);
	const [ sort, setSort ] = useState(false);
	const [ sortDirection, setSortirection ] = useState(-1);
	const searchInput = useRef(null);
	
	useEffect(() => {
		dispatch(setTitle('Ventas Realizadas'));
		
		fetchData(`ventas-realizadas`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setList(response));
			}
		});
	}, []);
	
	useEffect(() => {
		setPage(0);
	}, [searchValue]);
	
	const handleSort = (ord) => {
		if (!sort_list.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
		
	let sort_list = [...list].sort((a, b) => (new Date(b.fecha)).getTime() - (new Date(a.fecha)).getTime())
	
	if (searchValue) {
		sort_list = [...sort_list].filter(item => 	item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.author.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.format.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
	}
	
	if (sort) {
		sort_list = [...sort_list].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	}
	
	const importeTotal = list.reduce((acumulador, item) => acumulador + Number(item.compra), 0);
	
	let importePagado = 0, importePendiente = 0;
	
	list.map(item => {
		const total = item.cupones.reduce((acumulador, cupon) => acumulador + cupon.valor, 0);
		
		if (item.remesa && item.remesa.estado === 2) importePagado += total;
		else importePendiente += total;
	});
	
	
	return (
		<div className="ventas-realizadas-page">
			<div className="alert info">
				<RiInformationFill className="icon" /><div><b>Información.</b> ¿Tienes dudas o alguna urgencia? Llama al <a href="tel:619 477 411">619 477 411</a> para solicitar ayuda urgente</div>
			</div>
			
			{
				!disponibles && (
					<div className="alert warning">
						<IoIosWarning className="icon" /><div><b>Presupuesto de la campaña agotado.</b> La plataforma quedará habilitada hasta el día 25 de Noviembre para adjuntar facturas realizadas a fecha anterior a
		la finalización de la campaña. Si el ayuntamiento decide ampliar el presupuesto, se lo notificaremos por correo electrónico.</div>
					</div>
				)
			}
			
			<div className="row">
				<div className="col-3 col-mobile-1">
					<InfoCard title='Importe total' value={ `${ importeTotal }€` } text={ <div>por todas las ventas</div> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Dinero pagado' value={ `${ importePagado }€` } text={ <div>por remesas completadas</div> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Dinero a pagar' value={ `${ importePendiente }€` } text={ <div>pendiente para la próxima remesa</div> } />
				</div>
			</div>
			
			
			<div className="card title-hook">
				<div>
					<div className="title">Remesas</div>
					<div className="subtitle">Estado de las ventas</div>
				</div>
			</div>
			
			
			<div className="card no-border table">
				<div className="table-container">
					<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
						<thead>
							<tr>
								<td className="check-field">ID</td>
								<td onClick={ () => handleSort('id') } className="tr-fecha">Fecha <br />operación { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-importe">Importe <br />de la venta { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-bonos">Bonos <br />canjeados { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-pagar">A pagar por <br />el cliente { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-estado">Estado de <br />la factura { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('id') } className="tr-otras">Otras <br />operaciones { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
							</tr>
						</thead>
						
						<tbody>
							{
								[...sort_list.slice(page*max, page*max + max )].map(item => <VentasRealizadasPageTableItem key={ item.id } item={ item } /> )
							}
							
							{
								!sort_list.length && (
									<tr>
										<td colSpan={ 7 } align="center" className="empty">
											<div className="empty">
												<div className="btn grey no-click">
													<BsFiles /> Aun no hay remesas creadas
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				
				<div className="table-controls">
					<div className="page">
						{
							sort_list.length > 0 && (
								<>
									{ page * max + 1 } al { ((page * max) + max) > sort_list.length ? sort_list.length : ((page * max) + max) } de { sort_list.length } 
									
									<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
										<option value="5">5</option>
										<option value="7">7</option>
										<option value="10">10</option>
										<option value="20">20</option>
									</select>
								</>
							)
						}
					</div>
					
					
					<div className="pagination">
						<div className="options">
							<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
								<MdKeyboardArrowLeft />
							</div>
							
							{
								Array(Math.ceil(sort_list.length / max)).fill(null).map((i, index) => 
									<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
										{ index+1 }
									</div>
								)
							}
							
							<div className={ page*max + max >= sort_list.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
								<MdKeyboardArrowRight />
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	);
}

export default VentasRealizadasPage;