import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoClose } from 'react-icons/io5';

import '../../styles/ModalFaltaValidar.scss';

import { setUser, unlogin } from '../../features/layout/layoutSlice';
import { setList } from '../../features/ventasRealizadas/ventasRealizadasSlice';
import { setList as SetListAuditoria } from '../../features/auditoria/auditoriaSlice';

import apiURL from '../../hooks/apiURL';
import useFile from '../../hooks/useFile';
import fetchData from '../../hooks/fetchData';

import Button from '../../components/Button';

const ModalFaltaValidar = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	
	const handleRefresh = e => {
		if (send) return null;
		
		setSend(true);
		
		fetchData(`authToken`, {
			method: 'POST',
			body: {
				token
			},
			cbk: ({ status, message, response }) => {
				if (status === 'success') {
					dispatch(setUser(response.user));
					setSend(false);
					
					if (response.user.estado === -1 || response.user.estado === 1)
						close();
				}
				else {
					localStorage.clear();
					dispatch(unlogin());
					close();
				}
			},
		});
	}
	
	return (
		<div className="drowers-modal-falta-validar">
			<div className="title">Sin validar</div>
			
			<div className='inside'>
				<div className="text">Este establecimiento necesita ser validado por un administrador, vuelve a comprobarlo más tarde.</div>
			</div>
			
			<div className="buttons center">
				<Button base={ true } onClick={ handleRefresh } disabled={ send }>Refrescar</Button>
			</div>
		</div>
	);
}

export default ModalFaltaValidar;