const formatProgress = (value) => {
	if (Number(value) === 0) return value;
	
	const opciones = {
	  style: 'decimal',
	  minimumFractionDigits: 0,
	  maximumFractionDigits: 1
	};
	
	const valorFormateado = (value).toLocaleString('es-ES', opciones);
	
	return valorFormateado;
}

export default formatProgress;