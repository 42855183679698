import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp, MdAccountBalanceWallet } from 'react-icons/md';
import { TbFilePlus } from 'react-icons/tb';
import { FaTrash } from 'react-icons/fa';
import { IoLogOut, IoDocumentText } from 'react-icons/io5';
import { AiOutlineShop } from 'react-icons/ai';
import { RiEdit2Fill } from 'react-icons/ri';

import '../styles/ComerciosPage.scss';

import fetchData from '../hooks/fetchData';
import formatMoney from '../hooks/formatMoney';
import formatProgress from '../hooks/formatProgress';

import { openModal, setTitle, setConfig } from '../features/layout/layoutSlice';
import { setList } from '../features/comercios/comerciosSlice';

import Loader from '../components/Loader';
import InfoCard from '../components/InfoCard';

const ComerciosPageTableItem = ({ item, validar, desvalidar, suspender }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const handleDblClick = e => {
		navigate( `/comercios/${ item.id }` );
	}
	
	const handleValidar = () => {
		validar(item.id);
	}
	
	const handleDesvalidar = () => {
		desvalidar(item.id);
	}
	
	const handleSuspender = () => {
		suspender(item.id);
	}
	
	const handleGestionar = () => {
		dispatch(openModal( {id: 'gestionar-documentacion', extra: { id: item.id } }))
	}
	
	const handleEditar = () => {
		dispatch(openModal( {id: 'crear-comercio', extra: { id: item.id } }))
	}
	
	return (
		<tr onDoubleClick={ handleDblClick }>
			<td className="check-field"># { item.id }</td>
			
			<td>
				<div className="bi-line">
					<b>{ item.comercio_nombre }</b>
					<small>{ `${ item.nombre } ${ item.apellidos }` }</small>
					{ !!item.es_benefico && <small className="green">Benefico</small> }
				</div>
			</td>
			<td>
				<div className="bi-line">
					<b>{ item.email }</b>
					<small>{ item.telefono }</small>
				</div>
			</td>
			<td>
				{ item.estado === 0 && <div className="estado pendiente">Pendiente</div> }
				{ item.estado === 1 && <div className="estado pagada">Validado</div> }
				{ item.estado === -1 && <div className="estado error">Suspendido</div> }
			</td>
			<td>
				<div className="flex h-center">
					<div className="btn gestionar" onClick={ handleGestionar }>
						<IoDocumentText className="icon" />Gestionar
					</div>
				</div>
			</td>
			<td>
				<div className="flex h-center">
					{
						item.estado === 0 && (
							<div className="btn pay" onClick={ handleValidar }>
								<IoLogOut className="icon" />Validar
							</div>
						)
					}
					{
						item.estado === 1 && (
							<div className="btn unpay" onClick={ handleDesvalidar }>
								<IoLogOut className="icon" />Desvalidar
							</div>
						)
					}
					{
						item.estado === -1 && (
							<div className="btn unpay-2" onClick={ handleDesvalidar }>
								<IoLogOut className="icon" />Reactivar
							</div>
						)
					}
					
					{
						item.estado === 0 && (
							<div className="btn borrar" onClick={ handleSuspender }>
								<IoLogOut className="icon" />Suspender
							</div>
						)
					}
					
					<div className="btn txt" onClick={ handleEditar }>
						<RiEdit2Fill className="icon" />Editar
					</div>
				</div>
			</td>
		</tr>
	)
}

const ComerciosPage = () => {
	const dispatch = useDispatch();
	const { token, config } = useSelector(state => state.layout);
	const { list, init } = useSelector(state => state.comercios);
	const { list:bonos } = useSelector(state => state.remesas);
	const [ search, setSearch ] = useState(false);
	const [ searchValue, setSearchValue ] = useState('');
	const [ page, setPage ] = useState(0);
	const [ max, setMax ] = useState(10);
	const [ sort, setSort ] = useState(false);
	const [ sortDirection, setSortirection ] = useState(-1);
	const searchInput = useRef(null);
	
	useEffect(() => {
		dispatch(setTitle('Comercios'));
		
		fetchData(`get-comercios`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setList(response));
			}
		});
		
		fetchData(`get-app-info`, {
			token,
			cbk: ({ status, message, response }) => {
				dispatch(setConfig(response));
			}
		});
	}, []);
	
	useEffect(() => {
		setPage(0);
	}, [searchValue]);
	
	const handleSort = (ord) => {
		if (!sort_list.length)
			return null;
		
		if (sort === ord) {
			setSortirection(sortDirection * -1);
		}
		else {
			setSortirection(1);
			setSort(ord);
		}
	}
	
	const handleValidar = (id) => {
		let nList = [...list].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = 1;
			
			return nItem;
		})
		
		dispatch(setList(nList));
		changeEstadoRemesa(id, 1)
	}
	
	const handleDesvalidar = (id) => {
		let nList = [...list].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = 0;
			
			return nItem;
		})
		
		dispatch(setList(nList));
		changeEstadoRemesa(id, 0)
	}
	
	const handleSuspender = (id) => {
		let nList = [...list].map(item => {
			let nItem = {...item};
			
			if (nItem.id === id)
				nItem.estado = -1;
			
			return nItem;
		})
		
		dispatch(setList(nList));
		changeEstadoRemesa(id, -1)
	}
	
	const changeEstadoRemesa = (id, s) => {
		fetchData(`update-comercio-estado`, {
			method: 'POST',
			token,
			body: {
				id,
				s
			}
		});
	}
	
	const handleCreate = () => {
		dispatch(openModal( {id: 'crear-comercio' }))
	}
	
	if (!init)
		return <div className="loader"><Loader /></div>;
		
	let sort_list = [...list].sort((a, b) => (new Date(b.create_at)).getTime() - (new Date(a.create_at)).getTime())
	
	if (searchValue) {
		sort_list = [...sort_list].filter(item => 	item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.author.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
																					item.format.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
	}
	
	if (sort) {
		sort_list = [...sort_list].sort((a, b) => sortDirection * String(a[sort]).localeCompare(String(b[sort]), 'en', {numeric: true}));
	}
	
	let importeCampania = config.presupuesto;
	let importePagado = config.pagado || 0;
	let importePendiente = config.pendiente || 0;
	let importeRestante = importeCampania - (importePagado * 100) - (importePendiente * 100);
	
	return (
		<div className="comercios-page">
			<div className="row">
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida disponible' value={ `${ formatMoney(importeCampania) }€` } text={ <div>presupuesto de la campaña</div> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Dinero pagado' value={ `${ formatMoney(importePagado * 100) }€` } text={ <><span className="green">{ formatProgress((importePagado * 100) / importeCampania) }%</span><div>del total disponible</div></> } />
				</div>
				
				<div className="col-3 col-mobile-1">
					<InfoCard title='Partida restante' value={ `${ formatMoney(importeRestante) }€` } text={ <><span className="red">{ formatProgress((importeRestante * 100) / importeCampania) }%</span><div>de la partida disponible</div></> } />
				</div>
			</div>
			
			<div className="card">
				
				<div className="table-info">
					<div className="title">
						Gestionar comercios
						<div className="subtitle">Valida, suspende o accede a la documentación de los comercios.</div>
					</div>
					
					<div className="options">
						<div className="btn" onClick={ handleCreate }>
							<AiOutlineShop /> Alta comercio
						</div>
					</div>
				</div>
				
				<div className="table-container">
					<table className="drws-table" cellPadding={ 0 } cellSpacing={ 0 } border={ 0 }>
						<thead>
							<tr>
								<td className="check-field" onClick={ () => handleSort('id') }>ID { sort === 'id' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('comercio_nombre') } className="td-comercio">Comercio { sort === 'comercio_nombre' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('email') } className="td-contacto">Contacto { sort === 'email' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td onClick={ () => handleSort('estado') } className="td-estado">Estado { sort === 'estado' ? sortDirection === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : ''}</td>
								<td width={ 160 } className="td-documentacion">Documentación</td>
								<td width={ 220 }>Acciones</td>
							</tr>
						</thead>
						
						<tbody>
							{
								[...sort_list.slice(page*max, page*max + max )].map(item => <ComerciosPageTableItem key={ item.id } item={ item } validar={ handleValidar } desvalidar={ handleDesvalidar } suspender={ handleSuspender } /> )
							}
							
							{
								!sort_list.length && (
									<tr>
										<td colSpan={ 6 } align="center" className="empty">
											<div className="empty">
												<div className="btn" onClick={ handleCreate }>
													<AiOutlineShop /> Crear el primer comercio
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				
				<div className="table-controls">
					<div className="page">
						{
							sort_list.length > 0 && (
								<>
									{ page * max + 1 } al { ((page * max) + max) > sort_list.length ? sort_list.length : ((page * max) + max) } de { sort_list.length } 
									
									<select className="page-select" value={ max } onChange={ e => setMax(Number(e.target.value))}>
										<option value="5">5</option>
										<option value="7">7</option>
										<option value="10">10</option>
										<option value="20">20</option>
									</select>
								</>
							)
						}
					</div>
					
					
					<div className="pagination">
						<div className="options">
							<div className={ page < 1 ? 'disabled' : '' } onClick={ () => setPage(page-1) }>
								<MdKeyboardArrowLeft />
							</div>
							
							{
								Array(Math.ceil(sort_list.length / max)).fill(null).map((i, index) => 
									<div key={ index } className={ `single-page${ index === page ? ' active' : '' }` } onClick={ () => setPage(index) }>
										{ index+1 }
									</div>
								)
							}
							
							<div className={ page*max + max >= sort_list.length ? 'disabled' : '' } onClick={ () => setPage(page+1) }>
								<MdKeyboardArrowRight />
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
}

export default ComerciosPage;