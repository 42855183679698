import { TbPhoto } from 'react-icons/tb';
import { HiLocationMarker } from 'react-icons/hi';
import { FaPhoneAlt } from 'react-icons/fa';

import '../styles/ComercioCard.scss';

import apiURL from '../hooks/apiURL';

const ComercioCard = ({ data }) => {
	const className = ['comercio-card'];
	
	if (data.disabled) className.push('disabled');
	
	return (
		<div className={ className.join(' ') }>
			<div className="card">
				<div className="media" style={{ backgroundImage: `url(${ apiURL }upload/comercios-foto/${ data.comercio_foto })` }}>
					{ !data.comercio_foto && <TbPhoto className="icon" /> }
				</div>
				
				<div className="data">
					<div className="title">{ data.comercio_nombre }</div>
					<div className="line">{ data.sector || '-' }</div>
					<div className="line"><HiLocationMarker className="icon" />{ data.comercio_direccion }</div>
					<div className="line"><FaPhoneAlt className="icon" />{ data.telefono }</div>
				</div>
			</div>
		</div>
	);
}

export default ComercioCard;