import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoExit } from 'react-icons/io5';

import '../../styles/ModalRegistrarUsuario.scss';

import { openModal } from '../../features/layout/layoutSlice';
import { setList, addList } from '../../features/usuarios/usuariosSlice';

import apiURL from '../../hooks/apiURL';
import fetchData from '../../hooks/fetchData';
import useInput from '../../hooks/useInput';

import Button from '../../components/Button';
import InputField from '../../components/InputField';

const ModalRegistrarUsuario = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.usuarios);
	const { id } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ tipo, setTipo ] = useState(1);
	
	const nombre = useInput('');
	const apellidos = useInput('');
	const email = useInput('');
	const telefono = useInput('');
	const dni = useInput('');
	
	useEffect(() => {
		const usuario = [...list].find(item => item.id === id);
		
		dni.setValue(usuario.documento);
	}, []);
	
	const handleConfirm = e => {
		if (send) return null;
		
		setSend(true);
		
		fetchData(`registrar-usuario`, {
			method: 'POST',
			token,
			body: {
				id,
				tipo,
				
				nombre: nombre.value,
				apellidos: apellidos.value,
				email: email.value,
				telefono: telefono.value,
				documento: dni.value
			},
			cbk: ({ status, message, response }) => {
				if (response) {
					let nList = [...list].map(item => {
						if (item.id === id)
							return response;
						
						return item;
					})
					
					dispatch(setList(nList));
				}
				
				close();
			}
		});
	}
	
	return (
		<div className="drowers-modal-registrar-usuario">
			<div className="title">Registrar Usuario</div>
			
			<div className="inside">
				<div className="row">
					<div className="col-2">
						<div className={ `selector ${ tipo === 1 ? 'active' : '' }` } onClick={ () => setTipo(1) }>
							Solo con DNI
						</div>
					</div>
					
					<div className="col-2">
						<div className={ `selector ${ tipo === 2 ? 'active' : '' }` } onClick={ () => setTipo(2) }>
							Con datos
						</div>
					</div>
				</div>
				
				{
					tipo === 1 && (
						<div className="datos-acceso">
							<div className="datos">
								<div className="text">Usuario:</div>
								<div className="value">{ dni.value }</div>
							</div>
							
							<div className="datos">
								<div className="text">Contraseña:</div>
								<div className="value">{ dni.value }</div>
							</div>
						</div>
					)
				}
				{
					tipo === 2 && (
						<>
							<div className="row">
								<div className="col-2">
									<InputField label='Nombre' type='text' value={ nombre.value } onChange={ nombre.onChange } />
								</div>
								
								<div className="col-2">
									<InputField label='Apellidos' type='text' value={ apellidos.value } onChange={ apellidos.onChange } />
								</div>
							</div>
							
							<InputField label='Correo electrónico' type='text' value={ email.value } onChange={ email.onChange } />
							
							<div className="row">
								<div className="col-2">
									<InputField label='Teléfono' type='text' value={ telefono.value } onChange={ telefono.onChange } />
								</div>
								
								<div className="col-2">
									<InputField label='DNI' type='text' value={ dni.value } onChange={ dni.onChange } disabled={ true } />
								</div>
							</div>
						</>
					)
				}
			</div>
			
			<div className="buttons center">
				<Button grey={ true } onClick={ close } disabled={ send }>Cancelar</Button>
				<Button green={ true } onClick={ handleConfirm } disabled={ send }>Registrar</Button>
			</div>
		</div>
	);
}

export default ModalRegistrarUsuario;