import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdOutlineBlock } from 'react-icons/md';
import { FiUpload } from 'react-icons/fi';

import '../../styles/ModalGestionarDocumentacion.scss';

import { unlogin, setUser } from '../../features/layout/layoutSlice';
import { setList } from '../../features/comercios/comerciosSlice';

import apiURL from '../../hooks/apiURL';
import fetchData from '../../hooks/fetchData';
import useFile from '../../hooks/useFile';

import Button from '../../components/Button';

const ModalGestionarDocumentacionCupon = ({ item, id_comercio }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.layout);
	const [ uploading, setUploading ] = useState(false);
	const [ progress, setProgress ] = useState(0);
	const [ send, setSend ] = useState(false);
	const { list } = useSelector(state => state.comercios);
	const file = useFile([]);
	const inputElem = useRef(null);
	
	
	const handleUpload = e => {
		if (!uploading)
			inputElem.current.click();
	}
	
	useEffect(() => {
		setUploading(true);
		setProgress(0);
		
		if (file.value.length) {
			setSend(true);
			
			let data = new FormData();
					data.append('id_comercio', id_comercio);
					data.append('id_documento', item.id_base);
					data.append('documento', file.value[0]);
			
			//
			
			let request = new XMLHttpRequest();
					request.open('POST', `${ apiURL }v1/subir-documento`); 
					
					request.setRequestHeader('Authorization', `Bearer ${ token }` );
					
					// upload progress event
					request.upload.addEventListener('progress', (e) => {
						// upload progress as percentage
						setProgress((e.loaded / e.total) * 100);
					});
					
					// request finished event
					request.addEventListener('load', (e) => {
						// request.response holds response from the server
						const json = JSON.parse(request.response);
						
						if (json.status === 'success') {
							const nList = [...list].map(item2 => {
								if (item2.id !== id_comercio)
									return item2;
								
								let nItem2 = {...item2};
										nItem2.ficheros = [...item2.ficheros].map(item3 => {
											if (item3.id_base !== item.id_base)
												return item3;
											
											return json.response;
										})
								
								return nItem2;
							});
							
							dispatch(setList(nList))
						}
						
						setUploading(false);
						
					});
					
					// send POST request to server
					request.send(data);
		}
		else {
			setUploading(false);
			setProgress(0);
		}
	}, [file.value]);
	
	const handleDeleteFile = e => {
		setUploading(false);
		setProgress(0);
		file.setValue([]);
		
		const nList = [...list].map(item2 => {
			if (item2.id !== id_comercio)
				return item2;
			
			let nItem2 = {...item2};
					nItem2.ficheros = [...item2.ficheros].map(item3 => {
						if (item3.id_base !== item.id_base)
							return item3;
						
						let nItem3 = {...item3};
								nItem3.fichero = null;
								nItem3.id = null;
								nItem3.id_comercio = null;
						
						return nItem3;
					})
			
			return nItem2;
		});
		
		dispatch(setList(nList));
		
		fetchData('eliminar-documento', {
			method: 'POST',
			token,
			body: {
				id: item.id
			}
		});
	}
	
	const handleVer = e => {
		window.open( `${ apiURL }upload/documentos/${ item.fichero }` )
	}
	
	return (
		<div className={ `bono-line ${ !item.fichero ? 'falta' : '' }` }>
			<div className="nombre">
				<div className="text">{ item.nombre }</div>
				<div className={ `progress ${ uploading ? 'uploading' : '' }` }>
					<div style={{ width: `${ progress }%` }} />
				</div>
				
				<input type="file" ref={ inputElem } onChange={ file.onChange } className="hidden" />
			</div>
			
			{
				!item.fichero && (
					<>
						<div className="option" onClick={ handleUpload }><FiUpload className="icon" /></div>
						<div className="estado falta">Falta</div>
					</>
				)
			}
			
			{
				item.fichero && (
					<>
						<div className="option" onClick={ handleDeleteFile }><MdOutlineBlock className="icon" /></div>
						<div className="estado ver" onClick={ handleVer }>Ver</div>
					</>
				)
			}
		</div>
	)
}

const ModalGestionarDocumentacion = ({ close, extra }) => {
	const dispatch = useDispatch();
	const { token, user } = useSelector(state => state.layout);
	const { list } = useSelector(state => state.comercios);
	const { id, noClose } = extra;
	const [ send, setSend ] = useState(false);
	const [ error, setError ] = useState(false);
	
	const handleConfirm = e => {
		close();
	}
	
	const handleSalir = e => {
		close();
		
		delete localStorage.token;
		
		dispatch(unlogin());
	}
	
	const handleContinuar = e => {
		close();
		
		const nUser = {...user};
					nUser.ficheros = comercio.ficheros;
		
		dispatch(setUser(nUser));
	}
	
	const comercio = list.find(item => item.id === id);
	const continuar = comercio.ficheros.filter((item, index) => !item.fichero);
	
	return (
		<div className="drowers-modal-gestionar-documentacion">
			<div className="title">Gestionar documentación</div>
			
			<div className='inside'>
				{
					comercio.ficheros.map((item, index) => <ModalGestionarDocumentacionCupon key={ index } item={ item } id_comercio={ comercio.id } />)
				}
			</div>
			
			<div className="buttons center">
				{ !noClose && (
						<>
							<Button grey={ true } onClick={ close } disabled={ send }>Cerrar</Button> 
							<Button green={ true } onClick={ handleConfirm } disabled={ send }>OK</Button>
						</>
					)
				}
				{ 
					noClose && <Button grey={ true } onClick={ handleSalir } disabled={ send }>Cerrar sesión</Button> 
				}
				
				{ 
					noClose && !continuar.length && <Button base={ true } onClick={ handleContinuar } disabled={ send }>Continuar</Button> 
				}
			</div>
		</div>
	);
}

export default ModalGestionarDocumentacion;